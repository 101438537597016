import React, { useEffect, useState } from 'react';
import {
  Typography, Grid, Checkbox, FormControl, TextField,
} from '@material-ui/core/';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControlLabel, RadioGroup, Radio } from '@material-ui/core/index';
import { operations, selectors } from '../../../../state/ducks/eval';
import RateTextBox from '../../../../components/Eval/RateInput/RateInput';
import CurrencyTextBox from '../../../../components/Eval/CurrencyBox';
import Dropdown from '../../../../components/Eval/Dropdown/Dropdown';
import styles from './InformationStyles';
import DatePicker from './DatePicker';
import {
  COMBOBOX_OPTIONS_PAIRS_MAPPING, NON_DEL_APPROVAL_DATE,
  GEN_INFO, NEW_TERM, DISASTER_PARTIAL_CLAIM, LAST_PAID_INSTALLMENT, VA_APPROVAL_DATE,
  VA_SERVICING_PURCHASE,
} from '../../../../constants/eval';

const Information = (props) => {
  const [informationData, setInformationData] = useState(props.data);
  const [fieldsToDisable, setFieldsToDisable] = useState(props.fieldsToDisable);
  const {
    isCaseEditable,
    delegationIndicator,
    resolutionChoiceType, lockFlag, caseType,
  } = props;

  const handleCheck = (event) => {
    const { saveCaseDetails } = props;
    const { checked, value } = event.target;
    if ((checked !== null || checked !== undefined)
      && (fieldsToDisable && fieldsToDisable.length > 0
        ? !fieldsToDisable.includes(value) : true)) {
      const newState = informationData
        .map(e => ((e.columnName === value)
          ? {
            ...e,
            // isChecked: checked,
            isOverride: checked,
          } : e));
      setInformationData(newState);
      saveCaseDetails({ type: props.type, data: newState });
    }
  };

  useEffect(() => {
    setInformationData(props.data);
  }, [props.data, lockFlag, caseType]);

  useEffect(() => {
    setFieldsToDisable(props.fieldsToDisable);
  }, [props.fieldsToDisable]);


  const inputValidations = (fieldName, value) => {
    switch (fieldName) {
      // case NEW_RATE:
      //   return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

      case NEW_TERM: {
        const updatedValue = value;
        if (new RegExp('^[0-9]+$|^$').test(value)) {
          return updatedValue;
        }
        return '';
      }

      default:
        return value;
    }
  };

  const getValidUpdatedInput = (val) => {
    let inputValue = val;
    if (inputValue.length > 0) {
      inputValue = inputValue.trim();
      inputValue = inputValue.replaceAll(' ', '');
      inputValue = inputValue.replaceAll('%', '');
    }
    return Number((inputValue) >= 0) ? inputValue : '';
  };

  const getRoundedValue = value => (value !== null && value !== '' && !Number.isNaN(Number(value))
    ? parseFloat(value).toFixed(2)
    : '0.00');

  const columnTypeValidations = (columnType, value) => {
    switch (columnType) {
      // case NEW_RATE:
      //   return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

      case 'RateTextBox': {
        const updatedValue = getValidUpdatedInput(value);
        if (new RegExp(/^[0-9]+([.][0-9]+)?$/).test(updatedValue)) {
          if (!Number.isNaN(Number(updatedValue))
   && updatedValue !== null && updatedValue !== '') {
            return Number(updatedValue / 100).toString();
          }
        }
        return '0';
      }
      case 'CheckBox': {
        if (value === true) {
          return 'True';
        }
        return 'False';
      }

      case 'AmountTextBox': {
        return getRoundedValue(value);
      }
      default:
        return value;
    }
  };

  const handleEvent = (columnName, value, columnType) => {
    const { saveCaseDetails } = props;
    let validatedValue = columnType
      ? columnTypeValidations(columnType, value) : inputValidations(columnName, value);
    if (typeof value !== 'string' && moment(validatedValue, 'MM/DD/YYYY').isValid()) {
      validatedValue = moment(value).format('YYYY-MM-DD');
    }
    const newState = informationData.map((obj) => {
      // 👇️ if id equals 2, update country property
      if (obj.columnName === columnName) {
        return { ...obj, columnVal: validatedValue };
      }
      // 👇️ otherwise return the object as is
      return obj;
    });
      // setInformationData(newState);
    saveCaseDetails({ type: props.type, data: newState });
  };

  const classes = styles.InformationStyles();
  const textFieldClasses = styles.InputTextFieldStyles();

  const handleOptionsMapping = (columnName) => {
    const optionsObj = COMBOBOX_OPTIONS_PAIRS_MAPPING.filter(
      rec => rec.columnName === columnName,
    );
    if (optionsObj && optionsObj.length >= 1) {
      const { source, value } = optionsObj[0];
      let options = [];
      if (source === 'UI') { options = value; } else if (source === 'service') { options = props[value]; }
      if (options && options.length > 0) {
        return options.flatMap((rec) => {
          if (typeof (rec) === 'string' && rec.includes(',')) {
            return rec.split(',').map(str => ({ value: str, label: str }));
          }
          return ({ value: rec, label: rec });
        });
      }
    }
    return [{ label: '', value: '' }];
  };

  const handleList = (evalValue) => {
    const {
      columnDataType,
      columnDisplayName,
      columnKey,
      columnName,
      columnVal,
      isOverridable,
      isOverride,
    } = evalValue;
    switch (columnDataType) {
      case 'ComboBox': {
        // const options = [{ value: columnVal, label: columnVal },
        //   { value: 'test', label: 'test' }];
        const options = handleOptionsMapping(columnName);
        return (
          <Grid
            className={classes.caseFields}
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6} xs={6}>
              <Typography className={classes.caseLabel}>
                {columnDisplayName}
              </Typography>
            </Grid>
            <Grid className={classes.caseField} item md={6}>
              {isOverridable ? (
                <Checkbox
                  checked={isOverride}
                  classes={{ root: classes.checkBoxRoot }}
                  disabled={!isCaseEditable
                    || (isCaseEditable && (fieldsToDisable && fieldsToDisable.length > 0
                    && fieldsToDisable.includes(columnName)))}
                  onChange={handleCheck}
                  size="small"
                  value={columnName}
                />
              ) : null}
              <Dropdown
                handleSelect={handleEvent}
                label={columnName}
                options={options}
                override={(isCaseEditable && isOverridable) ? !isOverride : true}
                value={columnVal}
              />
            </Grid>
          </Grid>
        );
      }
      case 'TextBox': {
        return (
          <Grid
            className={classes.caseFields}
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6} xs={6}>
              <Typography className={classes.caseLabel}>
                {columnDisplayName}
              </Typography>
            </Grid>
            <Grid className={classes.caseField} item md={6} xs={6}>
              {isOverridable ? (
                <Checkbox
                  checked={isOverride}
                  classes={{ root: classes.checkBoxRoot }}
                  disabled={!isCaseEditable}
                  onChange={handleCheck}
                  size="small"
                  value={columnName}
                />
              ) : null}
              {/* <InputTextField
                id={columnKey.toString()}
                // inputProps={columnName === NEW_TERM ? { pattern: '^[0-9]+$|^$' } : {}}
                label={columnName}
                onChange={handleEvent}
                override={(isCaseEditable && isOverridable) ? !isOverride : true}
                type={columnName === NEW_TERM ? 'number' : 'text'}
                value={columnVal}
              /> */}
              <FormControl className={textFieldClasses.root}>
                <TextField
                  className={textFieldClasses.TextFieldDiv}
                  disabled={(isCaseEditable && isOverridable) ? !isOverride : true}
                  id={columnKey.toString()}
                  InputProps={{
                    disableUnderline: true,
                    style: { paddingLeft: '5px' },
                  }}
                  onChange={e => handleEvent(columnName, e.target.value)}
                  value={columnVal}
                />
              </FormControl>
            </Grid>
          </Grid>
        );
      }
      case 'RateTextBox': {
        const rateValue = columnVal === '' || columnVal === null ? '' : columnVal * 100;
        return (
          <Grid
            className={classes.caseFields}
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6} xs={6}>
              <Typography className={classes.caseLabel}>
                {columnDisplayName}
              </Typography>
            </Grid>
            <Grid className={classes.caseField} item md={6} xs={6}>
              {isOverridable ? (
                <Checkbox
                  checked={isOverride}
                  classes={{ root: classes.checkBoxRoot }}
                  disabled={!isCaseEditable}
                  onChange={handleCheck}
                  size="small"
                  value={columnName}
                />
              ) : null}
              <RateTextBox
                columnType="RateTextBox"
                id={columnKey.toString()}
                label={columnName}
                onChange={handleEvent}
                override={(isCaseEditable && isOverridable) ? !isOverride : true}
                // type="number"
                value={rateValue === null || rateValue === '' ? `${rateValue}` : `${Number(rateValue).toFixed(3)} %`}
              />
            </Grid>
          </Grid>
        );
      }
      case 'DateTextBox': {
        if ((columnName !== NON_DEL_APPROVAL_DATE)
          || (columnName === NON_DEL_APPROVAL_DATE)) {
          const dateValue = moment(columnVal).isValid() ? moment(columnVal).format('MM/DD/YYYY') : null;
          const checkDisabled = () => {
            if ((resolutionChoiceType === DISASTER_PARTIAL_CLAIM
              && columnName === LAST_PAID_INSTALLMENT)
              || (caseType === VA_SERVICING_PURCHASE && columnName === VA_APPROVAL_DATE
                && lockFlag === 0)) {
              return true;
            } if ((caseType === VA_SERVICING_PURCHASE && columnName === VA_APPROVAL_DATE
                  && lockFlag === 1)) {
              return false;
            }
            return !isCaseEditable;
          };
          const checkOveride = () => {
            if (isCaseEditable) {
              if (isOverridable) {
                return isOverride;
              }
            } if (!isCaseEditable) {
              const check = checkDisabled();
              if (!check && isOverride) {
                return true;
              }
            }
            return false;
          };
          return (
            <Grid
              className={classes.caseFields}
              container
              direction="row"
              spacing={2}
            >
              <Grid item md={6} xs={6}>
                <Typography className={classes.caseLabel}>
                  {columnDisplayName}
                </Typography>
              </Grid>
              <Grid className={classes.caseField} item md={6} xs={6}>
                {isOverridable || (resolutionChoiceType === DISASTER_PARTIAL_CLAIM
                && columnName === LAST_PAID_INSTALLMENT) ? (
                  <Checkbox
                    checked={isOverride}
                    classes={{ root: classes.checkBoxRoot }}
                    disabled={checkDisabled()}
                    onChange={handleCheck}
                    size="small"
                    value={columnName}
                  />
                  ) : null}
                <DatePicker
                  columnName={columnName}
                  delegationIndicator={delegationIndicator}
                  format="MM/DD/YYYY"
                  handleChange={handleEvent}
                  id={columnName}
                  label={columnName}
                  override={checkOveride()}
                  value={dateValue}
                />
              </Grid>
            </Grid>
          );
        }
        return <></>;
      }
      case 'AmountTextBox': {
        return (
          <Grid
            className={classes.caseFields}
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6} xs={6}>
              <Typography className={classes.caseLabel}>
                {columnDisplayName}
              </Typography>
            </Grid>
            <Grid className={classes.caseField} item md={6} xs={6}>
              {isOverridable ? (
                <Checkbox
                  checked={isOverride}
                  classes={{ root: classes.checkBoxRoot }}
                  disabled={!isCaseEditable}
                  onChange={handleCheck}
                  size="small"
                  value={columnName}
                />
              ) : null}
              <CurrencyTextBox
                columnType="AmountTextBox"
                handleChange={handleEvent}
                id={columnKey.toString()}
                label={columnName}
                override={(isCaseEditable && isOverridable) ? !isOverride : true}
                selectedValue={getRoundedValue(columnVal)}
              />
            </Grid>
          </Grid>
        );
      }
      case 'CheckBox': {
        return (
          <Grid
            className={classes.caseFields}
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6} xs={6}>
              <Typography className={classes.caseLabel}>
                {columnDisplayName}
              </Typography>
            </Grid>
            <Grid className={classes.checkBoxGrid} item md={6} xs={6}>
              <Checkbox
                checked={columnVal === 'True'}
                classes={{ root: classes.checkBoxRoot }}
                disabled={!isCaseEditable || !isOverridable}
                onChange={e => handleEvent(columnName, e.target.checked, 'CheckBox')}
                size="small"
              />
            </Grid>
          </Grid>
        );
      }
      case 'RadioButton': {
        return (
          <Grid
            className={classes.caseFields}
            container
            direction="row"
            spacing={2}
          >
            <Grid item md={6} xs={6}>
              <Typography className={classes.caseLabel}>
                {columnDisplayName}
              </Typography>
            </Grid>
            <Grid className={classes.caseField} item md={6} xs={6}>
              <FormControl disabled={!isCaseEditable || !isOverridable}>
                <RadioGroup name="radio" onChange={e => handleEvent(columnName, e.target.value)} row value={columnVal}>
                  <FormControlLabel control={<Radio />} label="Yes" value="true" />
                  <FormControlLabel control={<Radio />} label="No" value="false" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      }
      default: {
        return <></>;
      }
    }
  };
  return informationData
    ? informationData.map(value => handleList(value))
    : null;
};

Information.defaultProps = {
  type: GEN_INFO,
  saveCaseDetails: () => { },
  data: {},
  delegationIndicator: '',
  isCaseEditable: false,
};

Information.propTypes = {
  caseType: PropTypes.String,
  data: PropTypes.Object,
  delegationIndicator: PropTypes.String,
  isCaseEditable: PropTypes.Bool,
  lockFlag: PropTypes.Bool,
  saveCaseDetails: PropTypes.func,
  type: PropTypes.String,
};

const mapDispatchToProps = dispatch => ({
  saveCaseDetails: operations.saveCaseDetailsOpn(dispatch),
});

const mapStateToProps = state => ({
  deferralType: selectors.deferralType(state),
  workoutTypes: selectors.workoutTypes(state),
  capitalizeCurrentForbearance: selectors.capitalizeCurrentForbearance(state),
  capitalizeFutureEscrow: selectors.capitalizeFutureEscrow(state),
  trialDuration: selectors.trialDuration(state),
  escrowSpreadMonths: selectors.escrowSpreadMonths(state),
  lockFlag: selectors.lockFlag(state),
  caseType: selectors.caseType(state),
});

const InformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Information);

export default InformationContainer;
