/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import Loader from 'components/Loader/Loader';
import Tombstone from 'containers/Dashboard/Tombstone';
import * as R from 'ramda';
import { withRouter, Link } from 'react-router-dom';
import RouteAccess from 'lib/RouteAccess';
import EndShift from 'models/EndShift';
import DashboardModel from 'models/Dashboard';
import SweetAlertBox from 'components/SweetAlertBox';
import UserNotification from 'components/UserNotification/UserNotification';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import moment from 'moment-timezone';
import { selectors as widgetsSelectors, operations as widgetsOperations } from 'ducks/widgets';
import { operations as tombstoneOperations, selectors as tombstoneSelectors } from 'ducks/tombstone';
import { operations as indexerOperations } from 'ducks/indexer';
import { operations as docChecklistOperations } from 'ducks/document-checklist';
import { selectors as accountServiceSelectors } from 'ducks/accountService';
import { operations as repaymentEvalOperations } from 'ducks/repaymentEval';
import Grid from '@material-ui/core/Grid';
import { operations as milestoneOperations } from 'ducks/milestone-activity';
import { closeWidgets } from 'components/Widgets/WidgetSelects';
import IncomeCalcWidget from 'containers/IncomeCalc/IncomeCalcWidget';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import {
  MenuItem, Menu, IconButton, Button,
} from '@material-ui/core/index';
import CloseIcon from '@material-ui/icons/Close';
import DocChecklistWidget from 'components/Widgets/DocChecklistWidget';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import EvalWidget from 'components/Widgets/EvalWidget';
import Validators from 'lib/Validators';
import AccountServices from 'containers/AccountServices';
import { operations as smduOperations } from '../../../state/ducks/smdu';
import RepaymentCaseScreen from '../../Repayment/RepaymentCaseScreen';
import EvalCase from '../../Eval/EvalCase';
import RFDContent from '../../../components/Tombstone/TombstoneComponents/RFDContent';
import CollateralContent from '../../../components/Tombstone/TombstoneComponents/CollateralContent';
import RepossessionTableContent from '../../../components/Tombstone/TombstoneComponents/RepossessionTableContent';
import Fico from '../../../components/Fico/Fico';
import NoEvalsPage from '../NoEvalsPage';
import { DONE_POPUP_CONFIRMATION_MSG } from '../../../constants/eval';
import InvalidLoanPage from '../InvalidLoanPage';
import { EvalTableRow } from '../EvalTable';
import { operations, selectors } from '../../../state/ducks/dashboard';
import { operations as checkListOperations } from '../../../state/ducks/tasks-and-checklist';
import './SearchLoan.css';
import WidgetBuilder from '../../../components/Widgets/WidgetBuilder';
import AdditionalInfo from '../../AdditionalInfo/AdditionalInfo';
import GoBackToSearch from '../../../components/GoBackToSearch/GoBackToSearch';
import {
  ADDITIONAL_INFO, HISTORY, DOCUMENT_CHECKLIST, LSAMS_NOTES, FINANCIAL_CALCULATOR, EVAL,
} from '../../../constants/widgets';
import MilestoneActivity from '../../LoanActivity/MilestoneActivity';
import {
  CASE_DETAILS, RFD, COLLATERAL, SEARCH_PAGE_POP_UP_SCREEN,
  STANDARD_EVAL_SCREEN, REPAYMENT_PLAN_CASE, LOAN_SEARCH_SCREEN,
  DISASTER_EVAL_SCREEN, FICO_SCORE,
  REPO,
} from '../../../constants/loanInfoComponents';
import Popup from '../../../components/Popup';
import {
  COMPLETED, REJECTED, ACTIVE, SUSPENDED, MISSING_DOCS, FINS, IN_REVIEW, CLOSED,
} from '../../../constants/status';
import CaseDetailsComp from '../../Eval/MODCaseDetails';
import { selectors as evalSelectors, operations as evalOperations } from '../../../state/ducks/eval';
import { operations as stagerOperations } from '../../../state/ducks/stager';
import {
  BACKEND_UNDERWRITER, DOCS_IN, DOC_PROCESSOR, INVSET, LOSS_MITIGATION,
  LOSS_MITIGATION_MGR, SECONDLOOK, FRONTEND_UNDERWRITER, DOC_GEN, POSTMOD, POSTMOD_MGR,
} from '../../../constants/Groups';

const renderComponents = (checklistCenterPaneView) => {
  switch (checklistCenterPaneView) {
    case RFD:
      return (
        <RFDContent />
      );
    case COLLATERAL:
      return (
        <CollateralContent />
      );
    case CASE_DETAILS:
      return (
        <CaseDetailsComp />
      );
    case REPAYMENT_PLAN_CASE:
      return (
        <RepaymentCaseScreen />
      );
    case FICO_SCORE:
      return (
        <Fico isNavigatedFromTombstone />
      );
    case 'AccountServices': return <AccountServices inLoanSearchPage />;
    case REPO: return <RepossessionTableContent />;
    default:
      return (
        <EvalCase />
      );
  }
};


class SearchLoan extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      isOpen: false,
      isDropDownVisible: false,
      anchorEl: null,
      isCreateModVisible: true,
      isDoneOpen: false,
      isEvalTypePopup: false,
      isStandardEvalOptions: false,
      standardEvalOptions: [{ option: 'Pre-Approved', isChecked: false },
        { option: 'Inflight', isChecked: false },
        { option: 'Non Delegated', isChecked: false },
      ],
    };
    this.redirectPath = '';
    this.renderSearchResults = this.renderSearchResults.bind(this);
    this.renderRejectResults = this.renderRejectResults.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.getParamsValue = this.getParamsValue.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.validateLoanNumber = this.validateLoanNumber.bind(this);
    this.getLoanActivityPath = this.getLoanActivityPath.bind(this);
    this.handleDropdown = this.handleDropdown.bind(this);
    this.handleEvalscreen = this.handleEvalscreen.bind(this);
    this.handleEvalOrModClose = this.handleEvalOrModClose.bind(this);
    this.handleCreateEval = this.handleCreateEval.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }


  componentDidMount() {
    const {
      evalId, onAutoSave, isAssigned, onClearStagerTaskName,
      onGetGroupName, user, setStagerActiveSearchTerm, onSearchLoan,
    } = this.props;
    const groups = user && user.groupList ? user.groupList : [];
    const isAgent = groups && (groups.includes(LOSS_MITIGATION)
      || groups.includes(LOSS_MITIGATION_MGR) || groups.includes(BACKEND_UNDERWRITER)
      || groups.includes(SECONDLOOK) || groups.includes(INVSET)
      || groups.includes(DOCS_IN) || groups.includes(DOC_GEN)
      || groups.includes(DOC_PROCESSOR) || groups.includes(FRONTEND_UNDERWRITER)
      || groups.includes(POSTMOD) || groups.includes(POSTMOD_MGR));
    if (isAgent) {
      this.setState({
        isCreateModVisible: false,
      });
    }
    if (!R.isEmpty(evalId) && !R.isNil(evalId) && isAssigned) {
      onAutoSave('Paused');
    }
    onGetGroupName('SEARCH_LOAN');
    // onGetRFDData();
    onClearStagerTaskName();
    setStagerActiveSearchTerm();
    const loanNumber = this.getParamsValue();
    const validLoanNumber = this.validateLoanNumber();
    if (validLoanNumber) {
      onSearchLoan(loanNumber);
    }
  }

  componentDidUpdate() {
    const { onSearchLoan, searchLoanNbr } = this.props;
    const currentLoanNbr = this.getParamsValue();
    const validLoanNumber = this.validateLoanNumber();
    if (validLoanNumber && currentLoanNbr !== searchLoanNbr) {
      onSearchLoan(currentLoanNbr);
    }
  }

  getMilestoneActivityPath() {
    const { user } = this.props;
    const groups = user && user.groupList;
    return RouteAccess.hasMilestoneActivityAccess(groups) ? '/milestone-activity' : '/';
  }

  getLoanActivityPath() {
    const { user } = this.props;
    const groups = user && user.groupList;
    return RouteAccess.hasLoanActivityAccess(groups) ? '/loan-activity' : '/';
  }

  getParamsValue() {
    const { location } = this.props;
    const params = location.search;
    const loanNumberSearch = new URLSearchParams(params);
    return loanNumberSearch.get('loanNumber');
  }

  handleClose = () => {
    const { closeSweetAlert } = this.props;
    closeSweetAlert();
  }

  goToSearchResults = () => {
    const { groupName } = this.state;
    const { onGetGroupName } = this.props;
    if (groupName !== 'SEARCH_LOAN') {
      onGetGroupName('SEARCH_LOAN');
    }
    const {
      onWidgetToggle, openWidgetList, onGoBackToSearch, tombstoneData,
    } = this.props;
    onGoBackToSearch();
    const widgetsToBeClosed = {
      openWidgetList,
      page: 'SEARCH_LOAN',
      closingWidgets: [HISTORY, ADDITIONAL_INFO],
    };
    const widgetList = closeWidgets(widgetsToBeClosed);
    const payload = {
      currentWidget: '',
      openWidgetList: widgetList,
      data: tombstoneData,
    };
    onWidgetToggle(payload);
  }

  handleEvalOrModClose = () => {
    const { standardEvalOptions } = this.state;
    this.setState({
      anchorEl: null,
      isDropDownVisible: false,
      isStandardEvalOptions: false,
      standardEvalOptions: standardEvalOptions.map(o => (
        { ...o, isChecked: false }
      )),
    });
  };

  onDoneConfirm = () => {
    const {
      setChecklistCenterPaneData, onWidgetToggle, openWidgetList, setBeginSearch,
      clearRepaymentData, clearEvalData, setEvalWidget,
    } = this.props;
    this.setState({ isDoneOpen: false });
    const { onSearchLoan } = this.props;
    const loanNumber = this.getParamsValue();
    setEvalWidget(false);
    clearEvalData();
    clearRepaymentData();
    setBeginSearch();
    onSearchLoan(loanNumber);
    setChecklistCenterPaneData(LOAN_SEARCH_SCREEN);
    if (!R.isEmpty(openWidgetList)) {
      const payload = {
        currentWidget: '',
        openWidgetList: [],
        page: 'SEARCH_LOAN',
      };
      onWidgetToggle(payload);
    }
  };

  onDoneCancel = () => {
    this.setState({ isDoneOpen: false });
  };

  handleDoneClick = () => {
    this.setState({ isDoneOpen: true });
  };

  handleDropdown = (event) => {
    this.setState({
      isDropDownVisible: true,
      anchorEl: event.currentTarget,
    });
  };

  handleCreate = (item) => {
    if (this.isStandardEval(item)) {
      this.setState({
        isEvalTypePopup: item,
      });
    } else if (item === STANDARD_EVAL_SCREEN) {
      this.setState({ isStandardEvalOptions: true });
    } else {
      this.handleCreateEval(item);
    }
  };

  handleCreateEval = (value) => {
    const {
      createNewMod, createNewEvalCase, setModStatus,
      clearRepaymentData, clearEvalData,
    } = this.props;
    const { standardEvalOptions } = this.state;
    this.setState({
      isDropDownVisible: false,
      anchorEl: null,
      isStandardEvalOptions: false,
      standardEvalOptions: standardEvalOptions.map(o => (
        { ...o, isChecked: false }
      )),
    });
    clearEvalData();
    clearRepaymentData();
    if (value === REPAYMENT_PLAN_CASE) {
      createNewMod({ selectedCaseType: 'Repayment Plan' });
      setModStatus(false);
    } else {
      const nonDelegated = R.find(data => data.option === 'Non Delegated', standardEvalOptions);
      const preApproved = R.find(data => data.option === 'Pre-Approved', standardEvalOptions);
      const inflight = R.find(data => data.option === 'Inflight', standardEvalOptions);
      createNewEvalCase({
        disasterType: value !== STANDARD_EVAL_SCREEN ? 'disaster' : null,
        evalType: value,
        isNonDelegated: nonDelegated.isChecked,
        isPreApproved: preApproved.isChecked,
        isInflight: inflight.isChecked,
      });
      setModStatus(false);
    }
  };

  handleSelect = (name) => {
    this.setState(prevState => ({
      standardEvalOptions: prevState.standardEvalOptions.map((opt) => {
        if (opt.option === name) {
          return { ...opt, isChecked: !opt.isChecked };
        }
        return opt;
      }),
    }));
  };

  isStandardEval = (value) => {
    const { evalDropdownData } = this.props;
    return value === STANDARD_EVAL_SCREEN && evalDropdownData.includes(DISASTER_EVAL_SCREEN);
  };

  handleEvalscreen = (value, rowValue, isLossMitAgent, isActive) => {
    const {
      setChecklistCenterPaneData, setEvalId, setRepaymentCaseId, setModStatus, fetchBorrowers,
      resetDocChecklistData, setSelectedCaseId, setProcessId,
    } = this.props;
    const { isNotNullAndNotEmptyorNotZero } = Validators;
    this.setState({
      isDropDownVisible: false,
      anchorEl: null,
    });
    setEvalId(rowValue.evalId);
    setProcessId({ processId: rowValue.piid });
    const { evalId } = rowValue;
    resetDocChecklistData();
    fetchBorrowers({ loanNumber: rowValue.loanNumber, processId: rowValue.piid });
    if (isLossMitAgent) {
      if ((evalId === '0' || R.isNil(evalId))) {
        setSelectedCaseId(rowValue.resolutionId);
        setRepaymentCaseId(rowValue.resolutionId);
        setChecklistCenterPaneData(REPAYMENT_PLAN_CASE);
      } else if (isNotNullAndNotEmptyorNotZero(evalId)) {
        setChecklistCenterPaneData(STANDARD_EVAL_SCREEN);
        setModStatus(isActive);
      }
      return;
    }
    if (isNotNullAndNotEmptyorNotZero(evalId)) {
      setChecklistCenterPaneData(value);
      setModStatus(isActive);
    }
  }

  handleRowClick(payload, rowInfo, disasterType) {
    const {
      user, checkTrialStagerButton, setChecklistCenterPaneData, setRepaymentCaseId,
      setIndexingPageVisibility, fetchBorrowers, openSweetAlert, setEvalId,
    } = this.props;
    const adGroups = user && user.groupList;
    const { isNotNullAndNotEmptyorNotZero } = Validators;
    const isPostMod = payload.milestone === 'Post Mod';
    if (payload.pstatus === 'Completed' || payload.pstatus === 'Terminated') {
      this.setState({ isOpen: true });
      return;
    }
    if (rowInfo.Header !== 'ACTIONS') {
      let group = '';
      if ((payload.assignee !== 'In Queue' || DashboardModel.ALLOW_IN_QUEUE.includes(payload.taskName) || (isPostMod && RouteAccess.hasSlaAccess(adGroups))) && payload.assignee !== 'N/A') {
        switch (payload.taskName) {
          case 'Underwriting':
            group = 'BEUW';
            this.redirectPath = '/backend-checklist';
            break;
          case 'Processing':
            group = 'PROC';
            this.redirectPath = '/doc-processor';
            break;
          case 'Trial Modification':
          case 'Forbearance':
            group = 'LA';
            checkTrialStagerButton();
            this.redirectPath = this.getLoanActivityPath();
            break;
          case 'Document Generation':
            group = 'DOCGEN';
            this.redirectPath = '/doc-gen';
            break;
          case 'Docs In':
            group = 'DOCSIN';
            this.redirectPath = '/docs-in';
            break;
          case DashboardModel.PENDING_BOOKING:
            group = 'BOOKING';
            this.redirectPath = '/special-loan';
            break;
          case 'Investor Settlement':
            group = 'INVSET';
            this.redirectPath = '/investor-settlement';
            break;
          case 'Second Look':
            group = 'SECONDLOOK';
            this.redirectPath = '/second-look';
            break;
          case 'Loss Mitigation':
            group = 'LOSSMITIGATION';
            this.redirectPath = '/loss-mitigation';
            break;
          default:
            this.redirectPath = '/frontend-checklist';
            group = 'FEUW';
        }
      }

      if ((payload.pstatus === 'Suspended' && payload.pstatusReason === 'Approved for Doc Generation')
        || (payload.tstatus === 'Active' && payload.taskName === 'Docs Sent')) {
        this.redirectPath = '/doc-gen-back';
        group = 'DGB';
      }

      if ((payload.tstatus === 'Active' && payload.taskName === 'Pending Buyout')
        || (payload.tstatus === 'Active' && payload.taskName === DashboardModel.PENDING_BOOKING)
        || (payload.pstatus === 'Suspended' && payload.pstatusReason === 'Mod Booked')) {
        this.redirectPath = '/docs-in-back';
        group = 'DIB';
      }

      if ((
        payload.taskName === DashboardModel.PENDING_BOOKING
        || (isPostMod && !(payload.taskName === 'Investor Settlement'))
      ) && RouteAccess.hasSlaAccess(adGroups)) {
        this.redirectPath = '/special-loan';
        group = 'BOOKING';
      }

      const statusReasons = [MISSING_DOCS, FINS, IN_REVIEW];
      if (payload.evalStatus === ACTIVE && statusReasons.includes(payload.statusReason)
        && adGroups.includes('indexer') && R.length(adGroups) === 1
      ) {
        group = 'Indexer';
        this.redirectPath = '/indexer';
        setIndexingPageVisibility(true);
        fetchBorrowers({ loanNumber: payload.loanNumber, processId: payload.piid });
      }

      if (payload.resolutionChoiceType === 'Repayment Plan' && ((isNotNullAndNotEmptyorNotZero(payload.evalId)
        && isNotNullAndNotEmptyorNotZero(disasterType[payload.resolutionId]))
        || (+payload.evalId === 0 || payload.evalId === null))) {
        if ((+payload.evalId === 0 || payload.evalId === null) && !RouteAccess.hasLossMitigationAccess(adGroups)) {
          const sweetAlertPayload = {
            status: 'Access Denied. You do not have permission to view this case. Please select a different case and try again.',
            level: 'Error',
            showConfirmButton: true,
          };
          openSweetAlert(sweetAlertPayload);
          return;
        }
        setEvalId(payload.evalId);
        setRepaymentCaseId(payload.resolutionId);
        setChecklistCenterPaneData(REPAYMENT_PLAN_CASE);
        return;
      }

      if (!R.isEmpty(group)) {
        const {
          onSelectEval, onGetGroupName,
        } = this.props;
        onGetGroupName(group);
        onSelectEval(payload);
        this.setState({ isRedirect: true });
      }
    }
  }

  handleBackButton() {
    const { onEndShift } = this.props;
    onEndShift(EndShift.CLEAR_DASHBOARD_DATA);
  }

  validateLoanNumber() {
    const { searchLoanResult } = this.props;
    return R.isEmpty(searchLoanResult)
      || (searchLoanResult
        && searchLoanResult.loanNumber);
  }

  renderRejectResults() {
    const { getRejectResponse } = this.props;
    return !R.isEmpty(getRejectResponse) ? (
      <div styleName="notificationMsg">
        <UserNotification
          level={getRejectResponse.level}
          message={getRejectResponse.message}
          type="alert-box"
        />
      </div>
    ) : null;
  }

  renderCollateralAlert() {
    const { clearPopupData, popupData } = this.props;
    if (popupData) {
      const {
        isOpen, message, title, level,
        confirmButtonText, onConfirm,
      } = popupData;
      const confirmAction = clearPopupData;
      return (
        <Popup
          confirmButtonText={confirmButtonText}
          level={level}
          message={message}
          onConfirm={() => confirmAction(onConfirm)}
          show={isOpen}
          showConfirmButton
          title={title}
        />
      );
    }
    return null;
  }

  renderLoanInfoComponents() {
    const { checklistCenterPaneView } = this.props;
    return (
      <Grid styleName="loan-info-components">
        {renderComponents(checklistCenterPaneView)}
        {this.renderCollateralAlert()}
      </Grid>
    );
  }

  renderAddIcon() {
    const { isCreateModVisible } = this.state;
    return (
      <>
        <Tooltip placement="left" title="Create New Mod">
          <Fab
            aria-label="add"
            color="primary"
            disabled={isCreateModVisible}
            onClick={this.handleDropdown}
            styleName="evalOrModButton"
          >
            <AddIcon styleName="evalOrModAddIcon" />
          </Fab>
        </Tooltip>
      </>
    );
  }

  renderCloseIcon() {
    return (
      <>
        <Tooltip placement="left" title="Close Mod">
          <Fab
            aria-label="close"
            color="primary"
            onClick={this.handleDropdown}
            styleName="evalOrModButton"
          >
            <CloseIcon styleName="evalOrModAddIcon" />
          </Fab>
        </Tooltip>
      </>
    );
  }

  renderSearchResults() {
    const {
      searchLoanResult, history, checklistCenterPaneView, evalDropdownData,
      openWidgetList, user, setEvalScreenIcon,
    } = this.props;
    const isDocchecklistOpen = R.contains(DOCUMENT_CHECKLIST, openWidgetList);
    const {
      isRedirect, isDropDownVisible, anchorEl, isDoneOpen, isEvalTypePopup,
      isStandardEvalOptions, standardEvalOptions,
    } = this.state;
    const groups = user && user.groupList ? user.groupList : [];
    const isLossMitAgent = groups && (groups.includes(LOSS_MITIGATION)
      || groups.includes(LOSS_MITIGATION_MGR));
    if (isRedirect) {
      history.push(this.redirectPath);
    }
    if (searchLoanResult.statusCode || searchLoanResult.userHasAccess === false) {
      const val = searchLoanResult.statusCode ? searchLoanResult.statusCode : 'No';
      return (
        <InvalidLoanPage loanNumber={val} />
      );
    }
    if (searchLoanResult.loanNumber) {
      const {
        loanNumber, unAssigned, assigned, valid, disasterType,
      } = searchLoanResult;
      let data = [];
      if (valid) { // valid loan number
        if (!unAssigned && !assigned) { // no eval cases present
          return <NoEvalsPage loanNumber={loanNumber} />;
        }
        if (unAssigned) {
          data.push(...unAssigned);
        }
        if (assigned) {
          data.push(...assigned);
        }
        data = R.sort(R.descend(
          R.compose(
            Number.parseInt,
            R.prop('date'),
          ),
        ), data);
        const { inProgress, createEvalLoader } = this.props;
        if (inProgress) {
          return (
            <Loader message="Please Wait" />
          );
        }
        const activeMods = [];
        const completedMods = [];
        const evalCompletedStatus = [COMPLETED, REJECTED];
        const statusReasons = [MISSING_DOCS, FINS];
        const resolutionStatus = [REJECTED, CLOSED];
        /* If the task is in active status (or)
        /* If the task is in active status (or) suspended (or)
        eval status is not Rejected or Completed and no process is present in CMOD,
        then display eval in the In Progress section
        else in completed section */
        data.forEach((mod) => {
          if (mod.pstatus === ACTIVE || mod.pstatus === SUSPENDED
            || (!mod.piid && !evalCompletedStatus.includes(mod.evalStatus))
            || (mod.evalStatus === ACTIVE
              && mod.pstatus === SUSPENDED && statusReasons.includes(mod.statusReason))) {
            activeMods.push(mod);
          } else if (mod.resolutionChoiceType === 'Repayment Plan'
            && mod.evalId === '0' && !resolutionStatus.includes(mod.resolutionStatus)) {
            activeMods.push(mod);
          } else completedMods.push(mod);
        });
        return (
          <>
            {SEARCH_PAGE_POP_UP_SCREEN.includes(checklistCenterPaneView)
              ? (
                <div styleName="mod-view-header">
                  <span>
                    <p styleName="mod-text">
                      MOD VIEW
                    </p>
                  </span>
                  <span styleName="doneBtnSpan">
                    <Button color="primary" onClick={this.handleDoneClick} styleName="doneButton" variant="contained">
                      <p styleName="doneText">DONE</p>
                    </Button>
                    {isDoneOpen && (
                      <>
                        <Popup
                          cancelButtonText="No"
                          confirmButtonText="Yes"
                          displayGif={false}
                          level=""
                          message={DONE_POPUP_CONFIRMATION_MSG}
                          onCancel={this.onDoneCancel}
                          onConfirm={this.onDoneConfirm}
                          show={isDoneOpen}
                          showCancelButton={isDoneOpen}
                          title=""
                          useOnCancel
                        />
                      </>
                    )}
                  </span>
                </div>
              ) : (
                <>
                  <div styleName="resultText">
                    <p styleName="search-text">Search result for </p>
                    <p styleName="searchResutlText">{loanNumber}</p>
                  </div>
                </>
              )
            }
            <div styleName="search-container">
              <Tombstone />
              {createEvalLoader ? (
                <div style={{
                  display: 'flex', justifyContent: 'center', width: '100%', position: 'absolute', marginTop: '30vh',
                }}
                >
                  <Loader message="Please wait while creating Eval/Repay Case" />
                </div>
              ) : (
                <div styleName="eval-table-container">
                  {
                    isDocchecklistOpen ? <DocChecklistWidget /> : (
                      <>
                        {SEARCH_PAGE_POP_UP_SCREEN.includes(checklistCenterPaneView) && !R.contains(EVAL, openWidgetList)
                          ? this.renderLoanInfoComponents()
                          : (
                            <>
                              <div styleName="eval-table-height-limiter">
                                {R.contains(LSAMS_NOTES, openWidgetList) && <LSAMSNotesWidget />}
                                {R.contains(EVAL, openWidgetList) && <EvalWidget />}
                                {R.contains(FINANCIAL_CALCULATOR, openWidgetList)
                                  && searchLoanResult.valid && <IncomeCalcWidget />}
                                <div style={{
                                  display: (
                                    R.contains(LSAMS_NOTES, openWidgetList) || R.contains(FINANCIAL_CALCULATOR, openWidgetList)
                                    || R.contains(EVAL, openWidgetList)
                                  ) ? 'none' : '',
                                }}
                                >
                                  <h3 style={{ paddingLeft: '2rem' }}> MOD HISTORY </h3>
                                  <h3 style={{ paddingLeft: '2rem' }}> InProgress </h3>
                                  <ReactTable
                                    className="-striped -highlight"
                                    columns={SearchLoan.COLUMN_DATA}
                                    data={activeMods}
                                    getTdProps={(state, rowInfo, column) => ({
                                      onClick: (event) => {
                                        const payload = {
                                          loanNumber,
                                          ...rowInfo.original,
                                          isSearch: true,
                                        };
                                        if (column.id === 'viewIcon' && !R.isNil(rowInfo.original.evalId)) {
                                          this.handleEvalscreen(
                                            STANDARD_EVAL_SCREEN, rowInfo.original,
                                            isLossMitAgent, true,
                                          );
                                          setEvalScreenIcon('viewIcon');
                                        } else if (rowInfo.original.sourceLabel === 'REMEDY' || column.Header === 'HISTORY') {
                                          event.stopPropagation();
                                        } else {
                                          this.handleRowClick(payload, column, disasterType);
                                        }
                                      },
                                      style: {
                                        height: activeMods && activeMods.length > 0 ? '3rem' : '6rem',
                                      },
                                    })}
                                    getTheadThProps={() => ({
                                      style: {
                                        'font-weight': 'bold', 'font-size': '10px', color: '#9E9E9E', 'text-align': 'left',
                                      },
                                    })}
                                    manual
                                    minRows={1}
                                    showPagination={false}
                                    styleName={activeMods && activeMods.length <= 4 ? 'inprogressTableWithSingleRow' : 'inprogressTable'}
                                  />
                                  <h3 style={{ paddingLeft: '2rem' }}> Completed </h3>
                                  <ReactTable
                                    className="-striped -highlight"
                                    columns={SearchLoan.COLUMN_DATA}
                                    data={completedMods}
                                    getTdProps={(state, rowInfo, column) => ({
                                      onClick: (event) => {
                                        const payload = {
                                          loanNumber,
                                          ...rowInfo.original,
                                          isSearch: true,
                                        };
                                        if (column.id === 'viewIcon') {
                                          this.handleEvalscreen(
                                            STANDARD_EVAL_SCREEN, rowInfo.original,
                                            isLossMitAgent, true,
                                          );
                                          setEvalScreenIcon('viewIcon');
                                        } else if (rowInfo.original.sourceLabel === 'REMEDY' || column.Header === 'HISTORY') {
                                          event.stopPropagation();
                                        } else {
                                          this.handleRowClick(payload, column, disasterType);
                                        }
                                      },
                                      style: {
                                        height: completedMods && completedMods.length > 0 ? '3rem' : '6rem',
                                      },
                                    })}
                                    getTheadThProps={() => ({
                                      style: {
                                        'font-weight': 'bold', 'font-size': '10px', color: '#9E9E9E', 'text-align': 'left',
                                      },
                                    })}
                                    manual
                                    minRows={1}
                                    showPagination={false}
                                    styleName={completedMods && completedMods.length <= 4 ? 'completedTableWithSingleRow' : 'completedTable'}
                                  />
                                </div>

                                {openWidgetList && openWidgetList.length <= 0
                                  && (
                                    <Grid>
                                      <div>
                                        <Menu
                                          anchorEl={anchorEl}
                                          anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          id="simple-Evalmenu"
                                          keepMounted
                                          onClose={this.handleEvalOrModClose}
                                          open={isDropDownVisible && !isStandardEvalOptions}
                                          transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                        >
                                          <MenuItem disabled>Create New Mod</MenuItem>

                                          {evalDropdownData && evalDropdownData.map(item => (
                                            <MenuItem
                                              key={item}
                                              onClick={() => (this.handleCreate(item))}
                                              value={item}
                                            >
                                              {item}
                                            </MenuItem>
                                          ))}
                                        </Menu>
                                        <Menu
                                          anchorEl={anchorEl}
                                          anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                          }}
                                          id="simple-Evalmenu"
                                          keepMounted
                                          onClose={this.handleEvalOrModClose}
                                          open={isStandardEvalOptions}
                                          transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                        >
                                          <MenuItem disabled>Standard Eval</MenuItem>

                                          {standardEvalOptions.map(opt => (
                                            <MenuItem
                                              key={opt.option}
                                              value={opt.option}
                                            >
                                              <Checkbox
                                                checked={opt.isChecked}
                                                color="primary"
                                                onChange={() => this.handleSelect(opt.option)}
                                                size="small"
                                                styleName="checkbox"
                                              />
                                              <ListItemText primary={opt.option} />
                                            </MenuItem>
                                          ))}
                                          <MenuItem>
                                            <Button color="primary" onClick={() => this.handleCreateEval(STANDARD_EVAL_SCREEN)} styleName="createButton" variant="contained">
                                              CREATE
                                            </Button>
                                          </MenuItem>
                                        </Menu>
                                      </div>
                                      {isDropDownVisible ? this.renderCloseIcon()
                                        : this.renderAddIcon()}
                                      <Popup
                                        cancelButtonText="No"
                                        confirmButtonText="Yes"
                                        level="Info"
                                        onCancel={() => this.setState({ isEvalTypePopup: null })}
                                        onConfirm={() => this.setState({
                                          isEvalTypePopup: null,
                                          isStandardEvalOptions: true,
                                        })}
                                        show={isEvalTypePopup}
                                        showCancelButton
                                        showConfirmButton
                                        title="Account potentially disaster impacted, proceed with standard evaluation case creation?"
                                      />
                                    </Grid>
                                  )
                                }
                              </div>
                            </>
                          )}
                      </>
                    )
                  }

                </div>
              )}
            </div>
          </>
        );
      }
      return <InvalidLoanPage loanNumber={loanNumber} />;
    }
    return null;
  }

  renderAlert = () => {
    const { resultOperation } = this.props;
    const { status, level, isOpen } = resultOperation;
    return !R.isEmpty(resultOperation) ? (
      <SweetAlertBox
        confirmButtonColor="#004261"
        message={status}
        onConfirm={() => this.handleClose()}
        show={isOpen}
        showConfirmButton
        type={level}
      />
    ) : null;
  };

  render() {
    const {
      searchLoanResult, history, location, openWidgetList, checklistCenterPaneView,
    } = this.props;
    const { isOpen } = this.state;
    const data = [];
    const {
      loanNumber, unAssigned, assigned, valid,
    } = searchLoanResult;
    if (valid) {
      if (unAssigned) {
        data.push(...unAssigned);
      }
      if (assigned) {
        data.push(...assigned);
      }
    }

    const isEval = [STANDARD_EVAL_SCREEN].includes(checklistCenterPaneView)
      || R.contains(DOCUMENT_CHECKLIST, openWidgetList);
    return (
      <>
        {!R.isEmpty(searchLoanResult) && !R.contains(HISTORY, openWidgetList) && (
          <WidgetBuilder page={isEval ? 'EVAL_SCRN' : 'SEARCH_LOAN'} />
        )}
        {((R.contains(ADDITIONAL_INFO, openWidgetList) && valid)
          || R.contains(HISTORY, openWidgetList)) ? (
            <GoBackToSearch
              history={history}
              loanNumber={loanNumber}
              location={location}
              onClick={this.goToSearchResults}
            />
          )
          : checklistCenterPaneView === LOAN_SEARCH_SCREEN && (
            <span styleName="backButton">
              <Link onClick={this.handleBackButton} to="/">&lt; BACK</Link>
            </span>
          )
        }
        {R.contains(ADDITIONAL_INFO, openWidgetList) && valid
          && (
            <AdditionalInfo
              data={data}
              loanNumber={loanNumber}
              styleName="evalTable"
              type="searchPage"
            />
          )}
        {R.contains(HISTORY, openWidgetList) && <MilestoneActivity inSearchPage />}
        {!(R.contains(ADDITIONAL_INFO, openWidgetList) || R.contains(HISTORY, openWidgetList))
          && (
            <>
              {this.renderRejectResults()}
              {this.renderSearchResults()}
              {this.renderAlert()}
            </>
          )}
        <>
          <SweetAlertBox
            message="Unable to proceed with this eval as it is no longer active"
            onConfirm={() => { this.setState({ isOpen: false }); }}
            show={isOpen}
            type="Info"
          />
        </>
      </>
    );
  }
}

SearchLoan.COLUMN_DATA = [
  {
    Header: '',
    accessor: 'viewIcon',
    maxWidth: 40,
    minWidth: 40,
    Cell: () => <IconButton style={{ padding: '0rem 0.5rem' }}><RemoveRedEye styleName="viewIcon" /></IconButton>,
  },
  {
    Header: 'SOURCE',
    accessor: 'sourceLabel',
    maxWidth: 60,
    minWidth: 60,
    Cell: row => <EvalTableRow row={row} />,
  },
  {
    Header: 'BOARDING TYPE',
    accessor: 'boardingType',
    maxWidth: 95,
    minWidth: 95,
    Cell: row => <EvalTableRow row={row} />,
  },
  {
    Header: 'ACTIONS',
    accessor: 'actions',
    maxWidth: 65,
    minWidth: 65,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'EVAL ID',
    accessor: 'evalId',
    maxWidth: 65,
    minWidth: 65,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'PROCESS ID',
    accessor: 'piid',
    maxWidth: 70,
    minWidth: 70,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'RESOLUTION ID',
    accessor: 'resolutionId',
    maxWidth: 100,
    minWidth: 100,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'WORKOUT TYPE',
    accessor: 'workoutType',
    maxWidth: 180,
    minWidth: 180,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'RESOLUTIONCHOICETYPE',
    accessor: 'resolutionChoiceType',
    maxWidth: 180,
    minWidth: 180,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'STATUS',
    accessor: 'pstatus',
    maxWidth: 70,
    minWidth: 70,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'PROCESS STATUS REASON',
    accessor: 'pstatusReason',
    maxWidth: 150,
    minWidth: 150,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    id: 'pstatusDate',
    Header: 'STATUS DATE',
    accessor: d => (R.isNil(d.pstatusDate) ? ''
      : moment(d.pstatusDate).format('MM/DD/YYYY hh:mm:ss A')),
    maxWidth: 180,
    minWidth: 180,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'MILESTONE',
    accessor: 'milestone',
    maxWidth: 150,
    minWidth: 150,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'TASK NAME',
    accessor: 'taskName',
    maxWidth: 150,
    minWidth: 150,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'TASK STATUS',
    accessor: 'tstatus',
    maxWidth: 90,
    minWidth: 90,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'TASK STATUS REASON',
    accessor: 'statusReason',
    maxWidth: 130,
    minWidth: 130,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'TASK STATUS DATE',
    id: 'tstatusDate',
    accessor: d => (R.isNil(d.tstatusDate) ? ''
      : moment(d.tstatusDate).format('MM/DD/YYYY hh:mm:ss A')),
    maxWidth: 150,
    minWidth: 150,
    Cell: row => <EvalTableRow row={row} />,

  }, {
    Header: 'ASSIGNED DATE',
    id: 'assignedDate',
    accessor: d => (R.isNil(d.assignedDate) ? ''
      : moment(d.assignedDate).format('MM/DD/YYYY hh:mm:ss A')),
    maxWidth: 150,
    minWidth: 150,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'ASSIGNED TO',
    accessor: 'assignee',
    maxWidth: 200,
    minWidth: 200,
    Cell: row => <EvalTableRow row={row} />,
  }, {
    Header: 'HISTORY',
    accessor: 'history',
    maxWidth: 80,
    minWidth: 80,
    Cell: row => <EvalTableRow row={row} />,
  },
];

SearchLoan.defaultProps = {
  inProgress: false,
  resultOperation: {},
  openWidgetList: [],
  onGoBackToSearch: () => { },
  checklistCenterPaneView: LOAN_SEARCH_SCREEN,
  popupData: {
    confirmButtonText: 'Okay!',
  },
  evalDropdownData: [],
  createEvalLoader: false,
};

SearchLoan.propTypes = {
  checklistCenterPaneView: PropTypes.string,
  checkTrialStagerButton: PropTypes.func.isRequired,
  clearEvalData: PropTypes.func.isRequired,
  clearPopupData: PropTypes.func.isRequired,
  clearRepaymentData: PropTypes.func.isRequired,
  closeSweetAlert: PropTypes.func.isRequired,
  createEvalLoader: PropTypes.bool,
  createNewEvalCase: PropTypes.func.isRequired,
  createNewMod: PropTypes.func.isRequired,
  evalDropdownData: PropTypes.arrayOf(PropTypes.shape()),
  evalId: PropTypes.string.isRequired,
  fetchBorrowers: PropTypes.func.isRequired,
  getRejectResponse: PropTypes.shape({
    level: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  // isAccountServiceScreenVisible: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool,
  isAssigned: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  onAutoSave: PropTypes.func.isRequired,
  // onGetTombstoneData: PropTypes.func.isRequired,
  onClearStagerTaskName: PropTypes.func.isRequired,
  onEndShift: PropTypes.func.isRequired,
  onGetGroupName: PropTypes.func.isRequired,
  onGoBackToSearch: PropTypes.func,
  onSearchLoan: PropTypes.func.isRequired,
  onSelectEval: PropTypes.func.isRequired,
  onWidgetToggle: PropTypes.func.isRequired,
  openSweetAlert: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  popupData: PropTypes.shape({
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
  }),
  resetDocChecklistData: PropTypes.func.isRequired,
  resultOperation: PropTypes.shape(
    {
      isOpen: PropTypes.bool,
      level: PropTypes.string,
      status: PropTypes.string,
    },
  ),
  searchLoanNbr: PropTypes.string.isRequired,
  searchLoanResult: PropTypes.shape({
    assigned: PropTypes.arrayOf(PropTypes.shape()),
    disasterType: PropTypes.shape(),
    loanNumber: PropTypes.string.isRequired,
    statusCode: PropTypes.string,
    taksId: PropTypes.string.isRequired,
    taskName: PropTypes.string.isRequired,
    unAssigned: PropTypes.arrayOf(PropTypes.shape()),
    userHasAccess: PropTypes.bool,
    valid: PropTypes.bool,
  }).isRequired,
  setBeginSearch: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setEvalId: PropTypes.func.isRequired,
  setEvalScreenIcon: PropTypes.func.isRequired,
  setEvalWidget: PropTypes.func.isRequired,
  setIndexingPageVisibility: PropTypes.func.isRequired,
  setModStatus: PropTypes.func.isRequired,
  setProcessId: PropTypes.func.isRequired,
  setRepaymentCaseId: PropTypes.func.isRequired,
  setSelectedCaseId: PropTypes.func.isRequired,
  setStagerActiveSearchTerm: PropTypes.func.isRequired,
  tombstoneData: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  enableGetNext: selectors.enableGetNext(state),
  evalId: selectors.evalId(state),
  isAssigned: selectors.isAssigned(state),
  searchLoanResult: selectors.searchLoanResult(state),
  getRejectResponse: selectors.getRejectResponse(state),
  user: loginSelectors.getUser(state),
  inProgress: selectors.inProgress(state),
  resultOperation: selectors.resultOperation(state),
  tombstoneData: tombstoneSelectors.getTombstoneData(state),
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
  popupData: selectors.getPopupData(state),
  evalDropdownData: selectors.getEvalDropdownData(state),
  isAccountServiceScreenVisible: accountServiceSelectors.isAccountServiceScreenVisible(state),
  createEvalLoader: evalSelectors.isCreateEvalLoading(state),
  searchLoanNbr: selectors.searchLoanNbr(state),
});

const mapDispatchToProps = dispatch => ({
  onGetRFDData: tombstoneOperations.getRFDTableDataOperation(dispatch),
  closeSweetAlert: operations.closeSweetAlert(dispatch),
  checkTrialStagerButton: operations.checkTrialEnableStagerButtonOperation(dispatch),
  onAutoSave: operations.onAutoSave(dispatch),
  onEndShift: operations.onEndShift(dispatch),
  onSearchLoan: operations.onSearchLoan(dispatch),
  onSelectEval: operations.onSelectEval(dispatch),
  onGetGroupName: operations.onGetGroupName(dispatch),
  onClearStagerTaskName: operations.onClearStagerTaskName(dispatch),
  onGetChecklistHistory: checkListOperations.fetchHistoricalChecklistData(dispatch),
  onWidgetToggle: widgetsOperations.onWidgetToggle(dispatch),
  onGoBackToSearch: milestoneOperations.goBackToSearch(dispatch),
  clearTombstoneData: tombstoneOperations.clearTombstoneDataOperation(dispatch),
  saveEvalIncmDetails: operations.saveEvalIncmDetails(dispatch),
  clearPopupData: operations.clearPopupData(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  setEvalScreenIcon: evalOperations.setEvalScreenIconOpn(dispatch),
  setEvalId: operations.setEvalIdOperation(dispatch),
  fetchEvalDetails: evalOperations.fetchEvalDetailsOperation(dispatch),
  createNewEvalCase: evalOperations.createNewEvalCaseOperation(dispatch),
  setRepaymentCaseId: operations.setRepaymentCaseIdOpn(dispatch),
  createNewMod: evalOperations.createNewModOperation(dispatch),
  setIndexingPageVisibility: indexerOperations.setIndexingPageVisibility(dispatch),
  fetchBorrowers: indexerOperations.fetchBorrowersIndexer(dispatch),
  setModStatus: operations.fetchModStatusOperation(dispatch),
  resetDocChecklistData: docChecklistOperations.resetDocChecklistOperation(dispatch),
  setBeginSearch: operations.setBeginSearch(dispatch),
  setSelectedCaseId: operations.setSelectedCaseIdOpn(dispatch),
  clearRepaymentData: repaymentEvalOperations.clearDataOperation(dispatch),
  setProcessId: operations.setProcessId(dispatch),
  clearEvalData: evalOperations.clearInfoOperation(dispatch),
  setStagerActiveSearchTerm: stagerOperations.setStagerActiveSearchTerm(dispatch),
  openSweetAlert: operations.openSweetAlert(dispatch),
  setEvalWidget: operations.setEvalWidget(dispatch),
  fetchResolutionChoiceType: smduOperations.fetchResolutionType(dispatch),

});

const SearchLoanContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchLoan);

const TestHooks = {
  SearchLoan,
};

export default withRouter(SearchLoanContainer);
export { TestHooks };
