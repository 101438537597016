import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tombstone from 'components/Tombstone/Tombstone';
import MilestoneTracker from 'components/MilestoneTracker/MilestoneTracker';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import Vasp from '../VASP/Vasp';
import RFDContent from '../../../components/Tombstone/TombstoneComponents/RFDContent';
import CollateralContent from '../../../components/Tombstone/TombstoneComponents/CollateralContent';
import RepossessionTableContent from '../../../components/Tombstone/TombstoneComponents/RepossessionTableContent';
import { selectors } from '../../../state/ducks/dashboard';
import './BackendStager.css';

class BackendStager extends React.PureComponent {
  renderCenterPane() {
    const {
      activeTab, checklistCenterPaneView, onOrderClick,
    } = this.props;
    if (checklistCenterPaneView === 'Vasp' || !checklistCenterPaneView) {
      return <Vasp activeTab={activeTab} onOrderClick={onOrderClick} />;
    }
    if (checklistCenterPaneView === 'Reason for Default') {
      return <RFDContent />;
    }
    if (checklistCenterPaneView === 'Occupancy Type') {
      return <CollateralContent />;
    }
    if (checklistCenterPaneView === 'Repossession') {
      return <RepossessionTableContent />;
    }
    return <Vasp activeTab={activeTab} onOrderClick={onOrderClick} />;
  }

  render() {
    const {
      milestoneDetails,
    } = this.props;
    const currentSelection = 2;
    return (
      <div>
        <div styleName="top-content">
          BACKEND STAGER
        </div>
        <div styleName="backend-stager">
          <div styleName="milestone-tracker">
            <MilestoneTracker currentSelection={currentSelection} trackerItems={milestoneDetails} />
          </div>
          <div styleName="content-columns">
            <div styleName="left-column">
              <Tombstone />
            </div>
            <div styleName="right-column">
              <div styleName="vasp-container">
                {this.renderCenterPane()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BackendStager.defaultProps = {
  milestoneDetails: [],
  checklistCenterPaneView: 'Vasp',
};

BackendStager.propTypes = {
  activeTab: PropTypes.string.isRequired,
  checklistCenterPaneView: PropTypes.string,
  milestoneDetails: PropTypes.arrayOf(PropTypes.shape({
    mlstnNm: PropTypes.string,
    taskId: PropTypes.string,
    visited: PropTypes.string,
  })),
  onOrderClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  milestoneDetails: selectors.getMilestoneDetails(state),
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
});

export default connect(mapStateToProps)(BackendStager);
