import {
  updateRepayTrackPaymentDatesAction,
  fetchMonthlyPaymentDetailsAction,
  updateMonthlyPaymentDetailsAction,
  lockCalculationAction,
  enableCalcAction,
  sendForApprovalAction,
  fetchResolutionRejectReasonAction,
  setResolutionRejectReasonAction,
  rejectCaseRulesAction,
  approvalAction,
  calcRepayAction,
  clearDataAction,
  setFromEvalSummaryPageAction,
  toggleLockCase,
  saveLockCase,
  updateWorkoutTypeAction,
  updateDurationAction,
  setIsEvalRepayAction,
} from './actions';


const updateRepayTrackPaymentsDates = dispatch => (payload) => {
  dispatch(updateRepayTrackPaymentDatesAction(payload));
};

const fetchMonthlyPaymentDetails = dispatch => () => {
  dispatch(fetchMonthlyPaymentDetailsAction());
};

const updateMonthlyPaymentDetails = dispatch => (key, value) => {
  dispatch(updateMonthlyPaymentDetailsAction(key, value));
};

const updateWorkoutTypeOperation = dispatch => (payload) => {
  dispatch(updateWorkoutTypeAction(payload));
};

const lockCalculationOperation = dispatch => (payload) => {
  dispatch(lockCalculationAction(payload));
};

const enableCalc = dispatch => (value) => {
  dispatch(enableCalcAction(value));
};

const sendForApprovalOperation = dispatch => (payload) => {
  dispatch(sendForApprovalAction(payload));
};

const sendForApproval = dispatch => (payload) => {
  dispatch(approvalAction(payload));
};

const fetchResolutionRejectReasonData = dispatch => (payload) => {
  dispatch(fetchResolutionRejectReasonAction(payload));
};

const setResolutionRejectReasonData = dispatch => (payload) => {
  dispatch(setResolutionRejectReasonAction(payload));
};

const rejectCaseRulesOperation = dispatch => (payload) => {
  dispatch(rejectCaseRulesAction(payload));
};

const calcRepayOperation = dispatch => (payload) => {
  dispatch(calcRepayAction(payload));
};

const clearDataOperation = dispatch => () => {
  dispatch(clearDataAction());
};

const setFromEvalSummaryPageOperation = dispatch => (payload) => {
  dispatch(setFromEvalSummaryPageAction(payload));
};

const toggleLockCaseOperation = dispatch => (payload) => {
  dispatch(toggleLockCase(payload));
};

const saveLockCaseOperation = dispatch => (payload) => {
  dispatch(saveLockCase(payload));
};

const updateDurationOperation = dispatch => (payload) => {
  dispatch(updateDurationAction(payload));
};

const setIsEvalRepayOperation = dispatch => (payload) => {
  dispatch(setIsEvalRepayAction(payload));
};

const operations = {
  enableCalc,
  lockCalculationOperation,
  updateRepayTrackPaymentsDates,
  updateMonthlyPaymentDetails,
  fetchMonthlyPaymentDetails,
  sendForApprovalOperation,
  sendForApproval,
  rejectCaseRulesOperation,
  fetchResolutionRejectReasonData,
  setResolutionRejectReasonData,
  calcRepayOperation,
  clearDataOperation,
  setFromEvalSummaryPageOperation,
  toggleLockCaseOperation,
  saveLockCaseOperation,
  updateWorkoutTypeOperation,
  updateDurationOperation,
  setIsEvalRepayOperation,
};

export default operations;
