import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import {
  Grid, Box, Button, IconButton,
} from '@material-ui/core';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import './TrackPayment.css';
import EvalTable from 'components/Eval/EvalTable';
import DatePicker from 'components/Eval/DatePicker';

import utils from 'ducks/tasks-and-checklist/utils';
import moment from 'moment-timezone';
import compareArrays from 'lib/CustomFunctions/Eval/compareTrackPaymentData';
import { selectors as loginSelectors } from 'ducks/login';
import Loader from '../../../../components/Loader';
import {
  ENABLE_DELETE_TRACK_PAYMENT_INFO, TRACKPAYMENT_EDITABLE_GROUPS,
  TRACKPAYMENT_EDITABLE_LM_GROUPS, TRACK_PAYMENT_CASE_TYPES_UPDATE, APPROVED,
  EVAL_WIDGET, CLOSED, SENT_FOR_APPROVAL, TRCK_PYMT_OTHER_APPLICABLE_CASES, REJECTED,
} from '../../../../constants/eval';
import { operations, selectors } from '../../../../state/ducks/eval';
import Popup from '../../../../components/Popup';


const TrackPayment = (props) => {
  const {
    trackPayments, fetchTrackPayments, updateTrackPaymentsDates, deleteTrackPayment,
    caseHeaderInfo, user, updateTrackPaymentsResponse,
    inProgress, evalScreenIcon, paymentStatus, getPaymentStatus,
    fetchCalcLogs, isRepayCase, isPaymentRequired,
  } = props;
  const [tableData, setTableData] = useState(trackPayments.trackPaymentDetailsList);
  const [visible, setVisible] = useState(false);
  const [initialData, setInitialData] = useState(trackPayments.trackPaymentDetailsList);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(
    { isSuccessModalVisible: false, updatedTrackPayment: false },
  );
  const [isTrackPayEditable, setIsTrackPayEditable] = useState(false);
  const { getCSTDateTime } = utils;
  const {
    resolutionId, caseType, reslWorkoutType, status, parentId,
  } = caseHeaderInfo;
  const userEmail = user && user.userDetails.email;

  const groups = user && user.groupList ? user.groupList : [];
  const isEditable = reslWorkoutType === 'F'
    ? isTrackPayEditable && R.any(item => R.contains(item, groups), TRACKPAYMENT_EDITABLE_LM_GROUPS)
    : isTrackPayEditable && R.any(item => R.contains(item, groups), TRACKPAYMENT_EDITABLE_GROUPS);

  const validStatusAndTypeforDelete = ENABLE_DELETE_TRACK_PAYMENT_INFO.some(
    value => value.status === status && value.caseType === caseType,
  );

  const enableDelete = status === CLOSED || validStatusAndTypeforDelete;

  useEffect(() => {
    fetchTrackPayments({ resolutionId: parentId || resolutionId, isRepaymentEval: 'No' });
  }, [resolutionId, parentId]);

  useEffect(() => {
    fetchCalcLogs(isRepayCase);
    getPaymentStatus();
  }, []);

  useEffect(() => {
    setInitialData(trackPayments.trackPaymentDetailsList);
    setTableData(trackPayments.trackPaymentDetailsList);
  }, [trackPayments]);

  useEffect(() => {
    if (!R.isNil(updateTrackPaymentsResponse) && showSuccessModal.updatedTrackPayment) {
      setShowSuccessModal(prev => ({ ...prev, isSuccessModalVisible: true }));
    }
  }, [updateTrackPaymentsResponse]);

  useEffect(() => {
    if (evalScreenIcon === EVAL_WIDGET) {
      if (caseHeaderInfo) {
        if (caseHeaderInfo.reslWorkoutType === 'T' && (caseHeaderInfo.status === CLOSED || caseHeaderInfo.status === REJECTED)) {
          setIsTrackPayEditable(true);
        } else if (caseHeaderInfo.reslWorkoutType === 'F' && (caseHeaderInfo.status === CLOSED || caseHeaderInfo.status === APPROVED
          || caseHeaderInfo.status === REJECTED
        )) {
          setIsTrackPayEditable(true);
        } else if (caseHeaderInfo.reslWorkoutType === 'M'
          && TRCK_PYMT_OTHER_APPLICABLE_CASES.includes(caseHeaderInfo.caseType)
          && (caseHeaderInfo.status === SENT_FOR_APPROVAL
            || caseHeaderInfo.status === REJECTED
            || caseHeaderInfo.status === APPROVED
            || !R.isNil(paymentStatus))
          && isPaymentRequired
        ) {
          setIsTrackPayEditable(true);
        } else if (caseHeaderInfo.parentId) {
          setIsTrackPayEditable(false);
        } else setIsTrackPayEditable(false);
      } else {
        setIsTrackPayEditable(false);
      }
    }
  }, [caseHeaderInfo]);

  const handleDelete = (id) => {
    deleteTrackPayment({ id, resolutionId, isRepaymentEval: 'No' });
  };

  const handleDateChange = (columnName, value, id) => {
    setTableData((prevState) => {
      const newState = prevState.map((obj, idx) => {
        if (obj.detailId === id && (value === null || (value && value.format() !== 'Invalid date'))) {
          return { ...obj, [columnName]: value ? value.format() : null, [`is${columnName}Updated`]: !moment(initialData[idx][columnName]).isSame(moment(value), 'days') };
        }
        return obj;
      });
      return newState;
    });
  };

  const handleUpdate = (isConfirm) => {
    const validateStatus = TRACK_PAYMENT_CASE_TYPES_UPDATE.includes(status);
    setShowPopup(validateStatus && !isConfirm);
    if (isConfirm || !validateStatus) {
      const paymentList = tableData.map(item => ({
        datePaymentReceived: item.paymentDate,
        forbDtlId: item.detailId,
        hssnReportedDate: item.hssnreportedDate,
        isPaymentDateUpdated: R.propOr(false, 'ispaymentDateUpdated', item),
        isProcessDateUpdated: R.propOr(false, 'ishssnreportedDateUpdated', item),
        seq: item.terms,
      }));
      const payload = {
        paymentHSSNDatesUpdateReqDataList: paymentList,
        userEmail,
        resolutionId,
        isRepaymentEval: 'No',
      };
      updateTrackPaymentsDates(payload);
      setShowSuccessModal(prev => ({ ...prev, updatedTrackPayment: true }));
    }
  };

  useEffect(() => {
    setVisible(compareArrays(initialData, tableData));
  }, [tableData]);

  const TRACKPAYMENT_COLUMNS = [
    {
      name: 'terms',
      label: 'Terms',
      align: 'left',
    },
    {
      name: 'startedDueDate',
      label: 'Stated Due Date',
      align: 'left',
      cellFormat: value => (value ? getCSTDateTime(moment(value), 'DD MMM YYYY', true) : null),
    },
    {
      name: 'actualDueDate',
      label: 'Actual Due Date',
      align: 'left',
      cellFormat: value => (value ? getCSTDateTime(moment(value), 'DD MMM YYYY', true) : null),
    },
    {
      name: 'paymentDate',
      label: 'Date Payment Received',
      align: 'left',
      editable: true,
      dateField: true,
      cellFormat: row => (
        <DatePicker
          endDate={new Date()}
          format="DD MMM YYYY"
          handleChange={(columnName, val) => {
            handleDateChange(columnName, val, row.detailId);
          }}
          id="paymentDate"
          isViewOnly={!isEditable}
          label="paymentDate"
          override
          value={!R.isNil(row.paymentDate) ? moment.utc(row.paymentDate).format('DD MMM YYYY') : null}
        />
      ),
    },
    {
      name: 'amount',
      label: 'Amount',
      align: 'left',
      cellFormat: value => `$${value}`,
    },
    {
      name: 'type',
      label: 'Type',
      align: 'left',
    },
    {
      name: 'hssnreportedDate',
      label: 'HSSN Reported Date',
      align: 'left',
      editable: true,
      dateField: true,
      cellFormat: row => (
        <DatePicker
          format="DD MMM YYYY"
          handleChange={(columnName, val) => {
            handleDateChange(columnName, val, row.detailId);
          }}
          id="hssnreportedDate"
          isViewOnly={!isEditable}
          label="hssnreportedDate"
          override
          value={!R.isNil(row.hssnreportedDate) ? getCSTDateTime(moment(row.hssnreportedDate), 'DD MMM YYYY') : null}
        />
      ),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
    },
    enableDelete && {
      name: 'del',
      label: '',
      align: 'left',
      deleteIcon: true,
      name1: 'terms',
      cellFormat: terms => (
        <IconButton
          color="secondary"
          disabled={!isEditable}
        >
          <DeleteOutlined onClick={() => handleDelete(terms)} styleName="deleteIcon" />
        </IconButton>

      ),
    },
  ];
  return (
    <>
      {inProgress ? (
        <div>
          <Loader message="Please wait, while Fetching Track Payment data" size={60} />
        </div>
      ) : (
        <Box style={{ maxHeight: '60vh', overflow: 'auto' }} styleName="trackPaymentTab">
          <Box styleName="trackPayment cardBlock">
            <Grid container>
              <Grid item lg={5} md={5} sm={12} styleName="datesBlock" xl={5} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
                    <Box styleName="fields">
                      <div styleName="fieldName"> Completion Date</div>
                      <div styleName="fieldValue">
                        {trackPayments.completionDate && getCSTDateTime(moment(trackPayments.completionDate), 'MM/DD/YYYY')}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
                    <Box styleName="fields">
                      <div styleName="fieldName"> Extended Date</div>
                      <div styleName="fieldValue">
                        {trackPayments.extendedDate && getCSTDateTime(moment(trackPayments.extendedDate), 'MM/DD/YYYY')}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xl={4} xs={12}>
                    <Box styleName="fields">
                      <div styleName="fieldName"> Doc Extension Date</div>
                      <div styleName="fieldValue">
                        {trackPayments.docExtensionDate && getCSTDateTime(moment(trackPayments.docExtensionDate), 'MM/DD/YYYY')}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid styleName="trackPayment tableBlock">
            <EvalTable
              columns={TRACKPAYMENT_COLUMNS}
              data={tableData || []}
            />
          </Grid>
          <Button
            color="primary"
            disabled={!isEditable || !visible}
            onClick={() => handleUpdate(false)}
            styleName="updateButton"
            variant="contained"
          >
            UPDATE PAYMENT DATES
          </Button>

          <Popup
            cancelButtonText="NO"
            confirmButtonText="YES"
            message="This will re-open the Case. Are you sure you want to proceed?"
            onCancel={() => setShowPopup(false)}
            onConfirm={() => handleUpdate(true)}
            show={showPopup}
            showCancelButton
            showConfirmButton
          />
          <Popup
            confirmButtonText="OK"
            level="Success"
            onConfirm={
              () => setShowSuccessModal(prev => ({ ...prev, isSuccessModalVisible: false }))}
            show={showSuccessModal.isSuccessModalVisible}
            showConfirmButton
            title={updateTrackPaymentsResponse && updateTrackPaymentsResponse.status}
          />
        </Box>
      )}
    </>
  );
};

TrackPayment.defaultProps = {
  trackPayments: [],
  updateTrackPaymentsDates: () => { },
  deleteTrackPayment: () => { },
  getPaymentStatus: () => { },
  updateTrackPaymentsResponse: {},
  inProgress: false,
  evalScreenIcon: '',
  caseHeaderInfo: {},
  fetchCalcLogs: () => { },
  paymentStatus: '',
  isRepayCase: false,
};

TrackPayment.propTypes = {
  caseHeaderInfo: PropTypes.shape(),
  deleteTrackPayment: PropTypes.func,
  evalScreenIcon: PropTypes.string,
  fetchCalcLogs: PropTypes.func,
  fetchTrackPayments: PropTypes.func.isRequired,
  getPaymentStatus: PropTypes.func,
  inProgress: PropTypes.bool,
  isPaymentRequired: PropTypes.bool.isRequired,
  // isTrackPayEditable: PropTypes.bool.isRequired,
  // resolutionStatus: PropTypes.string.isRequired,
  isRepayCase: PropTypes.bool,
  paymentStatus: PropTypes.string,
  trackPayments: PropTypes.arrayOf(PropTypes.shape()),
  updateTrackPaymentsDates: PropTypes.func,
  updateTrackPaymentsResponse: PropTypes.shape(),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape(),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  trackPayments: selectors.trackPayments(state),
  user: loginSelectors.getUser(state),
  caseHeaderInfo: selectors.caseHeaderInfoData(state),
  updateTrackPaymentsResponse: selectors.updateTrackPaymentsResponse(state),
  inProgress: selectors.inProgress(state),
  evalScreenIcon: selectors.getEvalScreenIcon(state),
  paymentStatus: selectors.getPaymentStatus(state),
  isPaymentRequired: selectors.isPaymentRequired(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTrackPayments: operations.fetchTrackPaymentData(dispatch),
  updateTrackPaymentsDates: operations.updateTrackPaymentsDates(dispatch),
  deleteTrackPayment: operations.deleteTrackPaymentOperation(dispatch),
  getPaymentStatus: operations.getPaymentStatusOperations(dispatch),
  fetchCalcLogs: operations.fetchCalcLogsData(dispatch),
});

const TrackPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(TrackPayment);

export default TrackPaymentContainer;
