import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import './History.css';
import { HISTORY_TABLE_COLUMNS } from 'constants/BoardingTemplate';
import CircularProgress from '@material-ui/core/CircularProgress';
import { operations, selectors } from 'ducks/boardingTemplate';
import SweetAlertBox from 'components/SweetAlertBox';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import PropTypes from 'prop-types';
import BoardingTemplateHistoryTable from './BoardingTemplateHistoryTable';

const OnBoardTable = ({
  fetchBoardingTemplateHistory, boardingTemplateHistory, inProgress, sortCriteria,
  resultOperation, resetResultOperationOperation, downloadFile, filters,
}) => {
  const handleDownload = (id) => {
    if (boardingTemplateHistory && boardingTemplateHistory.records) {
      const matchedData = R.filter(items => items.id === id, boardingTemplateHistory.records);
      if (matchedData) {
        const { fileName, outputFileJson } = matchedData[0];
        const outputJson = JSON.parse(outputFileJson);
        const excelData = outputJson.map((loan) => {
          const reason = loan.reason.join(',');
          const finalTemplateData = JSON.parse(loan.templateData);
          return {
            ...finalTemplateData,
            Status: loan.status,
            Reason: reason,
          };
        });

        const payload = {
          fileName,
          data: excelData,
        };
        downloadFile(payload);
      }
    }
  };

  return (
    <div styleName="histotyContainer">
      { inProgress
        ? (
          <CircularProgress styleName="loader" variant="indeterminate" />
        )
        : (
          <BoardingTemplateHistoryTable
            columns={HISTORY_TABLE_COLUMNS}
            data={boardingTemplateHistory}
            filters={filters}
            handleChange={(page, rowPage, filter, order) => fetchBoardingTemplateHistory(page,
              rowPage, filter, order)}
            handleDownload={handleDownload}
            size="small"
            sortCriteria={sortCriteria}
          />
        )
      }
      {resultOperation && resultOperation.status && (
      <SweetAlertBox
        message={resultOperation.status}
        onConfirm={() => {
          resetResultOperationOperation();
        }}
        show={resultOperation.isOpen}
        type={resultOperation.level}
      />
      )}
    </div>
  );
};

OnBoardTable.propTypes = {
  boardingTemplateHistory: PropTypes.shape().isRequired,
  downloadFile: PropTypes.func.isRequired,
  fetchBoardingTemplateHistory: PropTypes.func.isRequired,
  filters: PropTypes.shape({}).isRequired,
  inProgress: PropTypes.bool.isRequired,
  resetResultOperationOperation: PropTypes.func.isRequired,
  resultOperation: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  sortCriteria: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  boardingTemplateHistory: selectors.getBoardingTemplateHistory(state),
  inProgress: selectors.historyInProgress(state),
  resultOperation: dashboardSelectors.resultOperation(state),
  sortCriteria: selectors.getSortCriteria(state),
});

const mapDispatchToProps = dispatch => ({
  fetchBoardingTemplateHistory: operations.fetchBoardingTemplateHistory(dispatch),
  resetResultOperationOperation: dashboardOperations.resetResultOperationOperation(dispatch),
  downloadFile: operations.downloadFile(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardTable);
