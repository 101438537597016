import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { TextField, InputAdornment } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import CurrencyBoxStyles from './CurrencyBoxStyles';
import EvalDialogBox from '../EvalDialogBox/EvalDialogBox';

const NumberFormatCustom = (props) => {
  const { inputRef, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      isNumericString
      thousandSeparator
    />
  );
};

const CurrencyBox = ({
  id,
  label,
  override,
  selectedValue,
  details,
  handleChange,
  highlightCells,
  highlightCellsWithBorder,
  title,
  columnType,
}) => {
  const [currencyValue, setCurrencyValue] = useState(selectedValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrencyValue(selectedValue);
  }, [selectedValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = CurrencyBoxStyles({
    override, highlightCells, highlightCellsWithBorder, title,
  });

  const removeCharaters = value => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

  const handleCurrency = (e) => {
    const getUpdatedCurrencyValue = removeCharaters(e.target.value);
    setCurrencyValue(getUpdatedCurrencyValue);
    handleChange(label, getUpdatedCurrencyValue, columnType);
  };

  const handleBlur = () => {
    if (currencyValue) {
      const roundedValue = parseFloat(currencyValue).toFixed(2);
      setCurrencyValue(roundedValue);
      handleChange(label, roundedValue, columnType);
    }
  };

  return (
    <FormControl className={classes.root}>
      {!R.isEmpty(details) && (
        <InputLabel className={classes.labelName} htmlFor={label}>
          {label}
          <div>
            <Button className={classes.DetailsButton} disableElevation disableRipple onClick={handleClickOpen} variant="text">Details</Button>
            <EvalDialogBox content={{ total: '$957.50' }} isOpen={open} onClose={handleClose} showCloseIcon title="Corporate Advances (Uncapitalized)" />
          </div>
        </InputLabel>
      )}
      <TextField
        className={classes.TextFieldDiv}
        disabled={override}
        id={id}
        InputProps={{
          disableUnderline: true,
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment className={classes.Currency} position="start">$</InputAdornment>,
        }}
        onBlur={handleBlur}
        onChange={handleCurrency}
        value={currencyValue}
      />
    </FormControl>
  );
};

CurrencyBox.propTypes = {
  columnType: PropTypes.string,
  details: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      code: PropTypes.string,
      description: PropTypes.string,
    })),
    title: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  highlightCells: PropTypes.bool,
  highlightCellsWithBorder: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  override: PropTypes.bool,
  selectedValue: PropTypes.string,
  title: PropTypes.string,
};

CurrencyBox.defaultProps = {
  handleChange: () => { },
  highlightCells: false,
  highlightCellsWithBorder: false,
  label: '',
  override: true,
  selectedValue: '0',
  details: {},
  title: '',
  columnType: '',
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default CurrencyBox;
