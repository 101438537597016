/* eslint-disable react/no-unused-prop-types */
/* eslint-disable import/no-named-default */
import React from 'react';
import ContentHeader from 'components/ContentHeader';
import ProductSupport from 'components/ProductSupport/ProductSupport';
import { operations, selectors } from 'ducks/admin-tools';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FilterSection from './FilterSection';
import UnRejectRequest from './UnRejectRequest';
import ForceCreateCase from './ForceCreateCase';
import {
  BULK_STATUS, FORCE_CREATE_CASE, UNREJECT_REQUEST, EVAL_DISASTER_ID_REQUEST,
  SUB_STATUS_REQUEST, BULK_ACTION, FORCE_CREATE_EVAL_CASE, DEFAULT_ROWS_PER_PAGE,
  enumIdTypeMap,
  setDefaultIdType,
} from '../../constants/SupportAdmin';
import BulkStatus from './BulkStatus';
import EvalDisasterIdRequest from './EvalDisasterIdRequest';
import History from './History';
import SubStatusRequest from './SubStatusRequest';
import BulkAction from './BulkAction';
import ForceCreateEvalCase from './ForceCreateEvalCase';
import './SupportAdmin.css';

class SupportAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: BULK_STATUS,
      title: 'SUPPORT ADMIN',
      fromDate: '',
      toDate: '',
      selectedAction: 'All',
      selectedUser: 'All',
      error: false,
      radioSelect: 'case',
      isBulkActionIdTypeSelected: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.renderSelectedTab = this.renderSelectedTab.bind(this);
  }


  componentDidMount() {
    const {
      setSupportAdminActiveTab, fetchHistoryData, fetchReasonCodes, setActionType,
    } = this.props;
    const { radioSelect } = this.state;
    setSupportAdminActiveTab(BULK_STATUS);
    setActionType(null);
    fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, radioSelect);
    fetchReasonCodes();
  }

  onChangeHandler(values) {
    const {
      fromDate, toDate,
    } = values;

    const { radioSelect } = this.state;

    const { getFilteredData } = this.props;

    // eslint-disable-next-line no-unused-vars
    getFilteredData(Object.assign({}, Object.fromEntries(Object.entries(values).filter(([_key, v]) => v !== '')), { busKeyType: enumIdTypeMap[radioSelect] }), 0, DEFAULT_ROWS_PER_PAGE);

    this.setState({
      fromDate,
      toDate,
    });
  }

  handleChange(selectedTab) {
    const { activeTab } = this.state;
    const { setSupportAdminActiveTab, fetchHistoryData, setActionType } = this.props;
    if (selectedTab !== activeTab) {
      this.setState({ isBulkActionIdTypeSelected: false });
    }
    this.setState({
      activeTab: selectedTab,
      radioSelect: setDefaultIdType[selectedTab],
    });
    setSupportAdminActiveTab(selectedTab);
    setActionType(null);
    fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, setDefaultIdType[selectedTab]);
  }

  renderSelectedTab() {
    const { activeTab } = this.state;
    const {
      unRejectRequest, recordsInfo, fetchHistoryData, submitEvalDisasterIdRequest,
      fetchCaseTypes, caseTypes, submitForceCreateCaseOperation,
      submitForceCreateEvalCaseOperation,
    } = this.props;
    switch (activeTab) {
      case BULK_STATUS:
        return (
          <BulkStatus
            changeRadioSelect={(value) => {
              this.setState({ radioSelect: value });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }}
            recordsInfo={recordsInfo}
          />
        );
      case BULK_ACTION:
        return (
          <BulkAction
            recordsInfo={recordsInfo}
            setIdType={(value) => {
              this.setState({
                radioSelect: value,
                isBulkActionIdTypeSelected: true,
              });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }
            }
          />
        );
      case FORCE_CREATE_CASE:
        return (
          <ForceCreateCase
            caseTypes={caseTypes}
            getCaseTypes={fetchCaseTypes}
            onClear={() => fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, setDefaultIdType[activeTab])}
            recordsInfo={recordsInfo}
            setIdType={(value) => {
              this.setState({
                radioSelect: value,
                isBulkActionIdTypeSelected: true,
              });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }
            }
            submitForceCreateCaseOperation={submitForceCreateCaseOperation}
          />
        );
      case FORCE_CREATE_EVAL_CASE:
        return (
          <ForceCreateEvalCase
            recordsInfo={recordsInfo}
            setIdType={(value) => {
              this.setState({
                radioSelect: value,
              });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }
            }
            submitForceCreateEvalCaseOperation={submitForceCreateEvalCaseOperation}
          />
        );
      case UNREJECT_REQUEST:
        return (
          <UnRejectRequest
            changeRadioSelect={(value) => {
              this.setState({ radioSelect: value });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }}
            recordsInfo={recordsInfo}
            unRejectRequest={value => unRejectRequest(value)}
          />
        );
      case EVAL_DISASTER_ID_REQUEST:
        return (
          <EvalDisasterIdRequest
            recordsInfo={recordsInfo}
            setIdType={(value) => {
              this.setState({
                radioSelect: value,
              });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }
            }
            updateRequest={value => submitEvalDisasterIdRequest(value)}
          />
        );
      case SUB_STATUS_REQUEST:
        return (
          <SubStatusRequest
            changeRadioSelect={(value) => {
              this.setState({ radioSelect: value });
              fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE, value);
            }}
          />
        );
      default:
        return <div>Component Unavailable</div>;
    }
  }

  render() {
    const {
      activeTab, title, fromDate, toDate, selectedAction, selectedUser, error,
      radioSelect, isBulkActionIdTypeSelected,
    } = this.state;
    const {
      gridData, fetchHistoryData, getFilteredData, isFilterEnabled, fetchMatchedData,
      serviceTickets, requestors,
    } = this.props;
    return (
      <>
        <ContentHeader title={title} />
        <div styleName="supportAdminSection">
          <div styleName="prodSection">
            <div styleName="prodHeader">
              <h4>L2 Prod Support</h4>
            </div>
            <ProductSupport
              activeTab={activeTab}
              handleChange={this.handleChange}
            />
          </div>
          <div
            styleName="historySection"
          >

            <div styleName="formContainer">
              {this.renderSelectedTab()}
            </div>
            <div styleName="tableContainer">
              <Grid container direction="row">
                <Grid item xs={8}>
                  <h3>History</h3>
                </Grid>
                <Grid item xs={4}>
                  {isFilterEnabled && (
                    <h3 style={{ display: 'flex', flexDirection: 'row-reverse', marginRight: '1rem' }}>
                      {gridData && gridData.totalRecords ? gridData.totalRecords : 0}
                      {' '}
                      Records
                      <img alt="filter icon" src="/static/img/filter.png" style={{ height: '1rem', marginRight: '1rem' }} />
                    </h3>
                  )
                  }
                </Grid>
              </Grid>

              {gridData.records && (
                <History
                  activeTab={activeTab}
                  filterResults={getFilteredData}
                  historyData={gridData}
                  isBulkActionIdTypeSelected={isBulkActionIdTypeSelected}
                  radioSelect={radioSelect}
                />
              )}
            </div>
            {error && (
              <h4>
                {' '}
                Ignore This, Will remove once someone uses this states  :
                {fromDate}
                ,
                {toDate}
                ,
                {selectedAction}
                ,
                {selectedUser}
              </h4>
            )}
          </div>
          <div
            styleName="filterSection"
          >
            <FilterSection
              fetchMatchedData={fetchMatchedData}
              idType={radioSelect}
              onChangeHandler={this.onChangeHandler}
              onReset={() => {
                if (isFilterEnabled) {
                  fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE,
                    radioSelect);
                }
              }}
              requestors={requestors}
              serviceTickets={serviceTickets}
            />
          </div>
        </div>
      </>
    );
  }
}

SupportAdmin.defaultProps = {
  isFilterEnabled: false,
};

SupportAdmin.propTypes = {
  caseTypes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  fetchCaseTypes: PropTypes.func.isRequired,
  fetchHistoryData: PropTypes.func.isRequired,
  fetchMatchedData: PropTypes.func.isRequired,
  fetchReasonCodes: PropTypes.func.isRequired,
  getFilteredData: PropTypes.func.isRequired,
  getSAselectedTab: PropTypes.string.isRequired,
  gridData: PropTypes.shape().isRequired,
  idType: PropTypes.string.isRequired,
  isFilterEnabled: PropTypes.bool,
  recordsInfo: PropTypes.shape().isRequired,
  requestors: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceTickets: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActionType: PropTypes.func.isRequired,
  setSupportAdminActiveTab: PropTypes.func.isRequired,
  submitEvalDisasterIdRequest: PropTypes.func.isRequired,
  submitForceCreateCaseOperation: PropTypes.func.isRequired,
  submitForceCreateEvalCaseOperation: PropTypes.func.isRequired,
  unRejectRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  serviceTickets: selectors.getServiceTickets(state),
  requestors: selectors.getRequestorIds(state),
  getSAselectedTab: selectors.getSAselectedTab(state),
  gridData: selectors.getSBHistoryGridData(state),
  isFilterEnabled: selectors.isFilterEnabled(state),
  recordsInfo: selectors.getRecordsInfo(state),
  idType: selectors.getIdType(state),
  caseTypes: selectors.getCaseTypes(state),
});

const mapDispatchToProps = dispatch => ({
  setSupportAdminActiveTab: operations.setSupportAdminActiveTab(dispatch),
  fetchMatchedData: operations.fetchMatchedData(dispatch),
  fetchHistoryData: operations.fetchSBHistoryGridData(dispatch),
  getFilteredData: operations.filterResults(dispatch),
  unRejectRequest: operations.unRejectRequest(dispatch),
  submitEvalDisasterIdRequest: operations.submitEvalDisasterIdRequest(dispatch),
  fetchCaseTypes: operations.fetchCaseTypes(dispatch),
  submitForceCreateCaseOperation: operations.submitForceCreateCaseOperation(dispatch),
  submitForceCreateEvalCaseOperation: operations.submitForceCreateEvalCaseOperation(dispatch),
  fetchReasonCodes: operations.fetchBulkActionReasonCodesOperation(dispatch),
  setActionType: operations.setActionType(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportAdmin);
