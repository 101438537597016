// AD GROUPS DEFINED BELOW ARE CASE SENSITIVE
const FEUW = 'FEUW';
const BEUW = 'BEUW';
const DOC_PROCESSOR = 'PROC';
const DOC_GEN = 'DOCGEN';
const DOCS_IN = 'DOCSIN';
const BETA = 'BETA';
const STAGER = 'STAGER';
const TRIAL = 'TRIAL';
const UTIL = 'UTIL';
const POSTMODSTAGER = 'POSTMODSTAGER';
const POSTMOD = 'POSTMOD';
const BOOKING = 'BOOKING';
const DOCGENVENDOR = 'DOCGENVENDOR';
const FHLMCRESOLVE = 'FHLMCRESOLVE';
const UWSTAGER = 'UWSTAGER';
const RPS_STAGER = 'RPSstager';
const INVSET = 'INVSET';
const SECONDLOOK = 'SECONDLOOK';
const LOSS_MITIGATION = 'LOSSMITIGATION';
// const WESTWING = 'LOSSMITIGATION';
const USERSKILLS = 'USERSKILLS';
const RSH_STAGER = 'RSHstager';
const INDEXER = 'INDEXER';
const ESCROW = 'ESCROW';
const SUPPORTADMIN = 'SUPPORTADMIN';
const BUSINESSADMIN = 'BUSINESSADMIN';
const VP = 'VP';
const AVP = 'AVP';

const checklistGroupNames = [
  DOC_PROCESSOR,
  FEUW,
  BEUW,
  DOC_GEN,
  DOCS_IN,
  POSTMOD,
  BOOKING,
  UWSTAGER,
  INVSET,
  SECONDLOOK,
  // WESTWING,
  USERSKILLS,
];

const userGroupList = [
  DOC_PROCESSOR,
  FEUW,
  BEUW,
  DOC_GEN,
  DOCS_IN,
  BETA,
  STAGER,
  TRIAL,
  UTIL,
  POSTMODSTAGER,
  BOOKING,
  DOCGENVENDOR,
  FHLMCRESOLVE,
  RPS_STAGER,
  INVSET,
  SECONDLOOK,
  LOSS_MITIGATION,
  // WESTWING,
  USERSKILLS,
  RSH_STAGER,
  INDEXER,
  ESCROW,
  SUPPORTADMIN,
  BUSINESSADMIN,
  VP,
  AVP,
];

const EDITABLE_DOC_CHECKLIST_WIDGET = [FEUW, BEUW];
const GRPS_CANREJECTCASE = [
  FEUW, BEUW, DOC_PROCESSOR, TRIAL, DOC_GEN, DOCS_IN, BOOKING, SECONDLOOK,
];

module.exports = {
  BEUW,
  DOC_GEN,
  DOCS_IN,
  userGroupList,
  BETA,
  checklistGroupNames,
  LOSS_MITIGATION,
  EDITABLE_DOC_CHECKLIST_WIDGET,
  GRPS_CANREJECTCASE,
};
