import React from 'react';
import AccountServices from 'containers/AccountServices';
import Fico from '../Fico/Fico';
import {
  RFD, COLLATERAL, REASONABLE_EFFECT, HARDHSIP, ACCOUNT_SERVICE, NPV_RESULTS, FICO_SCORE,
  REPO,
} from '../../constants/loanInfoComponents';
import RFDContent from './TombstoneComponents/RFDContent';
import CollateralContent from './TombstoneComponents/CollateralContent';
import ReasonableEffort from './TombstoneComponents/ReasonableEffort';
import HardshipAffidavit from './TombstoneComponents/HardshipAffidavit/HardshipAffidavit';
import NPVResults from './TombstoneComponents/NPVResult';
import RepossessionTableContent from './TombstoneComponents/RepossessionTableContent';

const tombstonePopupMap = {
  [RFD]: <RFDContent />,
  [COLLATERAL]: <CollateralContent />,
  [REASONABLE_EFFECT]: <ReasonableEffort />,
  [HARDHSIP]: <HardshipAffidavit />,
  [ACCOUNT_SERVICE]: <AccountServices isNavigatedFromTombstone />,
  [NPV_RESULTS]: <NPVResults />,
  [FICO_SCORE]: <Fico isNavigatedFromTombstone />,
  [REPO]: <RepossessionTableContent />,
};

function getTombstonePopup(data) {
  return tombstonePopupMap[data];
}

export default getTombstonePopup;
