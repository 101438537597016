import { makeStyles } from '@material-ui/core/styles';

const DropdownStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  selectFieldDiv: {
    // marginLeft: '335px',
    '& .MuiSelect-select': {
      fontFamily: 'Lato',
      fontSize: '13px !important',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0em',
      color: '#4E586E',
      padding: '8px',
      border: hookData => (hookData.override && hookData.showDetails ? 'none' : '1px solid #E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
      backgroundColor: hookData => (hookData.override && hookData.showDetails ? 'default' : 'white'),
    },
    '& .MuiMenuItem-select': {
      fontSize: '13px !important',
    },
    '&:before': {
      borderColor: hookData => (hookData.override && hookData.showDetails ? '' : '#E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
    },
    '&:after': {
      borderColor: hookData => (hookData.override && hookData.showDetails ? '' : '#E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: hookData => (hookData.override && hookData.showDetails ? '' : '#E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: hookData => (hookData.override && hookData.showDetails ? '' : '#E0E4EF'),
      borderRadius: hookData => (hookData.override && hookData.showDetails ? '0px' : '4px'),
    },
    '&:underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  Currency: {
    marginRight: hookData => (hookData.override && hookData.showDetails ? '0px' : '8px'),
    marginLeft: hookData => (hookData.override && hookData.showDetails ? '0px' : '8px'),
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '400',
      color: hookData => (hookData.override && hookData.showDetails ? '#4E586E' : '#939299'),
    },
  },
  DetailsButton: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '17px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#596FEB',
    paddingBottom: '0px',
    marginLeft: '9px',
    paddingTop: '0px',
  },
}));

export default DropdownStyles;
