import React, { useEffect, useState } from 'react';
import {
  Box, Card, Grid, Typography,
} from '@material-ui/core';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { operations, selectors } from '../../../../state/ducks/eval';
import './CaseDetails.css';
import CorporateAdvances from './CorporateAdvances';
import StepRates from './StepRates';
import Information from './Information';
import Hardship from './Hardship';
import RatioSection from './RatioSection/RatioSection';
import FinancialSection from '../../FinancialSection/FinancialSection';
import * as constants from '../../../../constants/eval';

const CaseDetails = ({
  caseInformation, generalInformation, fetchUniversalActionData,
  universalActionResp, evalScreenIcon,
  caseHeaderInfo,
  setModCalcIndicator,
  loadModCalcCall,
}) => {
  const [validateGeneralInformation, setValidateGeneralInformation] = useState(generalInformation);
  const [validateCaseInformation, setValidateCaseInformation] = useState(caseInformation);
  const [delegationIndicator, setDelegationIndicator] = useState('');
  const [isCaseEditable, setIsCaseEditable] = useState(false);

  const findColIndex = caseInformation.findIndex(i => (i.columnName === 'workoutType'));
  const { resolutionChoiceType } = universalActionResp;
  const enableNonDelApprovalDate = (
    constants.NONDELAPPROVALDATE_CASETYPE.includes(resolutionChoiceType)
    || (resolutionChoiceType === 'Traditional Modification' && caseInformation[findColIndex].columnVal === 'VA Traditional Modification')
  ) && caseHeaderInfo.status === constants.OPEN;

  const updateCaseEditStatus = (headerInfo) => {
    if (headerInfo && headerInfo.status === constants.OPEN
      && evalScreenIcon === constants.EVAL_WIDGET) {
      setIsCaseEditable(true);
    } else {
      setIsCaseEditable(false);
    }
  };

  useEffect(() => {
    if (loadModCalcCall) {
      fetchUniversalActionData();
    }
    setModCalcIndicator(false);
    updateCaseEditStatus(caseHeaderInfo);
  }, []);

  useEffect(() => {
    updateCaseEditStatus(caseHeaderInfo);
  }, [caseHeaderInfo]);

  function fieldsToDisable(resolutionType) {
    const workoutTypeData = caseInformation[findColIndex];
    switch (resolutionType) {
      case constants.SPECIAL_SERVICING_TRIAL:
        return !R.isNil(workoutTypeData) && !R.equals(workoutTypeData.columnVal, 'Open Waterfall')
          ? constants.FieldsToDisableForSpecialServicingTrial
          : [];

      case constants.REPAYMENT_CASE:
        return !R.isNil(workoutTypeData) && workoutTypeData.columnVal === 'Special'
          ? constants.FieldsToDisableForRepaymentPlanCase
          : [];

      default:
        return [];
    }
  }

  const handleCaseInfo = (generalInfo, caseInfo) => {
    if (generalInfo && generalInfo.length > 0 && caseInfo && caseInfo.length > 0) {
      let updatedGenInfo = generalInfo;

      let updatedCaseInfo = caseInfo;

      let nonDelPosition = 0;
      const nonDelInUnivValue = generalInfo
        .find(i => (i.columnName === constants.NON_DEL_APPROVAL_DATE));
      const nonDelInCaseValue = caseInfo
        .find(i => (i.columnName === constants.NON_DEL_APPROVAL_DATE));
      if (nonDelInUnivValue) nonDelPosition = 1;
      if (nonDelInCaseValue) nonDelPosition = 2;

      const fetchNonDelegateType = generalInfo
        .find(i => (i.columnName === constants.LOCKED_DELEGATE_TYPE));
      setDelegationIndicator(fetchNonDelegateType && fetchNonDelegateType.columnVal ? fetchNonDelegateType.columnVal : '');

      if (fetchNonDelegateType && nonDelPosition === 1) {
        const isNonDelegated = fetchNonDelegateType.columnVal === constants.NON_DELEGATED;
        updatedGenInfo = updatedGenInfo.map(e => (e.columnName === constants.NON_DEL_APPROVAL_DATE
          ? {
            ...e,
            isOverridable: isNonDelegated,
            columnVal: isNonDelegated ? e.columnVal : '',
            isOverride: isNonDelegated ? e.isOverride : isNonDelegated,
          }
          : e));
      }
      if (fetchNonDelegateType && nonDelPosition === 2) {
        const isNonDelegated = fetchNonDelegateType.columnVal === constants.NON_DELEGATED;
        updatedCaseInfo = updatedCaseInfo.map(e => (e.columnName === constants.NON_DEL_APPROVAL_DATE
          ? {
            ...e,
            isOverridable: isNonDelegated,
            columnVal: isNonDelegated ? e.columnVal : '',
            isOverride: isNonDelegated ? e.isOverride : isNonDelegated,
          }
          : e));
      }
      setValidateGeneralInformation(updatedGenInfo);
      setValidateCaseInformation(updatedCaseInfo);
    } else {
      setValidateGeneralInformation(generalInfo);
      setValidateCaseInformation(caseInfo);
    }
  };

  useEffect(() => {
    handleCaseInfo(generalInformation, caseInformation);
  }, [generalInformation, caseInformation]);


  return (
  // inProgress ? <Loader message="Please wait" size={60} />
  //   : (
    <Box styleName="caseDetails">
      <Grid container spacing={2} style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Grid item lg={4} md={12} sm={12} styleName="formHeight" xl={4} xs={12}>
          <Card elevation={0} styleName="caseForms">
            <Typography styleName="header-font">General Information</Typography>
            <Box styleName="information">
              <Information
                data={validateGeneralInformation}
                delegationIndicator={delegationIndicator}
                enableNonDelApprovalDate={enableNonDelApprovalDate}
                fieldsToDisable={fieldsToDisable(resolutionChoiceType)}
                isCaseEditable={isCaseEditable}
                resolutionChoiceType={resolutionChoiceType}
                type={constants.GEN_INFO}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={4} md={12} sm={12} styleName="formHeight" xl={4} xs={12}>
          <Grid container direction="column" styleName="lastForm">
            <Grid item styleName="caseInfoGrid">
              <Card elevation={0} styleName="caseForms">
                <Typography styleName="header-font">Case Information</Typography>
                <Box styleName="information">
                  <Information
                    data={validateCaseInformation}
                    delegationIndicator={delegationIndicator}
                    enableNonDelApprovalDate={enableNonDelApprovalDate}
                    fieldsToDisable={fieldsToDisable(resolutionChoiceType)}
                    isCaseEditable={isCaseEditable}
                    resolutionChoiceType={resolutionChoiceType}
                    type={constants.CASE_INFO}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item>
              <FinancialSection caseHeaderInfo={caseHeaderInfo} page="caseDetails" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={12} sm={12} styleName="formHeight" xl={4} xs={12}>
          <Grid container direction="column" styleName="lastForm">
            <Grid item styleName="corporateGrid">
              <Card elevation={0} styleName="caseForms">
                <Typography styleName="header-font">Corporate Advances</Typography>
                <CorporateAdvances />
              </Card>
            </Grid>
            <Grid item styleName="stepRatesGrid">
              <Card elevation={0} styleName="caseForms">
                <Typography styleName="header-font">Step Rates</Typography>
                <StepRates />
              </Card>
            </Grid>
            <Grid item styleName="card3Grid">
              <Card elevation={0} styleName="caseForms">
                <Typography styleName="header-font">Ratios</Typography>
                <RatioSection />
              </Card>
            </Grid>
            {constants.HARDSHIP_APPLICABLE_CASES.includes(caseHeaderInfo.caseType)
                && (
                <Grid item>
                  <Card elevation={0} styleName="caseForms">
                    <Hardship isCaseEditable={isCaseEditable} />
                  </Card>
                </Grid>
                ) }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  // )
  );
};

CaseDetails.defaultProps = {
  caseInformation: [],
  fetchUniversalActionData: () => { },
  generalInformation: [],
  universalActionResp: {},
  caseHeaderInfo: {
    caseType: '',
    status: '',
  },
  evalScreenIcon: '',
};

CaseDetails.propTypes = {
  caseHeaderInfo: PropTypes.shape(
    {
      caseType: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    },
  ),
  caseInformation: PropTypes.arrayOf(PropTypes.shape()),
  evalScreenIcon: PropTypes.string,
  fetchUniversalActionData: PropTypes.func,
  generalInformation: PropTypes.arrayOf(PropTypes.shape()),
  // inProgress: PropTypes.bool.isRequired,
  loadModCalcCall: PropTypes.bool.isRequired,
  setModCalcIndicator: PropTypes.func.isRequired,
  universalActionResp: PropTypes.shape(),
};

const mapStateToProps = state => ({
  universalActionResp: selectors.universalActionResp(state),
  caseInformation: selectors.caseInformation(state),
  generalInformation: selectors.generalInformation(state),
  // inProgress: selectors.isCalculateLoading(state),
  caseHeaderInfo: selectors.caseHeaderInfoData(state),
  evalScreenIcon: selectors.getEvalScreenIcon(state),
  loadModCalcCall: selectors.loadModCalcCall(state),
});

const mapDispatchToProps = dispatch => ({
  fetchUniversalActionData: operations.fetchCaseUniversalDataOperation(dispatch),
  setModCalcIndicator: operations.setModCalcIndicatorOperation(dispatch),
});

const CaseDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(CaseDetails);

const TestHooks = {
  CaseDetails,
};


export default CaseDetailsContainer;

export { TestHooks };
