import React from 'react';
import PropTypes from 'prop-types';
import './FilterSection.css';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider, DatePicker,
} from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Button, InputAdornment, IconButton, Select,
} from '@material-ui/core';
import InputSearchFilter from 'components/InputSearchFilter';
import * as R from 'ramda';
import utils from 'lib/Utils';
import { UPLOAD_DATA_LIST } from 'constants/busniessAdmin';

const triggerAutoCompleteMatch = utils.debounce((field, inputData, isUpload, fetchMatchedData) => {
  fetchMatchedData({
    field,
    inputData,
    isUpload,
  });
}, 300);

const dateFormatter = date => (moment(date).format('MM-DD-YYYY'));

class FilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templateTypeOption: UPLOAD_DATA_LIST,
      fromDate: '',
      toDate: '',
      createdBy: '',
      selectedStatus: '',
      selectedTemplateType: '',
      error: false,
      isDisabled: true,
      isDDvisible: '',
      statusOption: ['Success', 'UnSuccessful'],
    };
    this.getDropDownSelectOptions = this.getDropDownSelectOptions.bind(this);
    this.resetHandler = this.resetHandler.bind(this);
    this.applySelectedValues = this.applySelectedValues.bind(this);
    this.enableButtons = this.enableButtons.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props;
    if (prevProps.activeTab !== activeTab) {
      this.resetHandler();
    }
  }

  getDropDownSelectOptions = options => (options
    ? options.map(option => <MenuItem styleName="item" value={option}>{option}</MenuItem>)
    : null);


  handleChange = (e, value, fieldName) => {
    this.setState({
      [fieldName]: value,
    }, () => {
      const { fetchMatchedData, activeTab } = this.props;
      const { templateTypeOption } = this.state;
      if (R.equals(fieldName, 'createdBy')) {
        this.setState({ isDDvisible: fieldName });
        triggerAutoCompleteMatch(fieldName, value, (activeTab === 'All' || templateTypeOption.includes(activeTab)), fetchMatchedData);
      } else {
        this.enableButtons();
      }
    });
  }

  handleBlur = (fieldName) => {
    const { users } = this.props;
    const { [fieldName]: data } = this.state;
    if (R.equals(fieldName, 'createdBy') && (users && !users.includes(data))) { // TODO
      this.setState({ [fieldName]: '', isDDvisible: '' }, () => this.enableButtons());
    }
  }

  getInputValue = (value, name) => {
    this.setState({
      [name]: value,
      isDDvisible: '',
    }, () => this.enableButtons());
  }

  enableButtons = () => {
    const {
      fromDate, toDate, createdBy, selectedTemplateType, templateTypeOption,
      selectedStatus,
    } = this.state;
    const { activeTab } = this.props;
    if (activeTab === 'All' || templateTypeOption.includes(activeTab)) {
      let disable = true;
      let errorRsn = '';
      if (!R.isEmpty(createdBy) || !R.isEmpty(selectedStatus) || (activeTab === 'All' && !R.isEmpty(selectedTemplateType))) {
        disable = false;
      }
      if (!R.isEmpty(fromDate) && !R.isEmpty(toDate)) {
        const from = moment(fromDate);
        const to = moment(toDate);
        if (!to.isSameOrAfter(from)) {
          errorRsn = 'To Date to be equal or higher than From date';
        }
        this.setState({ error: errorRsn });
        disable = errorRsn !== '';
      } else if ((R.isEmpty(fromDate) && !R.isEmpty(toDate))
         || (!R.isEmpty(fromDate) && R.isEmpty(toDate))) {
        disable = true;
        this.setState({ error: '' });
      }
      this.setState({ isDisabled: disable });
    } else {
      let disable = true;
      let errorRsn = '';
      if (!R.isEmpty(createdBy) || !R.isEmpty(selectedStatus)) {
        disable = false;
      }
      if (!R.isEmpty(fromDate) && !R.isEmpty(toDate)) {
        const from = moment(fromDate);
        const to = moment(toDate);
        if (!to.isSameOrAfter(from)) {
          errorRsn = 'To Date to be equal or higher than From date';
        }
        this.setState({ error: errorRsn });
        disable = errorRsn !== '';
      } else if ((R.isEmpty(fromDate) && !R.isEmpty(toDate))
         || (!R.isEmpty(fromDate) && R.isEmpty(toDate))) {
        disable = true;
        this.setState({ error: '' });
      }
      this.setState({ isDisabled: disable });
    }
  };

  applySelectedValues = () => {
    const {
      fromDate, toDate, selectedStatus, createdBy,
      selectedTemplateType, templateTypeOption,
    } = this.state;
    const { submitFilterOptions, activeTab } = this.props;
    const payload = {
      fromDate,
      toDate,
      user: createdBy,
    };
    if (activeTab === 'All' || templateTypeOption.includes(activeTab)) {
      payload.action = activeTab !== 'All' ? activeTab : selectedTemplateType;
    } else if (!R.isEmpty(selectedStatus)) {
      payload.status = R.equals(selectedStatus, 'Success') ? 1 : 0;
    }
    this.setState({ isDDvisible: '' });
    submitFilterOptions(payload);
  }

  resetHandler() {
    const { onReset, activeTab } = this.props;
    const { templateTypeOption } = this.state;
    this.setState({
      fromDate: '',
      toDate: '',
      createdBy: '',
      selectedStatus: '',
      selectedTemplateType: '',
      error: false,
      isDisabled: true,
      isDDvisible: '',
    }, () => {
      onReset(activeTab === 'All' || templateTypeOption.includes(activeTab));
    });
  }


  render() {
    const {
      fromDate, toDate, isDisabled, createdBy,
      error, isDDvisible, selectedStatus, statusOption,
    } = this.state;

    const { users } = this.props;
    return (
      <>
        <Grid
          container
          direction="column"
          spacing={0}
          wrap="nowrap"
        >
          <Grid item styleName="resetSection">
            <div styleName="heading">
              <img alt="filter icon" src="/static/img/filter.png" />
              <h3>
                FILTER
              </h3>
            </div>
          </Grid>
          <Grid item styleName="root">
            <h3 styleName="actionName">Date Range</h3>
            <p styleName="actionSubhead">From</p>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                emptyLabel="mm-dd-yyyy"
                format="MM-DD-YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginLeft: '5rem' }}>
                      <IconButton
                        style={{ padding: '0px' }}
                      >
                        <img alt="date picker" src="/static/img/datePicker.png" style={{ width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputVariant="outlined"
                maxDate={fromDate || new Date()}
                name="fromDate"
                onChange={value => this.handleChange('', value, 'fromDate')}
                styleName="inputTextElem"
                value={fromDate ? dateFormatter(fromDate) : null}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item styleName="root">
            <p styleName="actionSubhead">To</p>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                emptyLabel="mm-dd-yyyy"
                format="MM-DD-YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginLeft: '5rem' }}>
                      <IconButton
                        style={{ padding: '0px' }}
                      >
                        <img alt="date picker" src="/static/img/datePicker.png" style={{ width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputVariant="outlined"
                maxDate={toDate || new Date()}
                name="toDate"
                onChange={value => this.handleChange('', value, 'toDate')}
                styleName="inputTextElem"
                value={toDate ? dateFormatter(toDate) : null}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
            {error && (
              <p styleName="error">
                {error}
              </p>
            )}
          </Grid>

          <Grid item styleName="root">
            <h3 styleName="actionName">Created By</h3>
            <InputSearchFilter
              changeFilterHandler={e => this.handleChange(e, e.target.value, 'createdBy')}
              getInputValue={this.getInputValue}
              isDDvisible={isDDvisible}
              list={users}
              name="createdBy"
              onBlur={() => this.handleBlur('createdBy')}
              value={createdBy}
            />
          </Grid>
          {/* {activeTab === 'All' || templateTypeOption.includes(activeTab) ? (
            <Grid item styleName="root">
              <h3 styleName="actionName">Template Type</h3>
              <TextField
                disabled
                style={{ width: '100%', background: '#e7eaf1' }}
                value={activeTab}
              />
            </Grid>
          ) : (
            <Grid item styleName="root">
              <h3 styleName="actionName">Status</h3>
              <Select
                displayEmpty
                onChange={e => this.handleChange(e, e.target.value, 'selectedStatus')}
                value={selectedStatus}
              >
                <MenuItem styleName="item" value="">Select</MenuItem>
                {this.getDropDownSelectOptions(statusOption)}
              </Select>
            </Grid>
          )} */}
          <Grid item styleName="root">
            <h3 styleName="actionName">Status</h3>
            <Select
              displayEmpty
              onChange={e => this.handleChange(e, e.target.value, 'selectedStatus')}
              value={selectedStatus}
            >
              <MenuItem styleName="item" value="">Select</MenuItem>
              {this.getDropDownSelectOptions(statusOption)}
            </Select>
          </Grid>


          <Grid item styleName="actionButtons">
            <Button disabled={isDisabled} onClick={this.resetHandler}>RESET</Button>
            <Button color="primary" disabled={isDisabled} onClick={this.applySelectedValues} variant="contained">APPLY</Button>
          </Grid>

        </Grid>
      </>

    );
  }
}


FilterSection.defaultProps = {
  activeTab: 'All',
  onReset: () => {},
  submitFilterOptions: () => {},
  fetchMatchedData: () => {},
};


FilterSection.propTypes = {
  activeTab: PropTypes.string,
  fetchMatchedData: PropTypes.func,
  onReset: PropTypes.func,
  submitFilterOptions: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.string).isRequired,
};


export default FilterSection;
