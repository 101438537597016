import React from 'react';
import './LoanTypeDescription.css';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import { DoubleArrowRounded, CategoryOutlined } from '@material-ui/icons/index';

const LoanTypeDescription = ({
  loanTypeDescription, enableLoanDescWidget,
}) => {
  if (!enableLoanDescWidget) return null;
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      />
      <Fab
        disabled
        size="small"
        style={{
          backgroundColor: '#0bbfe3',
        }}
      >
        <CategoryOutlined styleName="icon" />
      </Fab>
      <h3 styleName="loantypedescription">{loanTypeDescription}</h3>
      <DoubleArrowRounded styleName="doubleArrow" />
    </>
  );
};

LoanTypeDescription.defaultProps = {
  enableLoanDescWidget: false,
  loanTypeDescription: '',
};

LoanTypeDescription.propTypes = {
  enableLoanDescWidget: PropTypes.bool,
  loanTypeDescription: PropTypes.string,
};

export default LoanTypeDescription;
