import {
  UPDATE_REPAY_TRACKPAYMENT_DATES,
  FETCH_MONTHLY_PAYMENT_DETAILS, UPDATE_MONTHLY_PAYMENT_DETAILS,
  LOCK_CALC, ENABLE_CALC, SHOW_LOADER, HIDE_LOADER,
  SEND_FORAPPROVAL_RULES,
  FETCH_REJECT_REASONS, SET_REJECT_REASONS,
  REJECT_CASE_RULES, SEND_FORAPPROVAL,
  CALCULATE_REPAYMENT_DATA,
  FETCH_REPAY_DATA_LOADER,
  CLEAR_DATA, FROM_EVAL_SUMMARY_PAGE,
  TOGGLE_LOCK_CASE, SAVE_LOCK_CASE,
  UPDATE_WORKOUT_TYPE,
  UPDATE_DURATION,
  IS_EVAL_REPAY_CASE,
} from './types';


const updateRepayTrackPaymentDatesAction = payload => ({
  type: UPDATE_REPAY_TRACKPAYMENT_DATES,
  payload,
});

const fetchMonthlyPaymentDetailsAction = () => ({
  type: FETCH_MONTHLY_PAYMENT_DETAILS,
});

const updateMonthlyPaymentDetailsAction = (key, value) => ({
  type: UPDATE_MONTHLY_PAYMENT_DETAILS,
  payload: { key, value },
});

const updateWorkoutTypeAction = payload => ({
  type: UPDATE_WORKOUT_TYPE,
  payload,
});

const lockCalculationAction = payload => ({
  type: LOCK_CALC,
  payload,
});

const enableCalcAction = value => ({
  type: ENABLE_CALC,
  payload: value,
});

const showLoader = () => ({
  type: SHOW_LOADER,
});

const hideLoader = () => ({
  type: HIDE_LOADER,
});

const sendForApprovalAction = payload => ({
  type: SEND_FORAPPROVAL_RULES,
  payload,
});

const approvalAction = payload => ({
  type: SEND_FORAPPROVAL,
  payload,
});

const fetchResolutionRejectReasonAction = payload => ({
  type: FETCH_REJECT_REASONS,
  payload,
});

const setResolutionRejectReasonAction = payload => ({
  type: SET_REJECT_REASONS,
  payload,
});

const rejectCaseRulesAction = payload => ({
  type: REJECT_CASE_RULES,
  payload,
});

const calcRepayAction = value => ({
  type: CALCULATE_REPAYMENT_DATA,
  payload: value,
});

const setFetchRepayDataLoaderAction = payload => ({
  type: FETCH_REPAY_DATA_LOADER,
  payload,
});

const clearDataAction = () => ({
  type: CLEAR_DATA,
});

const setFromEvalSummaryPageAction = payload => ({
  type: FROM_EVAL_SUMMARY_PAGE,
  payload,
});

const toggleLockCase = payload => ({
  type: TOGGLE_LOCK_CASE,
  payload,
});

const saveLockCase = payload => ({
  type: SAVE_LOCK_CASE,
  payload,
});

const updateDurationAction = payload => ({
  type: UPDATE_DURATION,
  payload,
});

const setIsEvalRepayAction = payload => ({
  type: IS_EVAL_REPAY_CASE,
  payload,
});

export {
  updateMonthlyPaymentDetailsAction,
  fetchMonthlyPaymentDetailsAction,
  updateRepayTrackPaymentDatesAction,
  lockCalculationAction,
  enableCalcAction,
  showLoader,
  hideLoader,
  sendForApprovalAction,
  approvalAction,
  fetchResolutionRejectReasonAction,
  setResolutionRejectReasonAction,
  rejectCaseRulesAction,
  calcRepayAction,
  setFetchRepayDataLoaderAction,
  clearDataAction,
  setFromEvalSummaryPageAction,
  toggleLockCase,
  saveLockCase,
  updateWorkoutTypeAction,
  updateDurationAction,
  setIsEvalRepayAction,
};
