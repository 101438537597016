const FETCH_REPAYMENT_CASESCREEN_DROPDOWN_DATA = 'app/repaymentEval/FETCH_REPAYMENT_CASESCREEN_DROPDOWN_DATA';
const SAVE_REPAYMENT_CASESCREEN_DROPDOWN_DATA = 'app/repaymentEval/SAVE_REPAYMENT_CASESCREEN_DROPDOWN_DATA';
const SET_SNACK_BAR_VALUES = 'app/repaymentEval/SET_SNACK_BAR_VALUES';

// track payment loader
const SHOW_LOADER = 'app/repaymentEval/SHOW_LOADER';
const HIDE_LOADER = 'app/repaymentEval/HIDE_LOADER';

const UPDATE_REPAY_TRACKPAYMENT_DATES = 'app/repaymentEval/UPDATE_REPAY_TRACKPAYMENT_DATES';

const FETCH_PAST_DUE_PAYMENT_DETAILS = 'app/repaymentEval/FETCH_PAST_DUE_PAYMENT_DETAILS';
const SET_PAST_DUE_PAYMENT_DETAILS = 'app/repaymentEval/SET_PAST_DUE_PAYMENT_DETAILS';
const FETCH_MONTHLY_PAYMENT_DETAILS = 'app/repaymentEval/FETCH_MONTHLY_PAYMENT_DETAILS';
const SET_MONTHLY_PAYMENT_DETAILS = 'app/repaymentEval/SET_MONTHLY_PAYMENT_DETAILS';
const UPDATE_MONTHLY_PAYMENT_DETAILS = 'app/repaymentEval/UPDATE_MONTHLY_PAYMENT_DETAILS';
const SET_REPAYMENT_EVAL_ERROR_MSG = 'app/repaymentEval/SET_REPAYMENT_EVAL_ERROR_MSG';
const LOCK_CALC = 'app/repaymentEval/LOCK_CALC';
const ENABLE_CALC = 'app/repaymentEval/ENABLE_CALC';

const SET_PAYMENT_CHANGES_DETAILS = 'app/repaymentEval/SET_PAYMENT_CHANGES_DETAILS';
const DELETE_TRACKPAYMENT = 'app/repaymentEval/DELETE_TRACKPAYMENT';
const SET_LOCK_FLAG = 'app/repaymentEval/SET_LOCK_FLAG';
const SEND_FORAPPROVAL_RULES = 'app/repaymentEval/SEND_FORAPPROVAL_RULES';
const SEND_FORAPPROVAL = 'app/repaymentEval/SEND_FORAPPROVAL';
const REJECT_CASE_RULES = 'app/repaymentEval/REJECT_CASE_RULES';
const SET_REJECT_REASONS = 'app/repaymentEval/SET_REJECT_REASONS';
const FETCH_REJECT_REASONS = 'app/repaymentEval/FETCH_REJECT_REASONS';
const SET_IS_CASE_REJECTED = 'app/repaymentEval/SET_IS_CASE_REJECTED';
const CALCULATE_REPAYMENT_DATA = 'app/repaymentEval/CALCULATE_REPAYMENT_DATA';
const FETCH_REPAY_DATA_LOADER = 'app/repaymentEval/FETCH_REPAY_DATA_LOADER';
const CLEAR_DATA = 'app/repaymentEval/CLEAR_DATA';
const FROM_EVAL_SUMMARY_PAGE = 'app/repaymentEval/FROM_EVAL_SUMMARY_PAGE';

// calculate and lock loader
const CALCULATE_LOADER = 'app/repaymentEval/CALCULATE_LOADER';

const UPDATE_CALC_RESPONSE = 'app/repaymentEval/UPDATE_CALC_RESPONSE';
const UPDATE_WORKOUT_TYPE = 'app/repaymentEval/UPDATE_WORKOUT_TYPE';
const TOGGLE_LOCK_CASE = 'app/repaymentEval/TOGGLE_LOCK_CASE';
const SAVE_LOCK_CASE = 'app/repaymentEval/SAVE_LOCK_CASE';
const UPDATE_DURATION = 'app/repaymentEval/UPDATE_DURATION';

const IS_EVAL_REPAY_CASE = 'app/repaymentEval/IS_EVAL_REPAY_CASE';

export {
  LOCK_CALC,
  ENABLE_CALC,
  SET_REPAYMENT_EVAL_ERROR_MSG,
  UPDATE_MONTHLY_PAYMENT_DETAILS,
  SET_MONTHLY_PAYMENT_DETAILS,
  FETCH_MONTHLY_PAYMENT_DETAILS,
  SET_PAST_DUE_PAYMENT_DETAILS,
  FETCH_PAST_DUE_PAYMENT_DETAILS,
  FETCH_REPAYMENT_CASESCREEN_DROPDOWN_DATA,
  SAVE_REPAYMENT_CASESCREEN_DROPDOWN_DATA,
  SET_SNACK_BAR_VALUES,
  SHOW_LOADER,
  HIDE_LOADER,
  UPDATE_REPAY_TRACKPAYMENT_DATES,
  SET_PAYMENT_CHANGES_DETAILS,
  DELETE_TRACKPAYMENT,
  SET_LOCK_FLAG,
  SEND_FORAPPROVAL_RULES,
  SEND_FORAPPROVAL,
  REJECT_CASE_RULES,
  SET_REJECT_REASONS,
  FETCH_REJECT_REASONS,
  SET_IS_CASE_REJECTED,
  CALCULATE_REPAYMENT_DATA,
  FETCH_REPAY_DATA_LOADER,
  CLEAR_DATA,
  FROM_EVAL_SUMMARY_PAGE,
  CALCULATE_LOADER,
  UPDATE_CALC_RESPONSE,
  UPDATE_WORKOUT_TYPE,
  UPDATE_DURATION,
  TOGGLE_LOCK_CASE,
  SAVE_LOCK_CASE,
  IS_EVAL_REPAY_CASE,
};
