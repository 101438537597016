import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { connect } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import { ERROR, SUCCESS } from 'constants/common';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import Override from './Override/Override';
import styles from './AlertBox.css';

class AlertBox extends React.PureComponent {
  static getIcon(level) {
    switch (level) {
      case ERROR:
        return <WarningIcon styleName="error-icon" />;
      case SUCCESS:
        return <CheckCircleIcon styleName="success-icon" />;
      default:
        return null;
    }
  }

  static getBoxStyle(level) {
    switch (level) {
      case ERROR:
        return 'alert-box--error';
      case SUCCESS:
        return 'alert-box--success';
      default:
        return 'alert-box';
    }
  }

  render() {
    const {
      className, level, message, isOverride,
    } = this.props;
    const icon = this.constructor.getIcon(level);
    return (
      <div className={classnames(className, styles[this.constructor.getBoxStyle(level)])}>
        {icon}
        <span styleName="message">{message}</span>
        {(isOverride && level === ERROR) && (
          <>
            <Override />
          </>
        )}
      </div>
    );
  }
}

AlertBox.defaultProps = {
  className: '',
  isOverride: false,
};

AlertBox.propTypes = {
  className: PropTypes.string,
  isOverride: PropTypes.bool,
  level: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  isOverride: dashboardSelectors.getisOverride(state),
});

export default connect(mapStateToProps, null)(AlertBox);
