import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Grid, Typography, IconButton, Modal, TextField,
}
  from '@material-ui/core';
import { selectors as dashboardSelectors, operations } from 'ducks/dashboard';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { selectors as loginSelectors } from 'ducks/login';
import { BEUW_MGR_OVERRIDE_RULE_LIST, OVERRIDE_CONFIRMATION_DIALOG_MSG } from 'constants/eval';
import {
  VP, AVP, BACKEND_MANAGER, DOCS_IN, DOC_GEN,
} from 'constants/Groups';
import './Override.css';

const Override = (props) => {
  const {
    ruleEngineErrorMsg, userGroupList, saveOverriddenRule, selectedCaseId, userOverrideRuleList,
  } = props;
  const [overrideData, setOverrideData] = useState({
    isErrorMsgVisible: true,
    show: false,
    notes: '',
    isDisabled: true,
    isConfirmPopupVisible: false,
    isEditVisible: false,
  });

  useEffect(() => {
    if (ruleEngineErrorMsg) {
      const { overridable, ruleId } = ruleEngineErrorMsg;
      if (overridable) {
        if (userGroupList.includes(VP) || userGroupList.includes(AVP)
          || userGroupList.includes(DOCS_IN) || userGroupList.includes(DOC_GEN)
          || (userGroupList.includes(BACKEND_MANAGER)
            && BEUW_MGR_OVERRIDE_RULE_LIST.includes(ruleId))
            || userOverrideRuleList.includes(ruleId)) {
          setOverrideData({
            ...overrideData,
            isEditVisible: true,
          });
        }
      }
    } else {
      setOverrideData({
        ...overrideData,
        isEditVisible: false,
      });
    }
  }, [userGroupList, ruleEngineErrorMsg]);

  const handleChange = (event) => {
    const { target: { value: notesValue } } = event;
    setOverrideData({
      ...overrideData,
      notes: notesValue,
      isDisabled: notesValue.length <= 0,
    });
  };

  const handleClose = () => {
    setOverrideData({
      ...overrideData,
      show: false,
    });
  };

  const defaultState = () => {
    setOverrideData({
      ...overrideData,
      notes: '',
      show: false,
      isConfirmPopupVisible: false,
      isDisabled: true,
    });
  };

  const handleSubmit = () => {
    const { notes } = overrideData;
    const saveData = {
      comments: notes,
      ruleId: ruleEngineErrorMsg.ruleId,
      ruleMessage: ruleEngineErrorMsg.errorMsg,
      resolutionId: selectedCaseId.resolutionId,
    };
    saveOverriddenRule(saveData);
    defaultState();
  };

  const renderOverridePopup = () => {
    const {
      show, notes, isConfirmPopupVisible, isDisabled,
    } = overrideData;
    return (
      <Modal
        disableBackdropClick
        onClose={handleClose}
        open={show}
      >
        <div styleName="override-popup-container">
          <Typography variant="h2">Override Rule</Typography>
          {!isConfirmPopupVisible && (
          <IconButton aria-label="close" onClick={() => setOverrideData({ ...overrideData, isConfirmPopupVisible: true })} styleName="close-btn">
            <CloseIcon />
          </IconButton>
          )}

          {!isConfirmPopupVisible ? (
            <div>
              <Typography style={{ display: 'flex' }} variant="body2">
                    Rule
                {ruleEngineErrorMsg && (<span styleName="error-msg">{ruleEngineErrorMsg.errorMsg}</span>)}
              </Typography>
              <div styleName="input-container">
                <Typography variant="body2">Notes</Typography>
                <TextField
                  hiddenLabel
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  name="notes"
                  onChange={handleChange}
                  rows="4"
                  size="small"
                  value={notes}
                  variant="filled"
                />
              </div>
              <div styleName="button-overrider-container">
                <Button color="primary" disabled={isDisabled} disableElevation onClick={handleSubmit} variant="contained">
                      OVERRIDE
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Typography styleName="confirmation-msg" variant="body2">
                {OVERRIDE_CONFIRMATION_DIALOG_MSG}
              </Typography>
              <div styleName="button-overrider-container">
                <Button
                  color="primary"
                  disableElevation
                  onClick={defaultState}
                  variant="contained"
                >
                      YES
                </Button>
                <Button color="primary" onClick={() => setOverrideData({ ...overrideData, isConfirmPopupVisible: false })} variant="text">
                      NO
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  };
  const { isErrorMsgVisible, isEditVisible } = overrideData;
  return (
    <>
      {isErrorMsgVisible && (
        <>
          <Grid item styleName="error-msg-container">
            {(ruleEngineErrorMsg && isEditVisible) && (
              <IconButton aria-label="Edit" component="span" onClick={() => setOverrideData({ ...overrideData, show: true })} styleName="btn-edit">
                <EditIcon />
              </IconButton>
            )}
          </Grid>
          {renderOverridePopup()}
        </>
      )}

    </>
  );
};

Override.defaultProps = {
  saveOverriddenRule: () => { },
  ruleEngineErrorMsg: {
    errorMsg: null,
    overridable: false,
  },
};

Override.propTypes = {
  ruleEngineErrorMsg: PropTypes.shape({
    errorMsg: PropTypes.string,
    overridable: PropTypes.bool,
    ruleId: PropTypes.number,
  }),
  saveOverriddenRule: PropTypes.func,
  selectedCaseId: PropTypes.func.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string).isRequired,
  userOverrideRuleList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  selectedCaseId: dashboardSelectors.getCaseId(state),
  ruleEngineErrorMsg: dashboardSelectors.getRuleEngineError(state),
  userGroupList: loginSelectors.getGroupList(state),
  userOverrideRuleList: dashboardSelectors.userOverrideRuleList(state),
});

const mapDispatchToProps = dispatch => ({
  saveOverriddenRule: operations.saveOverriddenRuleOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Override);
