import * as R from 'ramda';
import {
  SET_EVAL_INCM_DETAILS,
  SAVE_EVAL_DETAILS,
  SET_DISASTER_TYPE,
  SET_EXPAND_VIEW,
  SAVE_DISPOSITION,
  SAVE_SELECTED_DISPOSITION,
  CLEAR_DISPOSITION,
  CLEAR_FIRST_VISIT,
  HIDE_LOADER,
  SAVE_EVALID_LOANNUMBER,
  SUCCESS_END_SHIFT,
  SHOW_LOADER,
  SHOW_SAVING_LOADER,
  HIDE_SAVING_LOADER,
  CHECKLIST_NOT_FOUND,
  TASKS_NOT_FOUND,
  TASKS_FETCH_ERROR,
  AUTO_SAVE_TRIGGER,
  SEARCH_LOAN_RESULT,
  UNASSIGN_LOAN_RESULT,
  ASSIGN_LOAN_RESULT,
  HIDE_ASSIGN_UNASSIGN,
  CLEAR_BE_DISPOSITION,
  GROUP_NAME,
  SET_GET_NEXT_STATUS,
  USER_NOTIF_MSG,
  DISPLAY_ASSIGN,
  CLEAR_ERROR_MESSAGE,
  // GET_LOAN_ACTIVITY_DETAILS,
  LOAD_TRIALHEADER_RESULT,
  LOAD_TRIALSDETAIL_RESULT,
  LOAD_TRIALLETTER_RESULT,
  SET_TASK_UNDERWRITING_RESULT,
  GET_NEXT_ERROR,
  GETNEXT_PROCESSED,
  PUT_PROCESS_NAME,
  SET_RESULT_OPERATION,
  CLEAN_RESULT,
  CONTINUE_MY_REVIEW_RESULT,
  COMPLETE_MY_REVIEW_RESULT,
  SET_ADD_BULK_ORDER_RESULT,
  SET_BEGIN_SEARCH,
  SET_ENABLE_SEND_BACK_GEN,
  SET_BULK_UPLOAD_RESULT,
  SET_BULK_UPLOAD_PAGE_TYPE,
  SET_ENABLE_SEND_BACK_DOCSIN,
  SET_ENABLE_SEND_TO_BOOKING,
  CLEAR_USER_NOTIF_MSG,
  SET_ENABLE_SEND_TO_UW, SELECT_REJECT,
  CLEAR_SELECT_REJECT,
  SEARCH_LOAN_WITH_TASK,
  SET_STAGER_TASK_NAME,
  SET_STAGER_VALUE_STATE,
  MOD_REVERSAL_DROPDOWN_VALUES,
  POSTMOD_END_SHIFT,
  CLEAR_POSTMOD_END_SHIFT,
  CLEAR_BULKUPLOAD_TABLEDATA,
  CLEAR_DATA,
  SET_INCENTIVE_TASKCODES,
  STORE_EVALID_RESPONSE,
  RESOLUTION_DROP_DOWN_VALUES,
  SET_TRIAL_RESPONSE,
  DISABLE_TRIAL_BUTTON,
  DISCARD_EVAL_RESPONSE,
  SAVE_PROCESSED_FILE,
  DELETE_FILE,
  SET_MOD_DOC_VENDOR_DATA,
  CLEAR_MOD_DOC_VENDOR_SUBMIT_DATA,
  SET_DOWNLOAD_RESPONSE,
  SAVE_EVENTS_DROPDOWN,
  SAVE_INVESTOR_EVENTS_DROPDOWN,
  CLOSE_SWEET_ALERT,
  SET_MOD_DOC_VENDOR_TABINDEX,
  DISABLE_SEND_TO_FEUW,
  SAVE_EVAL_FOR_WIDGET,
  SAVE_MAIN_CHECKLIST,
  SET_USER_NOTIF_MESSAGE,
  SAVE_TASKID,
  DISABLE_PUSHDATA,
  ASSIGN_TO_ME_CLICK,
  SET_PAYMENT_DEFERRAL,
  SET_SELECTED_WIDGET,
  TOGGLE_LOCK_BUTTON,
  SET_POPUP_DATA,
  CLEAR_POPUP_DATA,
  TOGGLE_INCVRFN,
  EVAL_CASE_DETAILS,
  SET_CASE_DETAILS,
  SET_EVAL_INDEX,
  SAVE_EVAL_LOANVIEW,
  SET_USER_NOTIFICATION,
  DISMISS_USER_NOTIFICATION,
  SET_FHLMC_UPLOAD_RESULT,
  SET_FHLMC_MOD_HISTORY,
  TOGGLE_BANNER,
  SET_RESOLUTION_AND_INVSTR_HRCHY,
  GET_ELIGIBLE_DATA,
  SET_REQUEST_TYPE_DATA,
  SET_VALID_EVALDATA,
  CLEAR_POPUP_TABLE_DATA,
  SET_BRAND,
  SET_TRIAL_DISABLE_STAGER_BUTTON,
  SAVE_APPROVAL_DROPDOWN,
  SAVE_PREAPPROVAL_DROPDOWN,
  SET_CANCELLATION_REASON,
  SET_SELECTED_CANCELLATION_REASON,
  CLEAR_CANCELLATION_REASONS,
  SET_DISABLE_GENERATE_BOARDING_TEMPLATE,
  STORE_INVEST_CD_AND_BRAND_NM,
  SET_EXCEPTION_REVIEW_INDICATOR,
  SET_EXCEPTION_REVIEW_COMMENTS,
  SET_CASEIDS,
  SET_ENQUIRY_CASE_ID,
  SET_DISABLE_SUBMITTOFHLMC,
  SET_MILESTONE_DETAILS,
  SET_CURRENT_MILESTONE,
  SET_TRIAL_DATE_INFO,
  UPDATE_TRIAL_PERIOD_RESULT,
  ENABLE_ODM_RERUN_BUTTON,
  DISABLE_SAVE,
  DISABLE_FINANCE_CALC_TAB_BUTTON,
  SAVE_BOOKING_REJECT_DROPDOWN,
  RESET_DATA,
  SET_EXPENSE_LOCK_ID,
  SET_INCOME_LOCK_ID,
  SAVE_WIDGET_TASKID,
  SET_EVAL_DROPDOWN,
  SET_LOAN_TYPE,
  SET_LOAN_TYPE_DESCRIPTION,
  ENABLE_LOAN_DESC_WIDGET,
  SET_WATERFALLID,
  SET_EVAL_ID,
  SET_BORROWER_INFO,
  SHOW_WEST_WING_WIDGET,
  RESET_RESULT_OPERATION,
  SET_WORKOUT_TYPE,
  TOGGLE_SEND_BACK_DOCSIN,
  SENT_TODOCSIN_RESPONSE,
  SET_ISMODONLY_LOAN,
  SET_RESOLUTIONCHOICE_TYPE,
  SET_REPAYMENT_CASE_ID,
  ENABLE_PAYMENT_SUPPLEMENT_WIDGET,
  SET_MESSAGE_CODES,
  SET_DELIQUENT_INFO,
  SET_PROPERTY,
  SET_LIEN_POSITION,
  FETCH_MOD_STATUS,
  SET_PROCESSID_TASKID,
  IS_EVAL_WIDGET_APPLICABLE,
  SHOW_CENTRE_CHECKLIST_PANE_LOADER,
  OVERRIDE_CASE_RULE,
  SET_RULE_ENGINE_ERROR,
  SET_CASE_ID,
  USER_OVERRIDE_RULE_LIST,
  SET_SUBMIT_TO_FHLMC_BUTTON_CLICKED,
  SEARCHLOAN_VAL,
  SET_SELECTED_CASEID,
  SET_CASE_STS,
} from './types';

const initialState = {
  firstVisit: true,
  modDocVendorTabIndex: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_MILESTONE: {
      const milestone = action.payload;
      return {
        ...state,
        milestone,
      };
    }
    case TOGGLE_INCVRFN: {
      return {
        ...state,
        isIncomeVerification: action.payload,
      };
    }
    case SET_PAYMENT_DEFERRAL: {
      const data = action.payload;
      return {
        ...state,
        isPaymentDeferral: data,
      };
    }
    case DISCARD_EVAL_RESPONSE: {
      return {
        ...state,
        evalInsertionStatus: null,
      };
    }
    case STORE_EVALID_RESPONSE: {
      return {
        ...state,
        evalInsertionStatus: action.payload,
      };
    }
    case SET_INCENTIVE_TASKCODES: {
      return {
        ...state,
        incentiveTaskCodes: action.payload,
      };
    }
    case SET_GET_NEXT_STATUS: {
      return {
        ...state,
        showGetNext: action.payload,
      };
    }
    case USER_NOTIF_MSG: {
      return {
        ...state,
        checklistDiscrepancies: action.payload,
      };
    }
    case CLEAR_USER_NOTIF_MSG: {
      return {
        ...state,
        checklistDiscrepancies: {},
      };
    }
    case SELECT_REJECT: {
      return {
        ...state,
        rejectResponse: action.payload,
        wasSearched: false,
      };
    }
    case CLEAR_SELECT_REJECT: {
      const trialClosingResponse = null;
      return {
        ...state,
        trialClosingResponse,
        rejectResponse: null,
      };
    }
    // case GET_LOAN_ACTIVITY_DETAILS: {
    //   return {
    //     ...state,
    //     loanActivityDetails: action.payload,
    //   };
    // }
    case CLEAR_DISPOSITION: {
      const newState = {
        ...state,
        selectedDisposition: '',
      };
      delete newState.getNextResponse;
      return newState;
    }
    case SEARCH_LOAN_WITH_TASK: {
      return {
        ...state,
        searchLoanTaskResponse: action.payload,
        assignLoanResponse: {},
        unassignLoanResponse: {},
        clearSearch: true,
        checklistErrorCode: '',
      };
    }
    case SET_STAGER_TASK_NAME: {
      return {
        ...state,
        stagerTaskName: action.payload,
      };
    }
    case SET_STAGER_VALUE_STATE: {
      return {
        ...state,
        stagerValueAndState: action.payload,
      };
    }
    case CLEAR_FIRST_VISIT: {
      const newState = {
        ...state,
        firstVisit: false,
        isAssigned: false,
        milestoneDetails: null,
      };
      return newState;
    }
    case SET_EXPAND_VIEW: {
      return {
        ...state,
        expandView: !state.expandView,
      };
    }
    case SAVE_DISPOSITION: {
      let getNextResponse = {};
      if (action.payload) {
        getNextResponse = action.payload;
      }
      return {
        ...state,
        getNextResponse,
      };
    }

    case DISMISS_USER_NOTIFICATION: {
      return {
        ...state,
        userNotification: {
          isOpen: false,
          level: null,
          message: null,
        },
      };
    }
    case POSTMOD_END_SHIFT: {
      return {
        postModEndShift: true,
        firstVisit: true,
        isAssigned: true,
        clearSearch: true,
        groupName: state.groupName,
        stagerTaskName: state.stagerTaskName,
        getSearchLoanResponse: {},
      };
    }
    case CLEAR_POSTMOD_END_SHIFT: {
      return {
        ...state,
        postModEndShift: false,
      };
    }
    case SEARCH_LOAN_RESULT: {
      let getSearchLoanResponse = {};
      let loannumber = null;
      let resolutionid = null;
      if (action.payload) {
        getSearchLoanResponse = action.payload;
        const { loanNumber } = getSearchLoanResponse;
        resolutionid = R.pathOr('', ['unAssigned', 0, 'resolutionId'], getSearchLoanResponse);
        loannumber = loanNumber;
      }
      return {
        ...state,
        getSearchLoanResponse,
        loanNumber: loannumber,
        resolutionId: resolutionid,
        assignLoanResponse: {},
        unassignLoanResponse: {},
        clearSearch: true,
        checklistErrorCode: '',
        inProgress: false,
        wasSearched: true,
        userNotification: {},
        isIncomeVerification: false,
      };
    }

    case UNASSIGN_LOAN_RESULT: {
      let unassignLoanResponse = {};
      if (action.payload) {
        unassignLoanResponse = action.payload;
      }
      return {
        ...state,
        unassignLoanResponse,
      };
    }
    case ASSIGN_LOAN_RESULT: {
      let assignLoanResponse = {};
      const { showAssign } = state;
      if (action.payload) {
        assignLoanResponse = action.payload;
      }
      return {
        ...state,
        assignLoanResponse,
        isAssigned: (R.isNil(showAssign) && !R.isEmpty(assignLoanResponse)) || (R.contains(R.pathOr(null, ['taskData', 'taskName'], assignLoanResponse), ['Stager-Escrow-ToOrder', 'Stager-Escrow-Ordered'])),
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        inProgress: true,
        noTasksFound: false,
        checklistErrorCode: '',
        wasSearched: false,
      };
    }
    case GETNEXT_PROCESSED: {
      return {
        ...state,
        getNextProcessed: action.payload,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        inProgress: false,
      };
    }
    case SET_BEGIN_SEARCH: {
      return {
        ...state,
        wasSearched: false,
        resultOperation: {},
      };
    }
    case SHOW_SAVING_LOADER: {
      return {
        ...state,
        saveInProgress: true,
      };
    }
    case HIDE_SAVING_LOADER: {
      return {
        ...state,
        saveInProgress: false,
      };
    }
    case SUCCESS_END_SHIFT: {
      return {
        firstVisit: true,
        isAssigned: true,
        clearSearch: true,
        groupName: state.groupName,
        stagerTaskName: state.stagerTaskName,
        getSearchLoanResponse: {},
      };
    }
    case CHECKLIST_NOT_FOUND: {
      return {
        ...state,
        noTasksFound: true,
        checklistErrorCode: R.pathOr('', ['payload', 'messageCode'], action),
      };
    }
    case TASKS_NOT_FOUND: {
      let noTasksFound;
      if (action.payload) {
        ({ noTasksFound } = action.payload);
      }
      return {
        ...state,
        noTasksFound,
        evalId: null,
        loanNumber: null,
        taskId: null,
      };
    }
    case GET_NEXT_ERROR: {
      let isGetNextError;
      let getNextError;
      if (action.payload) {
        ({ isGetNextError, getNextError } = action.payload);
      }
      return {
        ...state,
        isGetNextError,
        getNextError,
        evalId: null,
        loanNumber: null,
        taskId: null,
      };
    }
    case TASKS_FETCH_ERROR: {
      let taskFetchError;
      if (action.payload) {
        taskFetchError = action.payload.taskfetchError;
      }
      return {
        ...state,
        taskFetchError,
        evalId: null,
        loanNumber: null,
        taskId: null,
      };
    }
    case AUTO_SAVE_TRIGGER: {
      let taskStatusUpdate;
      if (action.payload) {
        taskStatusUpdate = action.payload;
      }
      return {
        ...state,
        taskStatusUpdate,
      };
    }
    case SAVE_SELECTED_DISPOSITION: {
      let selectedDisposition = '';
      if (action.payload) {
        selectedDisposition = action.payload;
      }
      return {
        ...state,
        selectedDisposition,
      };
    }
    case SAVE_EVAL_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SAVE_EVALID_LOANNUMBER: {
      const newState = {
        ...state,
        brand: action.payload.brand,
        evalId: action.payload.evalId,
        selectedResolutionId: action.payload.resolutionId,
        resolutionId: action.payload.resolutionId,
        loanNumber: action.payload.loanNumber,
        taskId: action.payload.taskId,
        processId: action.payload.piid,
        processStatus: action.payload.pstatus,
        taskStatus: action.payload.tstatus,
        processName: action.payload.taskName,
        showAssign: action.payload.isSearch ? !!action.payload.assignee : null,
        taskFetchError: false,
        noTasksFound: false,
        isAssigned: action.payload.isSearch ? action.payload.isAssigned : true,
        taskIterationCounter: action.payload.taskIterationCounter,
        showContinueMyReview: action.payload.isSearch ? action.payload.showContinueMyReview : false,
        getSearchLoanResponse: {},
        completeReviewResponse: null,
        investorCode: action.payload.investorCode,
        disasterType: action.payload.disasterType,
      };
      return newState;
    }
    case SET_DISASTER_TYPE: {
      const disasterType = action.payload;
      return {
        ...state,
        disasterType,
      };
    }
    case SAVE_EVAL_FOR_WIDGET: {
      const widgetLoan = action.payload;
      return {
        ...state,
        widgetLoan,
      };
    }
    case SET_EVAL_INCM_DETAILS: {
      const evalIncmDetails = action.payload;
      const {
        evalId, processId, taskId, feuwChecklistId, incomeLockInd, expenseLockInd,
        isFreshIncomeChecklist, isFreshExpenseChecklist,
      } = evalIncmDetails;
      return {
        ...state,
        evalId,
        processId,
        feuwChecklistId,
        taskId,
        incomeLockInd,
        expenseLockInd,
        isFreshIncomeChecklist: isFreshIncomeChecklist === 'true',
        isFreshExpenseChecklist: isFreshExpenseChecklist === 'true',
      };
    }
    case SET_INCOME_LOCK_ID: {
      return {
        ...state,
        incomeLockInd: action.payload,
        isFreshIncomeChecklist: false,
      };
    }
    case SET_EXPENSE_LOCK_ID: {
      return {
        ...state,
        expenseLockInd: action.payload,
        isFreshExpenseChecklist: false,
      };
    }
    case SAVE_MAIN_CHECKLIST: {
      const checklistDetails = action.payload;
      return {
        ...state,
        ...checklistDetails,
      };
    }
    case CONTINUE_MY_REVIEW_RESULT: {
      const newState = {
        ...state,
        isAssigned: action.payload,
        showContinueMyReview: !action.payload,
        showAssign: null,
      };
      return newState;
    }

    case COMPLETE_MY_REVIEW_RESULT: {
      return {
        ...state,
        completeReviewResponse: action.payload,
        firstVisit: true,
        isAssigned: true,
        clearSearch: true,
        groupName: state.groupName,
        stagerTaskName: state.stagerTaskName,
        getSearchLoanResponse: {},
      };
    }

    case HIDE_ASSIGN_UNASSIGN: {
      const { assignLoanResponse } = state;
      return {
        ...state,
        showAssign: null,
        showCompleteMyreview: false,
        isAssigned: !R.isEmpty(assignLoanResponse),
      };
    }

    case DISPLAY_ASSIGN: {
      const { assignLoanResponse } = state;
      return {
        ...state,
        showAssign: false,
        isAssigned: !R.isEmpty(assignLoanResponse),
        showContinueMyReview: false,
      };
    }

    case GROUP_NAME: {
      return {
        ...state,
        groupName: action.payload,
      };
    }
    case CLEAR_BE_DISPOSITION: {
      const { selectedDisposition } = state;
      const clearDisposition = {
        ...selectedDisposition,
        id: '',
        statusName: '',
        isActivitySelected: '',
        cardStatus: {},
      };
      const newState = {
        ...state,
        selectedDisposition: clearDisposition,
      };
      return newState;
    }

    case CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        noTasksFound: false,
        checklistErrorCode: '',
      };
    }
    case LOAD_TRIALHEADER_RESULT: {
      const trialHeader = action.payload;
      const enableSendToUW = true;
      const disableTrialTaskButton = false;
      return {
        ...state,
        trialHeader,
        enableSendToUW,
        disableTrialTaskButton,
        loading: false,
      };
    }

    case PUT_PROCESS_NAME: {
      const processName = action.payload;
      return {
        ...state,
        processName,
        loading: false,
      };
    }
    case LOAD_TRIALSDETAIL_RESULT: {
      const trialsDetail = action.payload;
      return {
        ...state,
        trialsDetail,
        loading: false,
      };
    }

    case LOAD_TRIALLETTER_RESULT: {
      const trialsLetter = action.payload;
      return {
        ...state,
        trialsLetter,
        loading: false,
      };
    }

    case SET_TASK_UNDERWRITING_RESULT: {
      const resultUnderwriting = action.payload;
      return {
        ...state,
        resultUnderwriting,
        loading: false,
      };
    }
    case SET_USER_NOTIF_MESSAGE: {
      const { clearData } = action.payload;
      const userNotification = {
        ...action.payload,
        isOpen: true,
        clearData: clearData || false,
      };
      return {
        ...state,
        userNotification,
        tableData: [],
        loading: false,
      };
    }
    case SET_RESULT_OPERATION: {
      const { clearData } = action.payload;
      const resultOperation = {
        ...action.payload,
        isOpen: true,
        clearData: clearData || false,
      };
      return {
        ...state,
        resultOperation,
        tableData: [],
        loading: false,
      };
    }
    case SET_USER_NOTIFICATION: {
      const { message, level } = action.payload;
      const userNotification = {
        message,
        level,
        isOpen: true,
      };
      return {
        ...state,
        userNotification,
      };
    }

    case SET_POPUP_DATA: {
      const popupData = {
        ...action.payload,
        isOpen: true,
      };
      return {
        ...state,
        popupData,
      };
    }

    case CLEAR_POPUP_DATA: {
      return {
        ...state,
        popupData: {},
      };
    }

    case CLEAR_BULKUPLOAD_TABLEDATA: {
      return {
        ...state,
        tableData: [],
      };
    }

    case CLEAR_DATA: {
      const resultOperation = {};
      const resultData = {};
      const eventNames = [];
      const sendToModDocVendorSuccess = undefined;
      return {
        ...state,
        resultData,
        resultOperation,
        eventNames,
        sendToModDocVendorSuccess,
        modDocVendorTabIndex: 0,
      };
    }

    case CLEAN_RESULT: {
      const resultOperation = {};
      const enableSendToDocGen = true;
      const enableSendToDocsIn = true;
      const enableSendToBooking = true;
      return {
        ...state,
        resultOperation,
        enableSendToDocGen,
        enableSendToDocsIn,
        enableSendToBooking,
        loading: false,
        incCalcLockVisibility: true,
      };
    }

    case SET_ADD_BULK_ORDER_RESULT: {
      const tableData = action.payload;
      return {
        ...state,
        tableData,
        loading: false,
      };
    }

    case SET_BULK_UPLOAD_RESULT: {
      let resultData = {};
      resultData = action.payload;
      const resultOperation = {};
      return {
        ...state,
        resultData,
        resultOperation,
        loading: false,
      };
    }

    case SET_FHLMC_UPLOAD_RESULT: {
      const resultData = action.payload;
      return {
        ...state,
        resultData,
        loading: false,
      };
    }

    case SET_FHLMC_MOD_HISTORY: {
      const popupTableData = action.payload;
      return {
        ...state,
        popupTableData,
      };
    }

    case CLEAR_POPUP_TABLE_DATA: {
      return {
        ...state,
        popupTableData: null,
      };
    }

    case SET_ENABLE_SEND_BACK_GEN: {
      const enableSendToDocGen = action.payload;
      return {
        ...state,
        enableSendToDocGen,
      };
    }

    case SET_BULK_UPLOAD_PAGE_TYPE: {
      const pageType = action.payload;
      return {
        ...state,
        pageType,
      };
    }

    case SET_ENABLE_SEND_BACK_DOCSIN: {
      const enableSendToDocsIn = action.payload;
      return {
        ...state,
        enableSendToDocsIn,
        disableAssigntomeBtn: true,
      };
    }

    case SET_ENABLE_SEND_TO_BOOKING: {
      const enableSendToBooking = action.payload;
      return {
        ...state,
        enableSendToBooking,
        disableAssigntomeBtn: true,
      };
    }

    case ASSIGN_TO_ME_CLICK: {
      const disableAssigntomeBtn = action.payload;
      return {
        ...state,
        disableAssigntomeBtn,
      };
    }

    case DISABLE_SEND_TO_FEUW: {
      return {
        ...state,
        disableSendToFEUW: true,
      };
    }

    case SET_ENABLE_SEND_TO_UW: {
      const enableSendToUW = action.payload;
      return {
        ...state,
        enableSendToUW,
      };
    }

    case MOD_REVERSAL_DROPDOWN_VALUES: {
      const modReversalReasons = action.payload;
      return {
        ...state,
        modReversalReasons,
      };
    }

    case RESOLUTION_DROP_DOWN_VALUES: {
      const resolutionData = action.payload;
      return {
        ...state,
        resolutionData,
      };
    }

    case SET_TRIAL_RESPONSE: {
      const trialClosingResponse = action.payload;
      return {
        ...state,
        trialClosingResponse,
      };
    }

    case DISABLE_TRIAL_BUTTON: {
      return {
        ...state,
        disableTrialTaskButton: action.payload.disableTrialTaskButton,
        enableSendToUW: !action.payload.disableTrialTaskButton,
      };
    }

    case SAVE_PROCESSED_FILE:
      return {
        ...state,
        excelParsedData: action.payload,
      };

    case DELETE_FILE:
      return {
        ...state,
        isFileDeleted: action.payload,
        fileSubmitResponse: {},
        excelParsedData: null,
      };
    case SET_MOD_DOC_VENDOR_DATA: {
      const { resultData } = state;
      const { uploadFailed } = action.payload;
      return {
        ...state,
        resultData: R.isEmpty(uploadFailed) ? {} : { ...resultData, uploadFailed },
        fileSubmitResponse: action.payload,
      };
    }
    case SET_MOD_DOC_VENDOR_TABINDEX: {
      const { modDocVendorTabIndex } = action.payload;
      return {
        ...state,
        modDocVendorTabIndex: modDocVendorTabIndex || 0,
      };
    }
    case DISABLE_PUSHDATA: {
      return {
        ...state,
        disablePushData: action.payload,
      };
    }
    case CLOSE_SWEET_ALERT: {
      return {
        ...state,
        resultOperation: {},
      };
    }
    case CLEAR_MOD_DOC_VENDOR_SUBMIT_DATA:
      return {
        ...state,
        fileSubmitResponse: {},
        downloadResponse: {},
      };
    case SET_DOWNLOAD_RESPONSE:
      return {
        ...state,
        downloadResponse: action.payload,
      };
    case SAVE_EVENTS_DROPDOWN: {
      const { payload } = action;
      return {
        ...state,
        modDocVendorEventOptions: payload,
      };
    }
    case SAVE_INVESTOR_EVENTS_DROPDOWN: {
      const { payload } = action;
      return {
        ...state,
        investorEventOptions: payload,
      };
    }
    case SAVE_APPROVAL_DROPDOWN: {
      const { payload } = action;
      return {
        ...state,
        approvalDropdown: payload,
      };
    }
    case SAVE_PREAPPROVAL_DROPDOWN: {
      const { payload } = action;
      return {
        ...state,
        preApprovalDropdown: payload,
      };
    }
    case SET_SELECTED_WIDGET: {
      return {
        ...state,
        selectedWidget: action.payload,
      };
    }
    case SAVE_TASKID: {
      const bookingTaskId = action.payload;
      return {
        ...state,
        bookingTaskId,
      };
    }
    case SAVE_WIDGET_TASKID: {
      const taskId = action.payload;
      return {
        ...state,
        taskId,
      };
    }
    case TOGGLE_LOCK_BUTTON: {
      const enableLockButton = action.payload;
      return {
        ...state,
        enableLockButton,
      };
    }

    case TOGGLE_BANNER: {
      const showBanner = action.payload;
      return {
        ...state,
        showBanner,
      };
    }

    case EVAL_CASE_DETAILS: {
      const evalCaseDetails = action.payload;
      return {
        ...state,
        evalCaseDetails,
      };
    }

    case SET_CASE_DETAILS: {
      const caseDetails = action.payload;
      return {
        ...state,
        caseDetails,
      };
    }

    case SET_EVAL_INDEX: {
      const { index, evalId } = action.payload;

      return {
        ...state,
        evalIndex: index,
        addInfoEvalId: evalId,
      };
    }

    case SAVE_EVAL_LOANVIEW: {
      const newState = {
        ...state,
        brand: action.payload.brand,
        evalId: action.payload.evalId,
        loanNumber: action.payload.loanNumber,
        taskId: action.payload.taskId,
        processId: action.payload.piid,
        processStatus: action.payload.pstatus,
        taskStatus: action.payload.tstatus,
        processName: action.payload.taskName,
        showAssign: action.payload.isSearch ? !!action.payload.assignee : null,
        taskFetchError: false,
        noTasksFound: false,
        isAssigned: action.payload.isSearch ? action.payload.isAssigned : true,
        taskIterationCounter: action.payload.taskIterationCounter,
        showContinueMyReview: action.payload.isSearch ? action.payload.showContinueMyReview : false,
        completeReviewResponse: null,
      };
      return newState;
    }

    case SET_RESOLUTION_AND_INVSTR_HRCHY: {
      const { resolutionId, investorHierarchy } = action.payload;
      if (resolutionId) {
        return {
          ...state,
          resolutionId,
          investorHierarchy,
        };
      }
      return {
        ...state,
        investorHierarchy,
      };
    }
    case ENABLE_ODM_RERUN_BUTTON: {
      const enableODMRerun = action.payload;
      return {
        ...state,
        enableODMRerun,
      };
    }
    case SET_REQUEST_TYPE_DATA: {
      const setRequestTypeData = action.payload;
      return {
        ...state,
        setRequestTypeData,
      };
    }

    case GET_ELIGIBLE_DATA: {
      return {
        ...state,
        eligibleData: action.payload,
      };
    }

    case SET_VALID_EVALDATA: {
      const evalStatusData = action.payload;
      const resultOperation = {};
      return {
        ...state,
        evalStatusData,
        resultOperation,
      };
    }
    case SET_BRAND: {
      const brand = action.payload;
      return {
        ...state,
        brand,
      };
    }
    case SET_TRIAL_DISABLE_STAGER_BUTTON: {
      const disableTrialStagerButton = action.payload;
      return {
        ...state,
        disableTrialStagerButton,
      };
    }


    case SET_CANCELLATION_REASON: {
      const cancellationReasons = action.payload;
      return {
        ...state,
        selectedCancellationReason: '',
        cancellationReasons,
      };
    }

    case SET_SELECTED_CANCELLATION_REASON: {
      const selectedCancellationReason = action.payload;
      return {
        ...state,
        selectedCancellationReason,
      };
    }

    case CLEAR_CANCELLATION_REASONS: {
      return {
        ...state,
        selectedCancellationReason: '',
        cancellationReasons: [],
      };
    }

    case SET_DISABLE_GENERATE_BOARDING_TEMPLATE: {
      const isGBTdisabled = action.payload;
      return {
        ...state,
        isGBTdisabled,
      };
    }

    case STORE_INVEST_CD_AND_BRAND_NM: {
      const { investorCode, brandName } = action.payload;
      return {
        ...state,
        investorCode,
        brand: brandName,
      };
    }

    case SET_EXCEPTION_REVIEW_INDICATOR: {
      const exceptionReviewIndicator = action.payload;
      return {
        ...state,
        exceptionReviewIndicator,
      };
    }

    case SET_EXCEPTION_REVIEW_COMMENTS: {
      const exceptionReviewComments = action.payload;
      return {
        ...state,
        exceptionReviewComments,
      };
    }

    case SET_CASEIDS: {
      const caseIds = action.payload;
      return {
        ...state,
        caseIds,
      };
    }

    case SET_ENQUIRY_CASE_ID: {
      const enquiryCaseId = action.payload;
      return {
        ...state,
        enquiryCaseId,
      };
    }

    case SET_DISABLE_SUBMITTOFHLMC: {
      const disableSubmittoFhlmc = action.payload;
      return {
        ...state,
        disableSubmittoFhlmc,
      };
    }

    case SET_MILESTONE_DETAILS: {
      const milestoneDetails = action.payload;
      return {
        ...state,
        milestoneDetails,
      };
    }

    case SET_TRIAL_DATE_INFO: {
      const { payload: { fbDetailId, fieldToUpdate, updatedDate } } = action;
      const { trialsDetail } = state;
      const updatedTrialsDetail = trialsDetail ? trialsDetail.map((e) => {
        if (R.equals(R.propOr('', 'forbearanceDetailId', e), fbDetailId)) {
          return {
            ...e,
            isUpdated: true,
            [fieldToUpdate]: updatedDate,
          };
        }
        return e;
      }) : [];
      return {
        ...state,
        trialsDetail: updatedTrialsDetail,
      };
    }

    case UPDATE_TRIAL_PERIOD_RESULT: {
      const { updateTrialPeriodResult } = action.payload;
      const resultOperation = {
        ...action.payload,
        isOpen: true,
      };
      return {
        ...state,
        resultOperation,
        updateTrialPeriodResult,
        isSaveDisabled: true,
      };
    }

    case DISABLE_SAVE: {
      return {
        ...state,
        isSaveDisabled: action.payload,
      };
    }

    case DISABLE_FINANCE_CALC_TAB_BUTTON: {
      return {
        ...state,
        disableFinanceCalcTabButton: action.payload,
      };
    }

    case SAVE_BOOKING_REJECT_DROPDOWN: {
      return {
        ...state,
        rejectReasonDropdownOptions: action.payload,
      };
    }

    case SENT_TODOCSIN_RESPONSE: {
      const data = JSON.parse(JSON.stringify(action.payload));
      return {
        ...state,
        sentToDocsInResponse: data,
      };
    }

    case TOGGLE_SEND_BACK_DOCSIN: {
      return {
        ...state,
        enableSendToDocsIn: action.payload,
      };
    }

    case RESET_DATA: {
      return {
        ...state,
        isGetNextError: false,
        getNextError: '',
      };
    }

    case SET_EVAL_DROPDOWN: {
      return {
        ...state,
        evalDropdownData: action.payload,
      };
    }

    case SET_LOAN_TYPE: {
      return {
        ...state,
        loanType: action.payload,
      };
    }

    case SET_LOAN_TYPE_DESCRIPTION: {
      return {
        ...state,
        loanTypeDescription: action.payload,
      };
    }

    case ENABLE_LOAN_DESC_WIDGET: {
      const enableLoanDescWidget = action.payload;
      return {
        ...state,
        enableLoanDescWidget,
      };
    }

    case SET_WATERFALLID: {
      return {
        ...state,
        waterfallId: action.payload,
      };
    }

    case SET_EVAL_ID: {
      return {
        ...state,
        evalId: action.payload,
      };
    }
    case SET_BORROWER_INFO: {
      const processedBorrowerData = action.payload;
      return {
        ...state,
        processedBorrowerData,
      };
    }

    case SHOW_WEST_WING_WIDGET: {
      const showWestwingWidget = action.payload;
      return {
        ...state,
        showWestwingWidget,
      };
    }

    case RESET_RESULT_OPERATION: {
      return {
        ...state,
        resultOperation: {
          isOpen: false,
          status: null,
          level: null,
        },
      };
    }

    case SET_WORKOUT_TYPE: {
      const workoutType = action.payload;
      return {
        ...state,
        workoutType,
      };
    }

    case SET_ISMODONLY_LOAN: {
      const isModOnlyLoan = action.payload;
      return {
        ...state,
        isModOnlyLoan,
      };
    }
    case SET_RESOLUTIONCHOICE_TYPE: {
      return {
        ...state,
        resolutionChoiceType: action.payload,
      };
    }
    case SET_REPAYMENT_CASE_ID: {
      return {
        ...state,
        repaymentCaseId: action.payload,
      };
    }
    case ENABLE_PAYMENT_SUPPLEMENT_WIDGET: {
      const showPaymentSupplement = action.payload;
      return {
        ...state,
        showPaymentSupplement,
      };
    }
    case SET_MESSAGE_CODES: {
      const messageCodes = action.payload;
      return {
        ...state,
        messageCodes,
      };
    }
    case SET_DELIQUENT_INFO: {
      const deliquentInfo = action.payload;
      return {
        ...state,
        deliquentInfo,
      };
    }
    case SET_PROPERTY: {
      const property = action.payload;
      return {
        ...state,
        property,
      };
    }

    case SET_LIEN_POSITION: {
      return {
        ...state,
        lienPosition: action.payload,
      };
    }

    case FETCH_MOD_STATUS: {
      return {
        ...state,
        modStatus: action.payload,
      };
    }

    case SET_PROCESSID_TASKID: {
      const { processId, taskId } = action.payload;
      return {
        ...state,
        processId,
        taskId,
      };
    }

    case IS_EVAL_WIDGET_APPLICABLE: {
      const isEvalWidgetApplicable = action.payload;
      return {
        ...state,
        isEvalWidgetApplicable,
      };
    }

    case SHOW_CENTRE_CHECKLIST_PANE_LOADER: {
      return {
        ...state,
        isValidateLoading: action.payload,
      };
    }

    case SET_SUBMIT_TO_FHLMC_BUTTON_CLICKED: {
      return {
        ...state,
        submitToFhlmcButtonClicked: action.payload,
      };
    }

    case SEARCHLOAN_VAL: {
      return {
        ...state,
        searchLoanNbr: action.payload,
      };
    }

    case OVERRIDE_CASE_RULE: {
      return {
        ...state,
        isOverride: action.payload,
      };
    }

    case SET_RULE_ENGINE_ERROR: {
      return {
        ...state,
        ruleEngineError: action.payload,
      };
    }

    case SET_CASE_ID: {
      return {
        ...state,
        setCaseId: action.payload,
      };
    }

    case USER_OVERRIDE_RULE_LIST: {
      const { payload } = action;
      return {
        ...state,
        userOverrideRuleList: payload,
      };
    }
    case SET_SELECTED_CASEID: {
      const selectedCaseId = action.payload;
      return {
        ...state,
        selectedCaseId,
      };
    }

    case SET_CASE_STS: {
      const caseStatus = action.payload;
      return {
        ...state,
        caseStatus,
      };
    }

    default:
      return state;
  }
};

export default reducer;
