import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/tombstone';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import Loader from 'components/Loader';
import CloseIcon from '@material-ui/icons/Close';
import DashboardModel from 'models/Dashboard';
import './RepossessionTableContent.css';

const getDisplayValue = (value, type) => {
  if (!value) {
    // If value is falsy (empty, null, undefined, etc.), return the default value
    if (type === 'amount' || type === 'number') return 0;
    return 'N/A';
  }
  return value;
};

const getAddress = (line1, line2) => {
  if (!line1 && !line2) {
    return 'N/A';
  }
  if (line1 && line2) {
    return `${line1}, ${line2}`;
  }
  return line1 || line2;
};

const RepossessionTableContent = ({
  repossessionTableData = {},
  getRepossessionTableData,
  activeTile,
  setChecklistCenterPaneData,
  loading,
}) => {
  useEffect(() => {
    getRepossessionTableData();
  }, []);

  const handleClose = () => {
    if (activeTile === DashboardModel.NPV) {
      setChecklistCenterPaneData('NPVResults');
    } else {
      setChecklistCenterPaneData('Checklist');
    }
  };

  const {
    generalLoanInformation = {},
    loanInformation = {},
    repossessionVendorInfo = {},
    collateralInformation = {},
    letterHistory = {},
    loanIndicator = {},
    repossessionActions = {},
  } = repossessionTableData || {};

  const {
    vinNumber1 = {},
    vinNumber2 = {},
    vinNumber3 = {},
    vinNumber4 = {},
  } = collateralInformation;

  const {
    mailingAddress = {},
    borrowerName = '',
    cityStateZip = '',
    coBorrowerName = '',
    loanNumber = '',
  } = generalLoanInformation;

  const {
    repossessionVendor = '',
    repossessionVendorAddress = {},
    repossessionVendorPhoneNumber = '',
    repossessionVendorWebsite = '',
    vendorCityStateZip = '',
    vendorFileNumber = '',
  } = repossessionVendorInfo;

  const sections = [
    {
      title: 'General Loan Information',
      styleName: 'section-general-loan',
      columns: 'two-columns',
      fields: [
        { label: 'Loan Number', value: getDisplayValue(loanNumber, 'string') },
        { label: 'Mailing City, State Zip', value: getDisplayValue(cityStateZip, 'string') },
        { label: 'Borrower Name', value: getDisplayValue(borrowerName, 'string') },
        {
          label: 'Mailing Address',
          value: getAddress(mailingAddress.line1, mailingAddress.line2),
        },
        { label: 'Co-Borrower Name', value: getDisplayValue(coBorrowerName, 'string') },
      ],
    },
    {
      title: 'Loan Information',
      styleName: 'section-loan-info',
      columns: 'two-columns',
      fields: [
        { label: 'UPB Balance', value: `$${getDisplayValue(loanInformation.upbBalance, 'amount')}` },
        { label: 'Note Date', value: getDisplayValue(loanInformation.noteDate, 'date') },
        { label: 'Interest Rate', value: `${getDisplayValue(loanInformation.interestRate, 'number')}%` },
        { label: 'Escrow Balance', value: `$${getDisplayValue(loanInformation.escrowBalance, 'amount')}` },
        { label: 'Paid To Date', value: getDisplayValue(loanInformation.paidToDate, 'date') },
        { label: 'Suspense Balance', value: `$${getDisplayValue(loanInformation.suspenseBalance, 'amount')}` },
        { label: 'Day of Delinquency', value: getDisplayValue(loanInformation.dayOfDelinquency, 'number') },
        { label: 'Bankruptcy Status', value: getDisplayValue(loanInformation.bankruptcyStatus, 'string') },
        { label: 'Late Fees', value: `$${getDisplayValue(loanInformation.lateFees, 'amount')}` },
        { label: 'MISC Fees', value: `$${getDisplayValue(loanInformation.miscFees, 'amount')}` },
        { label: 'MFR Granted', value: getDisplayValue(loanInformation.mfrGranted, 'string') },
      ],
    },
    {
      title: 'Repossession Vendor',
      styleName: 'section-repossession-vendor',
      columns: 'three-columns',
      fields: [
        { label: 'Repossession Vendor', value: getDisplayValue(repossessionVendor, 'string') },
        { label: 'Repossession Vendor Phone Number', value: getDisplayValue(repossessionVendorPhoneNumber, 'string') },
        { label: 'Vendor City, State, Zip', value: getDisplayValue(vendorCityStateZip, 'string') },
        {
          label: 'Repossession Vendor Address',
          value: getAddress(repossessionVendorAddress.line1, repossessionVendorAddress.line2),
        },
        { label: 'Repossession Vendor Website', value: getDisplayValue(repossessionVendorWebsite, 'string') },
        { label: 'Vendor File Number', value: getDisplayValue(vendorFileNumber, 'string') },
      ],
    },
    {
      title: 'Collateral Information',
      styleName: 'section-collateral-info',
      columns: 'two-columns',
      fields: [
        { label: 'Year - Collateral', value: getDisplayValue(vinNumber1.collateralYear, 'string') },
        { label: 'VIN Number 1', value: getDisplayValue(vinNumber1.vinValue, 'string') },
        { label: 'Make of Collateral', value: getDisplayValue(vinNumber1.collateralMake, 'string') },
        { label: 'VIN Number 2', value: getDisplayValue(vinNumber2.vinValue, 'string') },
        { label: 'Model of Collateral', value: getDisplayValue(vinNumber1.collateralModel, 'string') },
        { label: 'VIN Number 3', value: getDisplayValue(vinNumber3.vinValue, 'string') },
        { label: 'Location of Collateral', value: getDisplayValue(vinNumber1.collateralLocation, 'string') },
        { label: 'VIN Number 4', value: getDisplayValue(vinNumber4.vinValue, 'string') },
      ],
    },
    {
      title: 'Letter History',
      styleName: 'section-letter-history',
      columns: 'three-columns',
      fields: [
        {
          label: 'Notice of Default Letter Sent',
          value: getDisplayValue(letterHistory.noticeOfDefaultLetterSent, 'date'),
        },
        {
          label: 'Amended Deficiency Letter Sent',
          value: getDisplayValue(letterHistory.amendedDeficiencyLetterSent, 'date'),
        },
        {
          label: 'Deficiency Letter Sent',
          value: getDisplayValue(letterHistory.deficiencyLetterSent, 'date'),
        },
        {
          label: 'Notice of Intent Letter Sent',
          value: getDisplayValue(letterHistory.noticeOfIntentLetterSent, 'date'),
        },
        {
          label: 'Surplus Letter Sent',
          value: getDisplayValue(letterHistory.surplusLetterSent, 'date'),
        },
        {
          label: 'Ancillary Letter Sent',
          value: getDisplayValue(letterHistory.ancillaryLetterSent, 'date'),
        },
      ],
    },
    {
      title: 'Loan Indicator',
      styleName: 'section-loan-indicator',
      columns: 'one-column',
      fields: [
        {
          label: 'CDLI (Indicator Position)',
          value: getDisplayValue(loanIndicator.indicatorPosition, 'string'),
        },
      ],
    },
    {
      title: 'Repossession Actions',
      styleName: 'section-repossession-actions',
      columns: 'four-columns',
      fields: [
        {
          label: 'Surrender Type (Involuntary or Voluntary)',
          value: getDisplayValue(repossessionActions.surrenderType, 'string'),
        },
        {
          label: 'Auction Date',
          value: getDisplayValue(repossessionActions.auctionDate, 'date'),
        },
        {
          label: 'Floor Price',
          value: `$${getDisplayValue(repossessionActions.floorPrice, 'amount')}`,
        },
        {
          label: 'Recovery Fees',
          value: `$${getDisplayValue(repossessionActions.recoveryFees, 'amount')}`,
        },
        {
          label: 'Date Referred to Vendor',
          value: getDisplayValue(repossessionActions.dateReferredToVendor, 'date'),
        },
        {
          label: 'Auction Sale Completed Date',
          value: getDisplayValue(repossessionActions.auctionSaleCompletedDate, 'date'),
        },
        {
          label: 'Secretion Claim Filed Date',
          value: getDisplayValue(repossessionActions.secretionClaimFiledDate, 'date'),
        },
        {
          label: 'Remarketing Fees',
          value: `$${getDisplayValue(repossessionActions.remarketingFees, 'amount')}`,
        },
        {
          label: 'Date Secured',
          value: getDisplayValue(repossessionActions.dateSecured, 'date'),
        },
        {
          label: 'Sales Price',
          value: `$${getDisplayValue(repossessionActions.salesPrice, 'amount')}`,
        },
      ],
    },
  ];

  const renderSection = (title, styleName, columns, fields) => (
    <div key={title} styleName={styleName}>
      <h3>{title}</h3>
      <div styleName={`info-grid ${columns}`}>
        {fields.map(({ label, value }) => (
          <div>
            <span styleName="label">{label}</span>
            <br />
            <span styleName="value">{value}</span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Grid container elevation={0}>
      <Grid item xs={11}>
        <Typography styleName="heading">
          Repossession
        </Typography>
      </Grid>
      <Grid item styleName="closeButton" xs={1}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {loading ? <Loader message="Please Wait" />
        : (
          <Grid item styleName="popup-container" xs={12}>
            {sections.map(section => renderSection(
              section.title,
              section.styleName,
              section.columns,
              section.fields,
            ))}
          </Grid>
        )}
    </Grid>
  );
};

RepossessionTableContent.propTypes = {
  activeTile: PropTypes.string.isRequired,
  getRepossessionTableData: PropTypes.func,
  loading: PropTypes.bool,
  repossessionTableData: PropTypes.shape({
    collateralInformation: PropTypes.shape({
      vinNumber1: PropTypes.shape({
        collateralLocation: PropTypes.string,
        collateralMake: PropTypes.string,
        collateralModel: PropTypes.string,
        collateralYear: PropTypes.number,
        licensePlateNumber: PropTypes.string,
        vinValue: PropTypes.string,
      }),
      vinNumber2: PropTypes.shape({
        collateralLocation: PropTypes.string,
        collateralMake: PropTypes.string,
        collateralModel: PropTypes.string,
        collateralYear: PropTypes.number,
        licensePlateNumber: PropTypes.string,
        vinValue: PropTypes.string,
      }),
      vinNumber3: PropTypes.shape({
        collateralLocation: PropTypes.string,
        collateralMake: PropTypes.string,
        collateralModel: PropTypes.string,
        collateralYear: PropTypes.number,
        licensePlateNumber: PropTypes.string,
        vinValue: PropTypes.string,
      }),
      vinNumber4: PropTypes.shape({
        collateralLocation: PropTypes.string,
        collateralMake: PropTypes.string,
        collateralModel: PropTypes.string,
        collateralYear: PropTypes.number,
        licensePlateNumber: PropTypes.string,
        vinValue: PropTypes.string,
      }),
    }),
    generalLoanInformation: PropTypes.shape({
      borrowerName: PropTypes.string,
      cityStateZip: PropTypes.string,
      coBorrowerName: PropTypes.string,
      loanNumber: PropTypes.string,
      mailingAddress: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
      }),
    }),
    letterHistory: PropTypes.shape({
      amendedDeficiencyLetterSent: PropTypes.string,
      ancillaryLetterSent: PropTypes.string,
      deficiencyLetterSent: PropTypes.string,
      noticeOfDefaultLetterSent: PropTypes.string,
      noticeOfIntentLetterSent: PropTypes.string,
      surplusLetterSent: PropTypes.string,
    }),
    loanIndicator: PropTypes.shape({
      indicatorPosition: PropTypes.string,
    }),
    loanInformation: PropTypes.shape({
      bankruptcyChapter: PropTypes.string,
      bankruptcyStatus: PropTypes.string,
      dayOfDelinquency: PropTypes.number,
      escrowBalance: PropTypes.number,
      interestRate: PropTypes.number,
      lateFees: PropTypes.number,
      mfrGranted: PropTypes.string,
      miscFees: PropTypes.number,
      noteDate: PropTypes.string,
      paidToDate: PropTypes.string,
      suspenseBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      upbBalance: PropTypes.number,
    }),
    repossessionActions: PropTypes.shape({
      auctionDate: PropTypes.string,
      auctionSaleCompletedDate: PropTypes.string,
      dateReferredToVendor: PropTypes.string,
      dateSecured: PropTypes.string,
      floorPrice: PropTypes.string,
      recoveryFees: PropTypes.string,
      remarketingFees: PropTypes.string,
      salesPrice: PropTypes.string,
      secretionClaimFiledDate: PropTypes.string,
      surrenderType: PropTypes.string,
    }),
    repossessionVendorInfo: PropTypes.shape({
      repossessionVendor: PropTypes.string,
      repossessionVendorAddress: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
      }),
      repossessionVendorPhoneNumber: PropTypes.string,
      repossessionVendorWebsite: PropTypes.string,
      vendorCityStateZip: PropTypes.string,
      vendorFileNumber: PropTypes.string,
    }),
    validLoanTypeCode: PropTypes.bool,
  }),
  setChecklistCenterPaneData: PropTypes.func.isRequired,
};

RepossessionTableContent.defaultProps = {
  repossessionTableData: {},
  getRepossessionTableData: () => {},
  loading: false,
};

const mapStateToProps = state => ({
  activeTile: dashboardSelectors.getStagerActiveTile(state),
  loading: selectors.getLoader(state),
  repossessionTableData: selectors.getRepossessionTableData(state),
});

const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
  getRepossessionTableData: operations.getRepossessionTableDataOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepossessionTableContent);
