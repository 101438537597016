const RESET_DATA = 'app/tasks-and-checklist/RESET_DATA';
const ERROR_LOADING_HISTORICAL_CHECKLIST = 'app/tasks-and-checklist/ERROR_LOADING_HISTORICAL_CHECKLIST';
const GET_CHECKLIST_SAGA = 'app/tasks-and-checklist/GET_CHECKLIST_SAGA';
const HISTORICAL_CHECKLIST_DATA = 'app/tasks-and-checklist/HISTORICAL_CHECKLIST_DATA';
const GET_HISTORICAL_CHECKLIST_DATA = 'app/tasks-and-checklist/GET_HISTORICAL_CHECKLIST_DATA';
const GET_NEXT_CHECKLIST = 'app/tasks-and-checklist/GET_NEXT_CHECKLIST';
const GET_PREV_CHECKLIST = 'app/tasks-and-checklist/GET_PREV_CHECKLIST';
const GET_TASKS_SAGA = 'app/tasks-and-checklist/GET_TASKS_SAGA';
const DISP_COMMENT = 'app/tasks-and-checklist/DISP_COMMENT';
const ERROR_LOADING_CHECKLIST = 'app/tasks-and-checklist/ERROR_LOADING_CHECKLIST';
const ERROR_LOADING_TASKS = 'app/tasks-and-checklist/ERROR_LOADING_TASKS';
const HANDLE_CHECKLIST_ITEM_CHANGE = 'app/tasks-and-checklist/HANDLE_CHECKLIST_ITEM_CHANGE';
const LOADING_CHECKLIST = 'app/tasks-and-checklist/LOADING_CHECKLIST';
const LOADING_TASKS = 'app/tasks-and-checklist/LOADING_TASKS';
const REMOVE_DIRTY_CHECKLIST = 'app/tasks-and-checklist/REMOVE_DIRTY_CHECKLIST';
const SET_SELECTED_CHECKLIST = 'app/tasks-and-checklist/SET_SELECTED_CHECKLIST';
const STORE_CHECKLIST = 'app/tasks-and-checklist/STORE_CHECKLIST';
const STORE_CURRENT_CHECKLIST = 'app/tasks-and-checklist/STORE_CURRENT_CHECKLIST';
const STORE_TASK_FILTER = 'app/tasks-and-checklist/STORE_TASK_FILTER';
const STORE_CHECKLIST_NAVIGATION = 'app/tasks-and-checklist/STORE_CHECKLIST_NAVIGATION';
const STORE_CHECKLIST_ITEM_CHANGE = 'app/tasks-and-checklist/STORE_CHECKLIST_ITEM_CHANGE';
const STORE_PROCESS_DETAILS = 'app/tasks-and-checklist/STORE_PROCESS_DETAILS';
const STORE_TASKS = 'app/tasks-and-checklist/STORE_TASKS';
const STORE_OPTIONAL_TASKS = 'app/tasks-and-checklist/STORE_OPTIONAL_TASKS';
const VALIDATION_DISPLAY = 'app/tasks-and-checklist/VALIDATION_DISPLAY,';
const STORE_MISC_TASK_COMMENT = 'app/tasks-and-checklist/STORE_MISC_TASK_COMMENT';
const TOGGLE_INSTRUCTIONS = 'app/tasks-and-checklist/TOGGLE_INSTRUCTIONS';
const SHOW_OPTIONAL_TASKS = 'app/tasks-and-checklist/SHOW_OPTIONAL_TASKS';
const DISP_COMMENT_SAGA = 'app/tasks-and-checklist/DISP_COMMENT_SAGA';
const EMPTY_DISPOSITION_COMMENT = 'app/tasks-and-checklist/EMPTY_DISPOSITION_COMMENT';
const DELETE_TASK = 'app/tasks-and-checklist/DELETE_TASK';
const UPDATE_CHECKLIST = 'app/tasks-and-checklist/UPDATE_CHECKLIST';
const SHOW_DELETE_TASK_CONFIRMATION = 'app/tasks-and-checklist/SHOW_DELETE_TASK_CONFIRMATION';
const RESET_DELETE_TASK = 'app/tasks-and-checklist/RESET_DELETE_TASK';
const CLEAR_SUBTASK = 'app/tasks-and-checklist/CLEAR_SUBTASK';
const UPDATE_COMMENTS = 'app/tasks-and-checklist/UPDATE_COMMENTS';
const EMPTY_CHECKLIST_COMMENT = 'app/tasks-and-checklist/EMPTY_CHECKLIST_COMMENT';
const FETCH_DROPDOWN_OPTIONS_SAGA = 'app/tasks-and-checklist/FETCH_DROPDOWN_OPTIONS_SAGA';
const SAVE_DROPDOWN_OPTIONS = 'app/tasks-and-checklist/SAVE_DROPDOWN_OPTIONS';
const GET_RESOLUTION_ID_STATS = 'app/tasks-and-checklist/GET_RESOLUTION_ID_STATS';
const FILTER_RULES = 'app/tasks-and-checklist/FILTER_RULES';
const SLA_RULES_PROCESSED = 'app/tasks-and-checklist/SLA_RULES_PROCESSED';
const SAVE_RULE_RESPONSE = 'app/tasks-and-checklist/SAVE_RULE_RESPONSE';
const CLEAR_RULE_RESPONSE = 'app/tasks-and-checklist/CLEAR_RULE_RESPONSE';
const SET_SLA_VALUES = 'app/tasks-and-checklist/SET_SLA_VALUES';
const SET_NEW_CHECKLIST = 'app/tasks-and-checklist/SET_NEW_CHECKLIST';
const PUSH_DATA = 'app/tasks-and-checklist/PUSH_DATA';
const CHECK_RULES_PASSED = 'app/tasks-and-checklist/CHECK_RULES_PASSED';
const COMPUTE_RULES_PASSED = 'app/tasks-and-checklist/COMPUTE_RULES_PASSED';
const SAVE_DROPDOWN_DATA = 'app/tasks-and-checklist/SAVE_DROPDOWN_DATA';
const PUT_DROPDOWN_DATA = 'app/tasks-and-checklist/PUT_DROPDOWN_DATA';
const SET_LAST_UPDATED = 'app/tasks-and-checklist/SET_LAST_UPDATED';
const SET_RFD_CHOICE = 'app/tasks-and-checklist/SET_RFD_CHOICE';
const FETCH_MONTHLY_EXPENSE_VALUES = 'app/tasks-and-checklist/FETCH_MONTHLY_EXPENSE_VALUES';
const SAVE_MONTHLY_EXPENSE_VALUES = 'app/tasks-and-checklist/SAVE_MONTHLY_EXPENSE_VALUES';
const CURRENT_CHECKLIST_TYPE = 'app/tasks-and-checklist/CURRENT_CHECKLIST_TYPE';
const CHECK_SUBREJECT_REASON_DROPDOWN_SAGA = 'app/tasks-and-checklist/CHECK_SUBREJECT_REASON_DROPDOWN_SAGA';

const FETCH_FICO_HISTORY = 'app/tasks-and-checklist/FETCH_FICO_HISTORY';
const SAVE_FICO_HISTORY = 'app/tasks-and-checklist/SAVE_FICO_HISTORY';
const SET_FICO_SCORE = 'app/tasks-and-checklist/SET_FICO_SCORE';
const SAVE_FICO_SCORE = 'app/tasks-and-checklist/SAVE_FICO_SCORE';
const FICO_LOCK = 'app/tasks-and-checklist/FICO_LOCK';

const SET_ASSET_DETAILS = 'app/tasks-and-checklist/SET_ASSET_DETAILS';
const SAVE_ASSET_DETAILS = 'app/tasks-and-checklist/SAVE_ASSET_DETAILS';
const SET_RADIO_STATE_DETAIL = 'app/tasks-and-checklist/SET_RADIO_STATE_DETAIL';
const ASSET_LOCK = 'app/tasks-and-checklist/ASSET_LOCK';
const FETCH_ASSET_HISTORIES = 'app/tasks-and-checklist/FETCH_ASSET_HISTORIES';
const SAVE_ASSET_HISTORIES = 'app/tasks-and-checklist/SAVE_ASSET_HISTORIES';
const FETCH_ASSET_HISTORY_FOR_ASSET_ID = 'app/tasks-and-checklist/FETCH_ASSET_HISTORY_FOR_ASSET_ID';
const SAVE_ASSET_HISTORY_BY_ID = 'app/tasks-and-checklist/SAVE_ASSET_HISTORY_BY_ID';
const SET_HISTORY_VIEW = 'app/tasks-and-checklist/SET_HISTORY_VIEW';
const SAVE_HISTORY_VIEW = 'app/tasks-and-checklist/SAVE_HISTORY_VIEW';
const CLEAR_FICO_ASSET_DATA = 'app/tasks-and-checklist/CLEAR_FICO_ASSET_DATA';
const SET_REJ_REASONID = 'app/tasks-and-checklist/SET_REJ_REASONID';
const SET_REJECT_REASON_DROPDOWN = 'app/tasks-and-checklist/SET_REJECT_REASON_DROPDOWN';
const ENABLE_NEXT_CHECKLIST = 'app/tasks-and-checklist/ENABLE_NEXT_CHECKLIST';

export {
  CURRENT_CHECKLIST_TYPE,
  SET_RFD_CHOICE,
  SET_LAST_UPDATED,
  PUT_DROPDOWN_DATA,
  SAVE_DROPDOWN_DATA,
  COMPUTE_RULES_PASSED,
  GET_NEXT_CHECKLIST,
  GET_PREV_CHECKLIST,
  GET_CHECKLIST_SAGA,
  GET_TASKS_SAGA,
  ERROR_LOADING_CHECKLIST,
  ERROR_LOADING_TASKS,
  HANDLE_CHECKLIST_ITEM_CHANGE,
  LOADING_CHECKLIST,
  LOADING_TASKS,
  DISP_COMMENT,
  REMOVE_DIRTY_CHECKLIST,
  SET_SELECTED_CHECKLIST,
  RESET_DATA,
  STORE_CHECKLIST,
  STORE_CURRENT_CHECKLIST,
  STORE_CHECKLIST_NAVIGATION,
  STORE_CHECKLIST_ITEM_CHANGE,
  STORE_PROCESS_DETAILS,
  STORE_TASKS,
  STORE_TASK_FILTER,
  STORE_OPTIONAL_TASKS,
  STORE_MISC_TASK_COMMENT,
  TOGGLE_INSTRUCTIONS,
  SHOW_OPTIONAL_TASKS,
  VALIDATION_DISPLAY,
  DISP_COMMENT_SAGA,
  EMPTY_DISPOSITION_COMMENT,
  DELETE_TASK,
  UPDATE_CHECKLIST,
  SHOW_DELETE_TASK_CONFIRMATION,
  RESET_DELETE_TASK,
  CLEAR_SUBTASK,
  HISTORICAL_CHECKLIST_DATA,
  GET_HISTORICAL_CHECKLIST_DATA,
  ERROR_LOADING_HISTORICAL_CHECKLIST,
  UPDATE_COMMENTS,
  EMPTY_CHECKLIST_COMMENT,
  FETCH_DROPDOWN_OPTIONS_SAGA,
  SAVE_DROPDOWN_OPTIONS,
  GET_RESOLUTION_ID_STATS,
  FILTER_RULES,
  SLA_RULES_PROCESSED,
  SAVE_RULE_RESPONSE,
  CLEAR_RULE_RESPONSE,
  SET_SLA_VALUES,
  SET_NEW_CHECKLIST,
  PUSH_DATA,
  CHECK_RULES_PASSED,
  FETCH_MONTHLY_EXPENSE_VALUES,
  SAVE_MONTHLY_EXPENSE_VALUES,
  FETCH_FICO_HISTORY,
  SAVE_FICO_HISTORY,
  SAVE_FICO_SCORE,
  SET_FICO_SCORE,
  FICO_LOCK,
  SET_ASSET_DETAILS,
  SAVE_ASSET_DETAILS,
  SET_RADIO_STATE_DETAIL,
  ASSET_LOCK,
  FETCH_ASSET_HISTORIES,
  FETCH_ASSET_HISTORY_FOR_ASSET_ID,
  SAVE_ASSET_HISTORIES,
  SAVE_ASSET_HISTORY_BY_ID,
  SET_HISTORY_VIEW,
  SAVE_HISTORY_VIEW,
  CLEAR_FICO_ASSET_DATA,
  SET_REJ_REASONID,
  CHECK_SUBREJECT_REASON_DROPDOWN_SAGA,
  SET_REJECT_REASON_DROPDOWN,
  ENABLE_NEXT_CHECKLIST,
};
