
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import { operations } from 'ducks/tombstone';
import PropTypes from 'prop-types';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import './Repossession.css';


class Repossession extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleCenterPaneView = this.handleCenterPaneView.bind(this);
  }

  handleCenterPaneView() {
    const {
      setChecklistCenterPaneData,
      getRepossessionTableData, loanNumber,
      loanInfoComponent,
    } = this.props;
    getRepossessionTableData(loanNumber);
    setChecklistCenterPaneData(loanInfoComponent);
  }

  render() {
    return (
      <>
        <IconButton onClick={this.handleCenterPaneView} size="small" styleName="icon-view">
          <VisibilityIcon />
        </IconButton>
      </>
    );
  }
}

Repossession.defaultProps = {
  getRepossessionTableData: () => { },
};

Repossession.propTypes = {
  getRepossessionTableData: PropTypes.func,
  loanInfoComponent: PropTypes.string.isRequired,
  loanNumber: PropTypes.number.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
  getRepossessionTableData: operations.getRepossessionTableDataOperation(dispatch),
});

const mapStateToProps = state => ({
  loanNumber: dashboardSelectors.loanNumber(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Repossession);
