import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from 'ducks/eval';
import { Card, Typography } from '@material-ui/core';
import * as moment from 'moment';
import { convertToUSCurrency } from 'lib/Formatters';
import EvalTable from '../../../../components/Eval/EvalTable';
import CardStyles from './CardStyles';

const StepRates = ({
  bookingTabData,
}) => {
  const [stepRatesData, setStepRatesData] = useState([]);

  useEffect(() => {
    if (bookingTabData.stepRates && bookingTabData.stepRates.length > 0) {
      setStepRatesData(bookingTabData.stepRates.map(e => ({
        step: e.seq,
        rate: e.rate,
        pi: e.stepPi,
        ti: e.stepTi,
        piti: e.stepPiti,
        date: e.startDate,
      })));
    }
  }, bookingTabData);
  const classes = CardStyles({ hideDivider: true });
  // const stepRatesData = [
  //   {
  //     step: R.pathOr('', ['stepRates', 'seq'], bookingTabData),
  //     rate: R.pathOr('', ['stepRates', 'rate'], bookingTabData),
  //     pi: R.pathOr('', ['stepRates', 'stepPi'], bookingTabData),
  //     ti: R.pathOr('', ['stepRates', 'stepTi'], bookingTabData),
  //     piti: R.pathOr('', ['stepRates', 'stepPiti'], bookingTabData),
  //     date: R.pathOr('', ['stepRates', 'startDate'], bookingTabData),
  //   },
  // ];
  const columns = [
    {
      name: 'step',
      label: 'Step',
    },
    {
      name: 'rate',
      label: 'Rate',
      cellFormat: value => `${Number.isNaN(Number(value)) ? 0.000 : (Number(value) * 100).toFixed(3)}%`,
    },
    {
      name: 'pi',
      label: 'PI',
      cellFormat: value => `${convertToUSCurrency.format(Number(value).toFixed(2))}`,
    },
    {
      name: 'ti',
      label: 'TI',
      cellFormat: value => `${convertToUSCurrency.format(Number(value).toFixed(2))}`,
    },
    {
      name: 'piti',
      label: 'PITI',
      cellFormat: value => `${convertToUSCurrency.format(Number(value).toFixed(2))}`,
    },
    {
      name: 'date',
      label: 'Start Date',
      cellFormat: value => (value && value !== '' && value !== '-' ? moment(value).format('MM/DD/YYYY') : '-'),
    },
  ];

  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>Step Rates</Typography>
      <EvalTable columns={columns} data={stepRatesData || []} />
    </Card>

  );
};

StepRates.propTypes = {
  bookingTabData: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  bookingTabData: selectors.bookingTabData(state),
});

export default connect(mapStateToProps)(StepRates);
