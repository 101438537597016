import React, { useEffect, useState } from 'react';
import EvalTable from 'components/Eval/EvalTable';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import './NewCases.css';
import { operations as tombstoneOperations } from 'ducks/tombstone';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { selectors as loginSelectors } from 'ducks/login';
import * as R from 'ramda';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/Loader';
import {
  WATERFALL_SEGREGATION, FORBEARANCE_WORKOUT_TYPE, CASE_CREATION_AD_GROUPS,
  REJECTED, COMPLETED,
} from '../../../constants/eval';
import { CASE_DETAILS, REPAYMENT_PLAN_CASE } from '../../../constants/loanInfoComponents';
import { operations, selectors as evalSelectors } from '../../../state/ducks/eval';
import { operations as repayOperations } from '../../../state/ducks/repaymentEval';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../state/ducks/dashboard';
import { selectors as widgetsSelectors } from '../../../state/ducks/widgets';
import { EVAL } from '../../../constants/widgets';

const useStyles = makeStyles({
  // root: {
  //   display: 'flex',
  // },
  item: {
    '&:hover': {
      backgroundColor: '#F3F5F9',
      color: '#4E586E',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      // },
    },
  },
  selected: {},
});
const NewCases = (props) => {
  const classes = useStyles();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedCase, setSelectedCase] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    user, setChecklistCenterPaneData, getCaseHeaderInfo, evalScreenData,
    fetchCaseTypes, createButtonStatus,
    setSelectedCaseId, addCaseToWaterfallSeq, isEditable,
    caseTypesData, getCaseData, fetchWorkoutTypes, workoutTypes,
    isworkOutTypeLoading, setRepaymentCaseId, setRuleEngineError,
    setModCalcIndicator, isCaseTypeLoading, setFromEvalSummaryPage,
    openWidgetList, setIsEvalRepay,
  } = props;

  const groups = user && user.groupList;
  const segregationGroupLower = [];
  WATERFALL_SEGREGATION.map(ele => segregationGroupLower.push(ele.toLocaleLowerCase()));
  const [data, setData] = useState([]);
  const createNewCaseAdGroups = groups.some(group => CASE_CREATION_AD_GROUPS.includes(group));

  const { evalCase } = evalScreenData;
  useEffect(() => {
    if (!R.isEmpty(evalScreenData) && !R.isNil(evalScreenData.caseInfo)) {
      setData(evalScreenData.caseInfo);
    }
  }, [evalScreenData]);


  // useEffect(() => {
  //   const { createNewCase } = props;
  //   if (!R.isEmpty(selectedCase) && !R.isNil(selectedCase) && workoutTypes.length === 0) {
  //     setAnchorEl(null);
  //     setIsPopupVisible(false);
  //     createNewCase({ workoutType: '', selectedCaseType: selectedCase });
  //   }
  // }, [workoutTypes]);

  const handleClick = (value) => {
    setModCalcIndicator(true);
    setRuleEngineError(null);
    setSelectedCaseId(value.caseId);
    getCaseHeaderInfo(value);
    if (value.workoutType !== FORBEARANCE_WORKOUT_TYPE) {
      setChecklistCenterPaneData(CASE_DETAILS);
    } else {
      setFromEvalSummaryPage(true);
      setRepaymentCaseId(value.caseId);
      setChecklistCenterPaneData(REPAYMENT_PLAN_CASE);
      if (R.contains(EVAL, openWidgetList)) {
        setIsEvalRepay(true);
      } else {
        setIsEvalRepay(false);
      }
    }
  };


  const handleCaseTypeDropDown = (value) => {
    setSelectedCase(value);
    const caseData = R.find(R.propEq('caseType', value))(getCaseData);
    fetchWorkoutTypes({ resolutionTypeId: caseData.resolutionTypeId, selectedCaseType: value });
  };

  const handleWaterfallAddition = (value) => {
    addCaseToWaterfallSeq({ caseId: value.caseId });
  };

  const canAddtoWtfall = (caseDtl) => {
    const { waterFallInfo } = evalScreenData;
    if (caseDtl.wtfallDtlCount === 0
      && evalCase.status === 'Active') {
      if (evalScreenData.waterFallInfo) {
        if (waterFallInfo.investorWaterfallDetailsList) {
          const exists = R.any(item => item.caseId === caseDtl.caseId,
            waterFallInfo.investorWaterfallDetailsList);
          return !exists;
        } return true;
      }
      return true;
    }
    return false;
  };

  const columns = [{
    align: '',
    label: 'Case ID',
    minWidthHead: '',
    name: 'caseId',
    options: {},
    cellFormat(singleValue, value) {
      return (
        <Button
          onClick={() => handleClick(value)}
          styleName="addButton caseId-link"
          type="text"
        >
          {value[this.name]}
        </Button>
      );
    },
  },
  {
    align: '',
    label: 'Created On',
    minWidthHead: '',
    name: 'createdOn',
    options: {},
  },
  {
    align: '',
    label: 'Parent ID',
    minWidthHead: '',
    name: 'parentId',
    options: {},
  },
  {
    align: '',
    label: 'Case Type',
    minWidthHead: '',
    name: 'caseType',
    options: {},
  },
  {
    align: '',
    label: 'Investor Case Type',
    minWidthHead: '',
    name: 'investorCaseType',
    options: {},
  },
  {
    align: '',
    label: 'Status',
    minWidthHead: '',
    name: 'status',
    options: {},
  },
  {
    align: '',
    label: 'Status Date',
    minWidthHead: '',
    name: 'statusDate',
    options: {},
  },
  {
    align: '',
    label: 'Sub Status',
    minWidthHead: '',
    name: 'subStatus',
    options: {},
  },
  {
    align: '',
    label: 'Agent Name',
    minWidthHead: '',
    name: 'agentName',
    options: {},
  },
  {
    align: '',
    label: 'Reason',
    minWidthHead: '',
    name: 'reason',
    options: {},
  },
  {
    align: '',
    label: '',
    minWidthHead: '',
    name: '',
    options: {},
    iconCheck: 'Yes',
    cellFormat(value) {
      return (
        <>
          {canAddtoWtfall(value) && (
            <Button
              disabled={!segregationGroupLower.some(l => groups.includes(l)) || !isEditable}
              onClick={() => handleWaterfallAddition(value)}
              style={{ visibility: (!isEditable || !segregationGroupLower.some(l => groups.includes(l))) ? 'hidden' : 'visible' }}
              styleName="addButton"
              type="button"
            >
              ADD TO WATERFALL
            </Button>
          )}
        </>
      );
    },
  },
  ];

  const popupHandle = (event) => {
    setAnchorEl(event.currentTarget);
    if (!isPopupVisible) {
      setSelectedCase('');
    }
    setIsPopupVisible(!isPopupVisible);
    fetchCaseTypes();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopupVisible(false);
  };
  const handleCaseCreation = (item) => {
    const { createNewCase } = props;
    createNewCase({ workoutType: item, selectedCaseType: selectedCase });
    setIsPopupVisible(false);
  };

  const disableStatuses = ['Open', 'Pending', 'Sent for Reject', 'Sent for Approval', 'Locked', 'Approved'];
  const enableStatuses = ['Closed', 'Rejected', 'Failed', 'Booked'];
  const hasDisableStatus = data.some(item => disableStatuses.includes(item.status));
  const hasAllEnableStatuses = data.every(item => enableStatuses.includes(item.status));

  return (
    <div styleName="white-bg">
      <div styleName="table-header">
        <h2>Case(s)</h2>
        <div styleName="popupHolder">
          <Button
            color="primary"
            disabled={!createNewCaseAdGroups || !createButtonStatus
              || [REJECTED, COMPLETED].includes(evalCase.status)
              || hasDisableStatus || !hasAllEnableStatuses}
            onClick={popupHandle}
            startIcon={<AddIcon />}
          >
            Create New Case
          </Button>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handleClose}
            open={isPopupVisible}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div>
              {!selectedCase
                ? (
                  <>
                    {isCaseTypeLoading && (
                    <div style={{ padding: '5px' }}>
                      <Loader message="Fetching case types..." size={20} />
                    </div>
                    )}
                    {!isCaseTypeLoading && (
                      <>
                        {caseTypesData.length === 0 ? (<Typography style={{ padding: '5px' }}> No Case Types Available</Typography>)
                          : (
                            <List aria-label="secondary mailbox folders" component="nav" dense>
                              {caseTypesData.map(item => (
                                <ListItem
                                  button
                                  className={classes.item}
                                  onClick={() => { handleCaseTypeDropDown(item); }}
                                >
                                  <ListItemText primary={item} />
                                </ListItem>
                              ))}
                            </List>
                          )
                    }
                      </>
                    )}
                  </>
                )
                : workoutTypes && workoutTypes.length > 0 && (
                  <>
                    <div styleName="header">
                      <div>
                        {' '}
                        <h3>Select Workout Type</h3>
                        <span>
                          for
                          &apos;
                          {selectedCase}
                          &apos;
                        </span>
                        {' '}

                      </div>
                      <Button onClick={() => setSelectedCase('')}>
                        {' '}
                        <b>&lt; Back </b>
                      </Button>
                    </div>
                    <div>
                      {isworkOutTypeLoading && (
                        <div style={{ padding: '5px' }}>
                          <Loader message="Fetching workout types..." size={20} />
                        </div>
                      )}
                      {!isworkOutTypeLoading && (
                        <>
                          {workoutTypes.length === 0 ? (<Typography style={{ padding: '5px' }}> No Workout Types Available</Typography>)
                            : (
                              <List component="nav" dense>
                                {workoutTypes.map(item => (
                                  <ListItem
                                    button
                                    className={classes.item}
                                    onClick={() => {
                                      handleCaseCreation(item);
                                    }}
                                  >
                                    <ListItemText primary={item} />
                                  </ListItem>
                                ))}
                              </List>
                            )}
                        </>
                      )
                      }

                      {/* {workoutTypes.map(item => (
                        <p
                          aria-hidden="true"
                          onClick={() => {
                            setType(item);
                            handleCaseCreation(item);
                          }}
                        >
                          {item}
                        </p>
                      ))} */}
                      {' '}
                    </div>
                    {' '}

                  </>
                )}
              <div />
            </div>
          </Popover>
        </div>
      </div>
      <div styleName="case-table-container">
        <EvalTable columns={columns} data={data} />
      </div>
    </div>

  );
};

NewCases.defaultProps = {
  isEditable: true,
  isworkOutTypeLoading: false,
  isCaseTypeLoading: false,
  openWidgetList: [],
};

NewCases.propTypes = {
  addCaseToWaterfallSeq: PropTypes.func.isRequired,
  caseTypesData: PropTypes.arrayOf(PropTypes.String).isRequired,
  createButtonStatus: PropTypes.bool.isRequired,
  createNewCase: PropTypes.func.isRequired,
  evalScreenData: PropTypes.shape().isRequired,
  fetchCaseTypes: PropTypes.func.isRequired,
  fetchWorkoutTypes: PropTypes.func.isRequired,
  getCaseData: PropTypes.shape().isRequired,
  getCaseHeaderInfo: PropTypes.func.isRequired,
  isCaseTypeLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  isworkOutTypeLoading: PropTypes.bool,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setFromEvalSummaryPage: PropTypes.func.isRequired,
  setIsEvalRepay: PropTypes.func.isRequired,
  setModCalcIndicator: PropTypes.func.isRequired,
  setRepaymentCaseId: PropTypes.func.isRequired,
  setRuleEngineError: PropTypes.func.isRequired,
  setSelectedCaseId: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
  workoutTypes: PropTypes.arrayOf(PropTypes.String).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  caseTypesData: evalSelectors.getCaseTypes(state),
  getCaseData: evalSelectors.getCaseData(state),
  getPreLockCaseValidation: evalSelectors.getPreLockCaseValidation(state),
  workoutTypes: evalSelectors.getWorkoutTypes(state),
  createButtonStatus: evalSelectors.getCreateButtonStatus(state),
  getModStatus: dashboardSelectors.getModStatus(state),
  isworkOutTypeLoading: evalSelectors.isworkOutTypeLoading(state),
  isCaseTypeLoading: evalSelectors.isCaseTypeLoading(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
});


const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  getCaseHeaderInfo: operations.getCaseHeaderInfo(dispatch),
  fetchCaseTypes: operations.fetchCaseTypesOperation(dispatch),
  fetchWorkoutTypes: operations.fetchWorkoutTypeDataOperation(dispatch),
  setSelectedCaseId: dashboardOperations.setSelectedCaseIdOpn(dispatch),
  addCaseToWaterfallSeq: operations.addCaseToWaterfallSeq(dispatch),
  createNewCase: operations.createNewCaseOperation(dispatch),
  setRepaymentCaseId: dashboardOperations.setRepaymentCaseIdOpn(dispatch),
  setRuleEngineError: operations.setRuleEngineErrorOperation(dispatch),
  setModCalcIndicator: operations.setModCalcIndicatorOperation(dispatch),
  setFromEvalSummaryPage: repayOperations.setFromEvalSummaryPageOperation(dispatch),
  setIsEvalRepay: repayOperations.setIsEvalRepayOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCases);
